export const getHeightWithFooterWONavbar = (
  pages,
  height,
  subnavbar_height_empty,
  subnavbar_height_not_empty
) => {
  if (pages === undefined) {
    return height * 0.92 - subnavbar_height_empty;
  } else {
    return height * 0.92 - subnavbar_height_not_empty;
  }
};
