import React, { useState } from "react";
import { Tooltip } from "@mui/material";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";
import TitleCard from "../../../Homepage/components/TitleCard";
import ModalActions from "./ModalActions";
import ModalDeleteAction from "./ModalDeleteAction";
import {
  FaPlus,
  FaPencilAlt,
  FaSpinner,
  FaAngleDoubleRight,
} from "react-icons/fa";
import { getIconMapped } from "../../../../utils/getIconMapped";
import { Button } from "reactstrap";
import { getHeightWorkspace } from "../../../../utils/getHeightWorkspace";
import { Checkbox } from "@mui/material";
import InfoAlert from "../../../../components/Modals/InfoAlert";

function ActionList(props) {
  const { width, height } = useWindowDimensions();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [actionSelected, setActionSelected] = useState(null);
  const [subActionsChecked, setSubActionsChecked] = useState([]);

  return (
    <Col
      xxl={width > 1700 ? "2" : "3"}
      xl="3"
      lg="3"
      md="6"
      sm="12"
      style={{
        position: "relative",
        padding: 10,
        height: getHeightWorkspace(
          props.pages,
          height,
          props.layout.subnavbar_height_empty_ctp,
          props.layout.subnavbar_height_not_empty_ctp
        ),
      }}
    >
      <div
        style={{
          ...props.layout.generic_card_ctp,
          display: "flex",
          flexFlow: "column",
          height: "100%",
        }}
      >
        {props.isLoading === true ? (
          <div style={{ padding: 20 }}>
            <FaSpinner className="spinner"></FaSpinner>
          </div>
        ) : (
          <>
            <TitleCard
              title="Action list"
              icon={
                <Tooltip title="Select domains">
                  <div
                    style={{
                      backgroundColor: props.layout.color_sidebar_ctp,
                      padding: "2px 8px 5.5px 8px",
                      borderRadius: "50%",
                      marginTop: 10,
                      cursor: "pointer",
                    }}
                    onClick={() => setIsOpen(true)}
                  >
                    <FaPlus
                      style={{
                        color: "white",
                        fontSize: 15,
                      }}
                    ></FaPlus>
                  </div>
                </Tooltip>
              }
            ></TitleCard>
            <div
              style={{
                padding: "10px 15px",
                flex: "1 1 auto",
                borderRadius: props.layout.generic_card_ctp.borderRadius,
                height: "auto",
                width: "100%",
                overflow: "auto",
              }}
            >
              <div style={{ height: "90%", overflow: "auto" }}>
                <div
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "bold",
                    color: props.layout.color_footer_ctp,
                    fontSize: width > 1450 ? 16 : 14,
                  }}
                >
                  {actionSelected === null
                    ? "Select an action..."
                    : actionSelected.name}
                </div>
                <div
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: 500,
                    fontSize: 14,
                  }}
                >
                  {actionSelected !== null
                    ? actionSelected.sub_actions.map((sub_action) => {
                        return (
                          <>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: 15,
                              }}
                            >
                              <Checkbox
                                checked={
                                  subActionsChecked.includes(sub_action.id) ===
                                  true
                                    ? true
                                    : false
                                }
                                onChange={(e) =>
                                  subActionsChecked.includes(sub_action.id) ===
                                  false
                                    ? setSubActionsChecked([
                                        ...subActionsChecked,
                                        sub_action.id,
                                      ])
                                    : setSubActionsChecked(
                                        subActionsChecked.filter(
                                          (id) => id !== sub_action.id
                                        )
                                      )
                                }
                              ></Checkbox>
                              <span style={{ marginLeft: 8 }}>
                                {sub_action.subActionName}
                              </span>
                            </div>
                          </>
                        );
                      })
                    : null}
                </div>
              </div>
              <div
                style={{
                  height: "10%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  outline
                  color="danger"
                  onClick={() => {
                    if (actionSelected === null) {
                      InfoAlert("No action selected");
                    } else {
                      setIsOpenDelete(true);
                    }
                  }}
                  style={{
                    width: 150,
                    borderRadius: 15,
                    cursor: "pointer",
                    fontWeight: 500,
                    fontFamily: "Montserrat",
                    marginTop: 15,
                  }}
                >
                  DELETE
                </Button>
              </div>
            </div>
          </>
        )}
      </div>

      {/* double arrow right */}
      <div
        style={{
          position: "absolute",
          zIndex: 9,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          top: "50%",
          right: 0,
          marginRight: width < 770 ? 0 : "-16px",
          color: "white",
          backgroundColor: props.layout.color_sidebar_ctp,
          borderRadius: "50%",
          width: 35,
          height: 35,
          cursor: "pointer",
        }}
      >
        <FaAngleDoubleRight
          style={{ fontSize: 25 }}
          onClick={() => {
            if (actionSelected !== null) {
              props.setActionDetails([
                ...props.actionDetails,
                ...actionSelected.sub_actions.filter((sub_action) =>
                  subActionsChecked.includes(sub_action.id)
                ),
              ]);
            }
          }}
        ></FaAngleDoubleRight>
      </div>

      <ModalActions
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        actionsAnagrafica={props.actionsAnagrafica}
        actionSelected={actionSelected}
        setActionSelected={setActionSelected}
      ></ModalActions>
      <ModalDeleteAction
        isOpen={isOpenDelete}
        setIsOpen={setIsOpenDelete}
        actionSelected={actionSelected}
        setSubActionsChecked={setSubActionsChecked}
        setActionSelected={setActionSelected}
        setActionsAnagrafica={props.setActionsAnagrafica}
      ></ModalDeleteAction>
    </Col>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(ActionList);
