import React, { useEffect } from "react";
import { getFontSize } from "../../../utils/getFontSize";
import { useWindowDimensions } from "../../../utils/useWindowDimensions";
import { Col } from "reactstrap";
import Plotly from "plotly.js-dist";
import { usePlotlyConfig } from "../../../utils/usePlotlyConfig";
import {
  consumiPerSettore2020,
  consumiPerCommodity2020,
} from "../../../utils/energyBalance";
import { connect } from "react-redux";

function RightColumn(props) {
  const { width, height } = useWindowDimensions();
  const padding = height * 0.005;
  const plotlyConfig = usePlotlyConfig();

  useEffect(() => {
    // sector energy balance
    var data = [
      {
        values: consumiPerSettore2020.map((obj) => obj.contributo),
        labels: consumiPerSettore2020.map((obj) => obj.settore),
        type: "pie",
        textinfo: "none",
        automargin: true,
        hole: 0.5,
      },
    ];

    var layout = {
      margin: {
        t: 20,
        b: 20,
        l: 20,
        r: 20,
      },
      legend: {
        orientation: "h",
        xanchor: "center",
        x: 0.5,
      },
      paper_bgcolor: "rgba(0,0,0,0)",
      plot_bgcolor: "rgba(0,0,0,0)",
    };

    Plotly.newPlot("pieChart3", data, layout, { ...plotlyConfig });

    // commodity energy balance
    var data = [
      {
        values: consumiPerCommodity2020.map((obj) => obj.contributo),
        labels: consumiPerCommodity2020.map((obj) => obj.settore),
        type: "pie",
        textinfo: "none",
        automargin: true,
        hole: 0.5,
      },
    ];

    var layout = {
      margin: {
        t: 20,
        b: 20,
        l: 20,
        r: 20,
      },
      legend: {
        orientation: "h",
        xanchor: "center",
        x: 0.5,
      },
      paper_bgcolor: "rgba(0,0,0,0)",
      plot_bgcolor: "rgba(0,0,0,0)",
    };

    Plotly.newPlot("pieChart4", data, layout, { ...plotlyConfig });
  }, []);

  return (
    <Col
      lg={"2"}
      md="12"
      style={{
        height: height * 0.78, // height is height * 0.78 because it needs to take into account the padding
        padding: padding,
        margin: 0,
      }}
    >
      <div
        style={{
          height: "100%",
          backgroundColor: "#E3F3F9",
          borderRadius: 7,
          padding: 15,
        }}
      >
        <div
          style={{
            height: "100%",
          }}
        >
          <div
            style={{
              height: "100%",
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              alignContent: "space-between",
            }}
          >
            {/* pieChart3 */}
            <div
              style={{
                width: "100%",
                height: "49%",
                backgroundColor: "#9FD4FF",
                borderRadius: 7,
                padding: 10,
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  borderBottom: "2px solid white",
                  width: "100%",
                  fontSize: getFontSize("large"),
                }}
              >
                E.B. per sector 2020
              </div>
              <div
                style={{
                  height: "90%",
                  width: "100%",
                  padding: 10,
                }}
              >
                <div
                  id="pieChart3"
                  style={{
                    height: "90%",
                    width: "auto",
                  }}
                ></div>
              </div>
            </div>
            {/* pieChart4 */}
            <div
              style={{
                width: "100%",
                height: "49%",
                backgroundColor: "#9FD4FF",
                borderRadius: 7,
                padding: 10,
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  borderBottom: "2px solid white",
                  width: "100%",
                  fontSize: getFontSize("large"),
                }}
              >
                Usage commodity 2020
              </div>
              <div
                style={{
                  height: "90%",
                  width: "100%",
                  padding: 10,
                }}
              >
                <div
                  id="pieChart4"
                  style={{
                    height: "90%",
                    width: "auto",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(RightColumn);
