import React, { useState } from "react";
import { Checkbox } from "@mui/material";
import { Modal, ModalHeader, ModalBody, ModalFooter, Card } from "reactstrap";
import { Button } from "reactstrap";
import { getIconMapped } from "../../../../utils/getIconMapped";
import { FaSearch } from "react-icons/fa";
import { TextField } from "@mui/material";

function ModalDomains(props) {
  const [searchText, setSearchText] = useState("");
  const toggle = () => props.setIsOpen(!props.isOpen);
  return (
    <Modal isOpen={props.isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Select domains of interest</ModalHeader>
      <ModalBody>
        <div style={{ marginBottom: 20 }}>
          <FaSearch style={{ color: "gray" }}></FaSearch>
          <TextField
            variant="standard"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            style={{ marginLeft: 10, marginTop: "-5px" }}
            placeholder="Search..."
          />
        </div>
        {/* filter domains by searchText in either domain name or action name */}
        {props.domainParams
          .filter(
            (domain) =>
              domain.name.toLowerCase().includes(searchText.toLowerCase()) ||
              domain.params
                .map((a) =>
                  a.name.toLowerCase().includes(searchText.toLocaleLowerCase())
                )
                .some((item) => item) // means at least one true in array
          )
          .map((domain, index) => {
            return (
              <Card
                body
                style={{
                  marginTop: index === 0 ? 0 : 20,
                  fontFamily: "Montserrat",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {getIconMapped(domain.icon)}{" "}
                    <span style={{ marginLeft: 10 }}>{domain.name}</span>
                  </div>
                  <Checkbox
                    checked={domain.active}
                    onChange={(e) => {
                      props.setDomainParams(
                        props.domainParams.map((obj) => {
                          if (obj.id !== domain.id) {
                            return obj;
                          } else {
                            return {
                              ...obj,
                              active: e.target.checked,
                            };
                          }
                        })
                      );
                      if (props.domainSelected === domain.id) {
                        props.setDomainSelected("");
                      }
                    }}
                  />
                </div>

                <span
                  style={{
                    textDecoration: "underline",
                    fontWeight: 500,
                    fontSize: 14,
                  }}
                >
                  Actions included:
                </span>
                <ul style={{ fontWeight: 500, fontSize: 14 }}>
                  {domain.params.map((sub_action) => {
                    return <li>{sub_action.name}</li>;
                  })}
                </ul>
              </Card>
            );
          })}
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => props.setIsOpen(false)}>Close</Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalDomains;
