import React, { useState, useEffect } from "react";
import * as Cesium from "cesium";
import "./BuildingMap.css";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import axios from "axios";
import CheckboxHeader from "./CheckboxHeader";
import Map3DBuildings from "./Map3DBuildings.tsx";

Cesium.Ion.defaultAccessToken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIyNTE4YTU4MS0xZDIyLTRhMmEtYWM2Yy1hZDYxNjI3MGNiYzgiLCJpZCI6MTIyNTI5LCJpYXQiOjE2NzQ4OTU4Nzd9.a65a_dI7VQTEA1_OW1LhDqNwQ15bfyE3wzPcQmG2eac";

function BuildingsMap() {
  const [filterChanged, setFilterChanged] = useState(0);
  const [filterOpened, setFilterOpened] = useState(false);
  const [agGridData, setAgGridData] = useState([]);
  var [selectedFeatures, setSelectedFeatures] = useState({});

  useEffect(() => {
    axios
      .get(
        "https://geoserver.est.polito.it/geoserver/citta/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=citta%3Abuildings&outputFormat=application%2Fjson&propertyName=identifier,type,description,elevation,area,epv,riscaldamento,age,enthermgr,elt_sec_subst,line_type"
      )
      .then((response) => response.data)
      .then((data) => {
        setAgGridData(data.features.map((f) => f.properties));
      });
  }, []);

  return (
    <div style={{ display: "flex", width: "100%", height: "100%" }}>
      <div style={{ height: "100%", width: "100%" }}>
        <Map3DBuildings selectedFeaturesOBJ={selectedFeatures} />
      </div>
      <div
        className="ag-theme-balham"
        style={{
          width: "50%",
          display: filterOpened === false ? "none" : "block",
        }}
      >
        <AgGridReact
          rowData={agGridData}
          defaultColDef={{
            flex: 1,
            sortable: true,
            resizable: true,
            filter: true,
            filterParams: { buttons: ["reset"] },
          }}
          onFilterChanged={() => setFilterChanged(filterChanged + 1)}
          frameworkComponents={{
            checkboxHeader: CheckboxHeader,
          }}
        >
          <AgGridColumn
            headerComponent="checkboxHeader"
            headerComponentParams={{
              selectedFeatures: selectedFeatures,
              setSelectedFeautures: setSelectedFeatures,
              filterChanged: filterChanged,
            }}
            valueFormatter={(params) =>
              selectedFeatures[params.data.identifier] === true ? "✅" : "⬜"
            }
            field="selected"
            headerName=""
            maxWidth={37}
            minWidth={37}
            onCellClicked={(e) => {
              setSelectedFeatures((oldSelectedFeature) => ({
                ...oldSelectedFeature,
                [e.data.identifier]: !(
                  selectedFeatures[e.data.identifier] ?? false
                ),
              }));
            }}
          />
          <AgGridColumn field="identifier" />
          <AgGridColumn field="description" />
          <AgGridColumn field="area" />
          <AgGridColumn field="riscaldamento" />
          <AgGridColumn field="epv" />
        </AgGridReact>
      </div>
      <div className="ag-theme-balham">
        <div
          className="ag-side-bar ag-unselectable ag-side-bar-right ag-focus-managed"
          style={{ backgroundColor: "white", height: "100%" }}
        >
          <div className="ag-side-buttons">
            <div
              className="ag-side-button"
              role="presentation"
              onClick={() => setFilterOpened(!filterOpened)}
            >
              <button
                type="button"
                tabIndex="-1"
                role="tab"
                className="ag-side-button-button"
              >
                <div className="ag-side-button-icon-wrapper" aria-hidden="true">
                  <span
                    className="ag-icon ag-icon-filter"
                    unselectable="on"
                    role="presentation"
                  />
                </div>
                <span className="ag-side-button-label"> Filters</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BuildingsMap;
