import React, { useState, useEffect } from "react";
import { useWindowDimensions } from "../../../utils/useWindowDimensions";
import { connect } from "react-redux";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import { getFontSize } from "../../../utils/getFontSize";
import BuildingsMap from "./BuildingsMap";
import GenerationMap from "./GenerationMap";
import TransportMap from "./TransportMap";
import ElectricityGrid from "./ElectricityGrid";
import RoadNetwork from "./RoadNetwork";
import Photovoltaic from "./Photovoltaic.tsx";
import Ape from "./Ape.tsx";
import Pollutants from "./Pollutants.tsx";

export const MainContent = (props) => {
  const { width, height } = useWindowDimensions();
  const padding = height * 0.01;
  const [activeTab, setActiveTab] = useState("1");

  return (
    <Row style={{ margin: 0, padding: padding }}>
      <Col
        xs="12"
        style={{
          height: "100%",
          padding: padding,
        }}
      >
        <Nav tabs style={{ fontSize: getFontSize("medium") }}>
          <NavItem style={{ marginRight: 5 }}>
            <NavLink
              className={activeTab === "1" ? "active" : ""}
              onClick={() => setActiveTab("1")}
              style={{ marginBottom: -2, borderBottom: "2px solid white" }}
            >
              City
            </NavLink>
          </NavItem>
          <NavItem style={{ marginRight: 5 }}>
            <NavLink
              className={activeTab === "2" ? "active" : ""}
              onClick={() => setActiveTab("2")}
              style={{ marginBottom: -2, borderBottom: "2px solid white" }}
            >
              Photovoltaic
            </NavLink>
          </NavItem>
          <NavItem style={{ marginRight: 5 }}>
            <NavLink
              className={activeTab === "3" ? "active" : ""}
              onClick={() => setActiveTab("3")}
              style={{ marginBottom: -2, borderBottom: "2px solid white" }}
            >
              Electricity Infrastructure
            </NavLink>
          </NavItem>
          <NavItem style={{ marginRight: 5 }}>
            <NavLink
              className={activeTab === "4" ? "active" : ""}
              onClick={() => setActiveTab("4")}
              style={{ marginBottom: -2, borderBottom: "2px solid white" }}
            >
              Public transport
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "5" ? "active" : ""}
              onClick={() => setActiveTab("5")}
              style={{ marginBottom: -2, borderBottom: "2px solid white" }}
            >
              Eletricity grid
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "6" ? "active" : ""}
              onClick={() => setActiveTab("6")}
              style={{ marginBottom: -2, borderBottom: "2px solid white" }}
            >
              Road network
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "7" ? "active" : ""}
              onClick={() => setActiveTab("7")}
              style={{ marginBottom: -2, borderBottom: "2px solid white" }}
            >
              EPC
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "8" ? "active" : ""}
              onClick={() => setActiveTab("8")}
              style={{ marginBottom: -2, borderBottom: "2px solid white" }}
            >
              Pollutants
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          {activeTab === "1" ? (
            <TabPane tabId="1">
              <div
                style={{
                  height: "100%",
                  backgroundColor: "white",
                  borderRadius: 7,
                  borderTopLeftRadius: 0,
                  border: "1px solid black",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    padding: 10,
                    height: height * 0.708,
                  }}
                >
                  <BuildingsMap />
                </div>
              </div>
            </TabPane>
          ) : activeTab === "2" ? (
            <TabPane tabId="2">
              <div
                style={{
                  height: "100%",
                  backgroundColor: "white",
                  borderRadius: 7,
                  borderTopLeftRadius: 7,
                  border: "1px solid black"
                }}
              >
                <div
                  style={{
                    height: "100%",
                    padding: 10,
                    height: height * 0.708,
                  }}
                >
                  <Photovoltaic />
                </div>
              </div>
            </TabPane>
          ) : activeTab === "3" ? (
            <TabPane tabId="3">
              <div
                style={{
                  height: "100%",
                  backgroundColor: "white",
                  borderRadius: 7,
                  borderTopLeftRadius: 7,
                  border: "1px solid black"
                }}
              >
                <div
                  style={{
                    height: "100%",
                    padding: 10,
                    height: height * 0.708,
                  }}
                >
                  <GenerationMap />
                </div>
              </div>
            </TabPane>
          ) : activeTab === "4" ? (
            <TabPane tabId="4">
              <div
                style={{
                  height: "100%",
                  backgroundColor: "white",
                  borderRadius: 7,
                  borderTopLeftRadius: 7,
                  border: "1px solid black"
                }}
              >
                <div
                  style={{
                    height: "100%",
                    padding: 10,
                    height: height * 0.708,
                  }}
                >
                  <TransportMap />
                </div>
              </div>
            </TabPane>
          ) : activeTab === "5" ? (
            <TabPane tabId="5">
              <div
                style={{
                  height: "100%",
                  backgroundColor: "white",
                  borderRadius: 7,
                  borderTopLeftRadius: 7,
                  border: "1px solid black"
                }}
              >
                <div
                  style={{
                    height: "100%",
                    padding: 10,
                    height: height * 0.708,
                  }}
                >
                  <ElectricityGrid />
                </div>
              </div>
            </TabPane>
          ) : activeTab === "6" ? (
            <TabPane tabId="6">
              <div
                style={{
                  height: "100%",
                  backgroundColor: "white",
                  borderRadius: 7,
                  borderTopLeftRadius: 7,
                  border: "1px solid black"
                }}
              >
                <div
                  style={{
                    height: "100%",
                    padding: 10,
                    height: height * 0.708,
                  }}
                >
                  <RoadNetwork />
                </div>
              </div>
            </TabPane>
          ) : activeTab === "7" ? (
            <TabPane tabId="7">
              <div
                style={{
                  height: "100%",
                  backgroundColor: "white",
                  borderRadius: 7,
                  borderTopLeftRadius: 7,
                  border: "1px solid black"
                }}
              >
                <div
                  style={{
                    height: "100%",
                    padding: 10,
                    height: height * 0.708,
                  }}
                >
                  <Ape />
                </div>
              </div>
            </TabPane>
          ) : activeTab === "8" ? (
            <TabPane tabId="8">
              <div
                style={{
                  height: "100%",
                  backgroundColor: "white",
                  borderRadius: 7,
                  borderTopLeftRadius: 7,
                  border: "1px solid black"
                }}
              >
                <div
                  style={{
                    height: "100%",
                    padding: 10,
                    height: height * 0.708,
                  }}
                >
                  <Pollutants />
                </div>
              </div>
            </TabPane>
          ) : null}
        </TabContent>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(MainContent);
