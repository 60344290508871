import React, { useEffect } from "react";
import { connect } from "react-redux";
import { setToolbarPage } from "../../actions/toolbar";
import { closeMenuOC } from "../../actions/handleOpenCloseActions";
import TopRow from "../../layout/CTP/_old/TopRow";
import BottomRow from "../../layout/CTP/_old/BottomRow";
import MainContent from "./components/MainContent";

export const ModellingParameters = (props) => {
  useEffect(() => {
    props.setToolbarPage("Modelling parameters");
    props.closeMenuOC();
  }, []);

  return (
    <div className="light-blue-background">
      <TopRow showSettings={false}></TopRow>

      <MainContent></MainContent>

      <BottomRow></BottomRow>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setToolbarPage, closeMenuOC })(
  ModellingParameters
);
