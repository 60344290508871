import React, { useEffect } from "react";
import Sidebar from "./components/Sidebar";
import Container from "@mui/material/Container";
import clsx from "clsx";
import { connect } from "react-redux";
import { setToolbarPage } from "../../actions/toolbar";
import { openMenuOC } from "../../actions/handleOpenCloseActions";
import Buildings from "./routes_sidebar/buildings";
import { Route } from "react-router-dom";
import ThreeIconsMenu from "../../layout/PdPLayout/ThreeIconsMenu";
import BottomInfoBar from "../../layout/PdPLayout/BottomInfoBar";

export const Homepage = (props) => {
  const { setToolbarPage, openMenuOC } = props;
  useEffect(() => {
    setToolbarPage("Data CTP");
    openMenuOC();
  }, [setToolbarPage, openMenuOC]);
  return (
    <React.Fragment>
      <ThreeIconsMenu></ThreeIconsMenu>
      <Sidebar></Sidebar>
      <main
        className={clsx(
          props.classes.content,
          !props.open && props.classes.contentShift
        )}
      >
        <div className={props.classes.appBarSpacer} />
        <Container
          maxWidth="xl"
          className={props.classes.container}
          style={{ padding: 0 }}
        >
          <div style={{ height: "100vh" }}>
            <Route
              path="/ctp/dashboard/data/buildings"
              render={(props) => <Buildings {...props} />}
            ></Route>
          </div>
        </Container>
      </main>
      <BottomInfoBar></BottomInfoBar>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  open: state.handleOpenClose.open,
});

export default connect(mapStateToProps, {
  setToolbarPage,
  openMenuOC,
})(Homepage);
