import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";
import TitleCard from "../../../Homepage/components/TitleCard";
import { FaSpinner } from "react-icons/fa";
import { getHeightWorkspace } from "../../../../utils/getHeightWorkspace";
import { roundAtMost2Digits } from "../../../../utils/roundAtMost2Digits";

function KPI(props) {
  const distinctCategories = ["residHeating", "publTransport", "enCommunity"];
  const { width, height } = useWindowDimensions();
  const [heightSection, setHeightSection] = useState(0);
  const [policyToCompare, setPolicyToCompare] = useState([]);
  const widthDivLineSvg = width < 1500 ? 60 : 120;
  const heightDivElement = 26;

  useEffect(() => {
    const multiplier = Math.floor((props.policiesSelected.length + 1) / 6) + 1;
    setHeightSection(multiplier * 150);
  }, [props.policiesSelected]);

  useEffect(() => {
    var toCompare = [];
    props.baseScenario.forEach((obj) => {
      toCompare.push({
        key: obj.key,
        domain: obj.domain,
        uom: obj.uom,
        description_short: obj.description_short,
        description_long: obj.description_long,
        listValues: [{ name: "Baseline", value: obj.value }],
      });
    });
    toCompare.forEach((obj) => {
      props.policiesSelected.forEach((p) => {
        p.results.forEach((r) => {
          if (r.key === obj.key) {
            obj.listValues = [
              ...obj.listValues,
              { name: p.name, value: r.value },
            ];
          }
        });
      });
    });
    setPolicyToCompare(toCompare);
  }, [props.policiesSelected, props.baseScenario]);

  const deltaPerc = (value, list) => {
    var toReturn = roundAtMost2Digits(
      (value / Math.max(...list.map((v) => v.value))) * 100
    );
    if (String(toReturn) === "NaN" || String(toReturn) === "Infinity") {
      toReturn = 0;
    }
    return toReturn;
  };
  return (
    <Col
      xxl={width > 1900 ? "10" : "9"}
      xl="9"
      lg="9"
      md="12"
      sm="12"
      style={{
        padding: 10,
        height: getHeightWorkspace(
          props.pages,
          height,
          props.layout.subnavbar_height_empty_ctp,
          props.layout.subnavbar_height_not_empty_ctp
        ),
        marginBottom: 40,
      }}
    >
      <div
        style={{
          ...props.layout.generic_card_ctp,
          display: "flex",
          flexFlow: "column",
          height: "100%",
          position: "relative",
          overflow: "auto",
        }}
      >
        {props.isLoading === true ? (
          <div style={{ padding: 20 }}>
            <FaSpinner className="spinner"></FaSpinner>
          </div>
        ) : (
          <>
            <TitleCard title="KPI"></TitleCard>
            {distinctCategories.map((category) => {
              return (
                <>
                  <div
                    style={{
                      padding: "25px 25px 0px 25px",
                      display: "flex",
                      alignItems: "center",
                      fontFamily: "Montserrat",
                      fontWeight: "bold",
                    }}
                  >
                    <div
                      style={{
                        width: 18,
                        height: 18,
                        backgroundColor: props.layout.yellow_detail_ctp,
                        borderRadius: 5,
                        marginRight: 10,
                      }}
                    ></div>
                    <div
                      style={{
                        fontSize: 15,
                      }}
                    >
                      {category === "residHeating"
                        ? "Residential heating"
                        : category === "publTransport"
                        ? "Public transport"
                        : category === "enCommunity"
                        ? "Energy Community"
                        : ""}
                    </div>
                  </div>
                  {policyToCompare
                    .filter((compare) => compare.domain === category)
                    .map((compare) => {
                      return (
                        <div
                          key={compare.key}
                          style={{
                            flex: "1 1 auto",
                            borderRadius:
                              props.layout.generic_card_ctp.borderRadius,
                            height: "auto",
                            width: "100%",
                            // overflow: "auto",
                            fontFamily: "Montserrat",
                            fontWeight: "bold",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              fontSize: 13,
                              padding: "0px 30px",
                            }}
                          >
                            {/* section */}
                            <div
                              style={{
                                width: "100%",
                                height: heightSection,
                                display: "flex",
                                // backgroundColor: "red",
                                marginTop: 10,
                                marginBottom: 10,
                              }}
                            >
                              {/* kpi */}
                              <div
                                style={{
                                  width: "30%",
                                  display: "flex",
                                  alignItems: "center",
                                  // backgroundColor: "yellow",
                                }}
                              >
                                <div
                                  style={{
                                    height: heightDivElement,
                                    width: "100%",
                                    borderBottom: `2px solid ${props.layout.color_footer_ctp}`,
                                    // backgroundColor: "orange",
                                    display: "flex",
                                    alignItems: "end",
                                  }}
                                >
                                  {`${compare.description_short} [${compare.uom}]`}
                                </div>
                              </div>

                              {/* lines */}
                              <div
                                style={{
                                  width: widthDivLineSvg,
                                  position: "relative",
                                  // backgroundColor: "orange",
                                }}
                              >
                                <svg style={{ overflow: "visible" }}>
                                  {compare.listValues.map((policy, index) => {
                                    return (
                                      <line
                                        x1="0"
                                        y1={heightSection / 2 + 12}
                                        x2={widthDivLineSvg}
                                        y2={
                                          (heightSection /
                                            compare.listValues.length) *
                                            index +
                                          heightSection /
                                            compare.listValues.length /
                                            2 +
                                          heightDivElement * 0.46
                                        }
                                        stroke={props.layout.color_footer_ctp}
                                        stroke-width="2"
                                      />
                                    );
                                  })}
                                </svg>
                              </div>

                              {/* comparison */}
                              <div
                                style={{
                                  display: "flex",
                                  flexGrow: 1,
                                  flexDirection: "column",
                                  justifyContent: "space-around",
                                  // backgroundColor: "green",
                                  alignItems: "center",
                                }}
                              >
                                {compare.listValues.map((policy) => {
                                  return (
                                    <div
                                      style={{
                                        width: "100%",
                                        // backgroundColor: "red",
                                        overflow: "hidden",
                                        height: heightDivElement,
                                        display: "flex",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "50%",
                                          // backgroundColor: "yellow",
                                          lineHeight: `${
                                            heightDivElement * 1.2
                                          }px`,
                                          textAlign: "center",
                                          borderBottom: `2px solid ${props.layout.color_footer_ctp}`,
                                          fontSize: 14,
                                        }}
                                      >
                                        {policy.value.toLocaleString()}{" "}
                                        <i>
                                          (
                                          {deltaPerc(
                                            policy.value,
                                            compare.listValues
                                          )}
                                          % ){" "}
                                        </i>
                                      </div>
                                      <div
                                        style={{
                                          width: "50%",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          borderRadius: 7,
                                          marginLeft: "-6px",
                                          color: "white",
                                          background: `linear-gradient(to right, ${
                                            props.layout.color_footer_ctp
                                          } ${deltaPerc(
                                            policy.value,
                                            compare.listValues
                                          )}%, #C5C6CD ${deltaPerc(
                                            policy.value,
                                            compare.listValues
                                          )}%)`,
                                          fontSize: 14,
                                        }}
                                      >
                                        {policy.name}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </>
              );
            })}
          </>
        )}
      </div>
    </Col>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(KPI);
