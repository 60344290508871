import React, { useEffect, useState } from "react";
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import axios from "axios";
import { API_URL } from "../../../../actions/types";
import { tokenConfig } from "../../../../utils/tokenConfig";
import ActionDomains from "./ActionDomains";
import ActionDetails from "./ActionDetails";
import ImpactAssessment from "./ImpactAssessment";
import { getHeightWithFooterWONavbar } from "../../../../utils/getHeightWithFooterWONavbar";

function Content(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [domainParams, setDomainParams] = useState([]);
  const [actionDetails, setActionDetails] = useState([]);
  const [baseScenario, setBaseScenario] = useState([]);
  const [actionImpact, setActionImpact] = useState([]);
  const { width, height } = useWindowDimensions();

  useEffect(async () => {
    // get default values
    const resDefaultValues = await axios.get(
      `${API_URL}/actions/default/`,
      tokenConfig()
    );
    setDomainParams(resDefaultValues.data);
    // get base scenario
    const resBaseScenario = await axios.get(
      `${API_URL}/scenarios/default/`,
      [],
      tokenConfig()
    );
    setBaseScenario(resBaseScenario.data);
    setIsLoading(false);
  }, []);

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#E4E6E8",
        height: getHeightWithFooterWONavbar(
          props.pages,
          height,
          props.layout.subnavbar_height_empty_ctp,
          props.layout.subnavbar_height_not_empty_ctp
        ),
        paddingLeft: props.layout.width_sidebar_ctp,
        paddingBottom: props.layout.footer_height_ctp,
        overflow: "auto",
      }}
    >
      {/* workspace without padding */}
      <div
        style={{
          height: "100%",
          width: "100%",
          padding: 10,
        }}
      >
        {/* workspace */}
        <div
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <Row style={{ margin: 0 }}>
            <ActionDomains
              pages={props.pages}
              isLoading={isLoading}
              domainParams={domainParams}
              setDomainParams={setDomainParams}
              actionDetails={actionDetails}
              setActionDetails={setActionDetails}
              actionImpact={actionImpact}
              setActionImpact={setActionImpact}
            ></ActionDomains>
            <ActionDetails
              pages={props.pages}
              isLoading={isLoading}
              actionDetails={actionDetails}
              setActionDetails={setActionDetails}
            ></ActionDetails>
            <ImpactAssessment
              pages={props.pages}
              isLoading={isLoading}
              baseScenario={baseScenario}
              actionImpact={actionImpact}
            ></ImpactAssessment>
          </Row>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(Content);
