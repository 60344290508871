import React, { useState } from "react";
import DrawerWrapper from "../../../layout/PdPLayout/DrawerWrapper";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import {
  FaAngleUp,
  FaAngleDown,
  FaAngleDoubleRight,
  FaBuilding,
  FaBolt,
} from "react-icons/fa";
import { Link } from "react-router-dom";

export const Sidebar = () => {
  const [open1, setOpen1] = useState(true);
  // const [open5, setOpen5] = useState(false);

  const styleLink = {
    color: "white",
    fontSize: "16px",
    textDecoration: "none",
  };
  const styleListItem = {
    backgroundColor: "#0A56A7",
    paddingTop: 0,
    marginTop: "-10px",
  };
  const styleListItemIcon = {
    color: "white",
    minWidth: "46px",
    marginLeft: "9px",
  };

  const mainList = (
    <React.Fragment>
      {/* gas price */}
      <Link style={styleLink} to={"/ctp/dashboard/data/buildings"}>
        <ListItem button>
          <ListItemIcon
            style={{ color: "white", display: "flex", alignItems: "center" }}
          >
            <FaBuilding></FaBuilding>
            <ListItemText
              primary={"Buildings"}
              style={{ marginLeft: "31px" }}
            />
          </ListItemIcon>
        </ListItem>
      </Link>
    </React.Fragment>
  );

  return <DrawerWrapper mainList={mainList}></DrawerWrapper>;
};

export default Sidebar;
