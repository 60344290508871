import {} from "../actions/types";

const initialState = {
  // CTP layout
  width_sidebar_ctp: 50,
  color_sidebar_ctp: "#374FE4",
  color_border_button_sidebar_ctp: "#85AAFF",
  color_background_button_sidebar_ctp: "#5268EC",
  height_button_submenu_ctp: 30,
  color_navbar_ctp: "white",
  subnavbar_height_empty_ctp: 6,
  subnavbar_height_not_empty_ctp: 30,
  color_main_content_background_ctp: "#E4E6E8",
  color_footer_ctp: "#1A2546",
  footer_height_ctp: 35,
  yellow_detail_ctp: "#FFCC00",
  generic_card_ctp: {
    width: "100%",
    height: "100%",
    borderRadius: 20,
    backgroundColor: "white",
    boxShadow: "1px 1px 5px rgb(133,164,204,0.7)",
  },
};

export default function toolbar(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
