import React, { useState } from "react";
import { connect } from "react-redux";
import { useWindowDimensions } from "../../../utils/useWindowDimensions";
import { Row, Col } from "reactstrap";
import LogoTorino from "../../../../images/torino_logo_full.png";
import LogoPolitecnico from "../../../../images/logo_est_polito_EC_noBackgroud.png";
import { FaCog, FaAngleDoubleLeft, FaGlobe } from "react-icons/fa";
import { getFontSize } from "../../../utils/getFontSize";
import { useHistory } from "react-router-dom";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Button } from "reactstrap";
import { logout } from "../../../actions/auth";
import { API_URL } from "../../../actions/types";
import { GiAtom } from "react-icons/gi";


export const TopRow = (props) => {
  const [modal, setModal] = useState(false);
  const { width, height } = useWindowDimensions();
  const history = useHistory();

  const toggle = () => setModal(!modal);

  return (
    <Row style={{ margin: 0 }}>
      <Col
        xs="12"
        style={{
          width: "100%",
          height: height * 0.1,
          display: "flex",
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div style={{ height: "100%" }}>
          {/* <img
            src={LogoTorino}
            alt="logo"
            style={{
              height: width < 500 ? "80%" : "100%",
              width: "auto",
              padding: 5,
            }}
          ></img> */}
          <img
            src={LogoPolitecnico}
            alt="logo"
            style={{
              height: width < 500 ? "80%" : "100%",
              width: "auto",
              padding: 5,
            }}
          ></img>
        </div>

        {props.showSettings === true ? (
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              paddingTop: 9,
            }}
          >
            <div
              onClick={setModal}
              style={{
                border: "1px solid white",
                padding: "5px 15px",
                borderRadius: 7,
                boxShadow: "2px 2px 2px #ADADAD",
                cursor: "pointer",
                fontSize: getFontSize("large"),
              }}
            >
              Settings
              <FaCog
                style={{
                  marginLeft: 8,
                  marginTop: "-1px",
                  fontSize: width > 4000 ? 30 : 20,
                }}
              ></FaCog>
            </div>
          </div>
        ) : (
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              paddingTop: 9,
            }}
          >
            {/* <div
              onClick={() =>
                window.location.replace(
                  API_URL.replace("/api", "/clicc/dashboard/homepage")
                )
              }
              style={{
                border: "1px solid white",
                padding: "5px 15px",
                borderRadius: 7,
                boxShadow: "2px 2px 2px #ADADAD",
                cursor: "pointer",
                fontSize: getFontSize("large"),
                marginRight: 20,
              }}
            >
              <FaGlobe
                style={{
                  marginRight: 8,
                  marginTop: "-3px",
                  fontSize: width > 4000 ? 26 : 16,
                }}
              ></FaGlobe>
              Access CLICC
            </div> */}
            {/* <div
              onClick={() => history.push("/ctp/dashboard/homepage")}
              style={{
                border: "1px solid white",
                padding: "5px 15px",
                borderRadius: 7,
                boxShadow: "2px 2px 2px #ADADAD",
                cursor: "pointer",
                fontSize: getFontSize("large"),
              }}
            >
              <FaAngleDoubleLeft
                style={{
                  marginRight: 8,
                  marginTop: "-1px",
                  fontSize: width > 4000 ? 30 : 20,
                }}
              ></FaAngleDoubleLeft>
              Back to CITTA
            </div> */}
            {/* <a
              style={{
                border: "1px solid white",
                padding: "5px 15px",
                borderRadius: 7,
                boxShadow: "2px 2px 2px #ADADAD",
                cursor: "pointer",
                fontSize: getFontSize("large"),
                fontFamily: "Montserrat",
                fontWeight: 400,
                color: "black",
                textDecoration: "none"
              }}
              // if localhost use local version, else always in production  
              href={"http://192.168.167.51/city_explorer"}
              target="_blank"
            >
              <GiAtom
                style={{
                  marginRight: 8,
                  marginTop: "-1px",
                  fontSize: width > 4000 ? 30 : 24,
                }}
              ></GiAtom>
              Virtual City Model
            </a> */}
            {/* <div
              onClick={() => history.push("/ctp/dashboard/homepage")}
              style={{
                border: "1px solid white",
                padding: "5px 15px",
                borderRadius: 7,
                boxShadow: "2px 2px 2px #ADADAD",
                cursor: "pointer",
                fontSize: getFontSize("large"),
                fontFamily: "Montserrat",
                fontWeight: 400,
              }}
            >
              <GiAtom
                style={{
                  marginRight: 8,
                  marginTop: "-1px",
                  fontSize: width > 4000 ? 30 : 24,
                }}
              ></GiAtom>
              Virtual City Model
            </div> */}
          </div>
        )}
      </Col>
      {/* settings modal */}
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>
          <span style={{ fontSize: getFontSize("medium") }}>Settings</span>
        </ModalHeader>
        <ModalBody style={{ fontSize: getFontSize("medium") }}>
          Leave the application:
          <Button
            color="danger"
            onClick={() => props.logout()}
            style={{ marginLeft: 10, fontSize: getFontSize("small") }}
          >
            Logout
          </Button>
        </ModalBody>
      </Modal>
    </Row>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { logout })(TopRow);
