import React, { Component } from "react";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";

export class Alert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { errors } = this.props;
    if (errors !== prevProps.errors) {
      this.setState({ showModal: true });
    }
  }
  render() {
    const { errors } = this.props;
    return (
      <React.Fragment>
        <SweetAlert
          danger
          title="Error"
          show={this.state.showModal}
          onConfirm={() => this.setState({ showModal: false })}
          customButtons={
            <React.Fragment>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => this.setState({ showModal: false })}
              >
                Close
              </button>
            </React.Fragment>
          }
        >
          {errors.msg.username ||
          errors.msg.password ||
          errors.msg.non_field_errors
            ? "Credentials not valid"
            : typeof errors.msg === "string"
            ? errors.msg.includes("A valid number is required")
              ? "A valid number is required"
              : errors.msg.length < 100
              ? errors.msg
              : null
            : Object.keys(errors.msg).includes("detail")
            ? errors.msg.detail
            : null}
        </SweetAlert>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  errors: state.errors,
});

export default connect(mapStateToProps)(Alert);
