import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import {
  FaHome,
  FaDatabase,
  FaChartLine,
  FaClipboardList,
  FaBalanceScale,
} from "react-icons/fa";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  speedDial: {
    position: "fixed",
    right: "110px",
    top: "20px",
    zIndex: 999,
  },
}));

export const SpeedDials = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const actions = [{ icon: <FaHome />, name: "Homepage" }];

  let history = useHistory();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseMenu = () => {
    setOpen(false);
  };

  const handleRedirect = (action) => {
    setOpen(false);
    if (action.name === "Homepage") {
      history.push("/ctp/dashboard/homepage");
    }
  };

  return (
    <React.Fragment>
      <SpeedDial
        ariaLabel="SpeedDial tooltip"
        className={classes.speedDial}
        icon={<i style={{ fontSize: "24px" }} className="fa fa-bars"></i>}
        onClose={handleCloseMenu}
        onOpen={handleOpen}
        open={open}
        direction="down"
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={() => handleRedirect(action)}
          />
        ))}
      </SpeedDial>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(SpeedDials);
