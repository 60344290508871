import React, { useEffect, useState } from "react";
import TitleCard from "../../../../Homepage/components/TitleCard";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import { useWindowDimensions } from "../../../../../utils/useWindowDimensions";
import { TextField } from "@mui/material";
import { InputAdornment } from "@mui/material";
import Plotly from "plotly.js-dist";
import { usePlotlyConfig } from "../../../../../utils/usePlotlyConfig";

function SelectValueTab(props) {
  const { width, height } = useWindowDimensions();
  const plotlyConfig = usePlotlyConfig();

  useEffect(() => {
    var trace1 = {
      x: props.transportData.map((obj) => obj.name),
      y: props.transportData.map((obj) => obj.default_value),
      // name: "SF Zoo",
      type: "bar",
    };

    var trace2 = {
      x: props.transportData.map((obj) => obj.name),
      y: props.transportData.map((obj) => obj.value - obj.default_value),
      marker: {
        color: props.transportData.map((obj) =>
          obj.value - obj.default_value < 0 ? "#C8C8C8" : "#3DD657"
        ),
      },
      // name: "LA Zoo",
      type: "bar",
    };

    var data = [trace1, trace2];

    var layout = {
      barmode: "stack",
      showlegend: false,
      margin: {
        l: 70,
        r: 70,
        b: 80,
        t: 40,
      },
      paper_bgcolor: "rgba(0,0,0,0)",
      plot_bgcolor: "rgba(0,0,0,0)",
    };

    Plotly.newPlot("vehiclesSelectedChart", data, layout, {
      ...plotlyConfig,
      displayModeBar: false,
    });
  }, [props.transportData]);

  return (
    <div
      style={{
        fontFamily: "Montserrat",
        height: "100%",
      }}
    >
      <Row style={{ margin: 0, height: "100%" }}>
        <Col
          lg="4"
          md="12"
          style={{
            height: width > 770 ? "100%" : "50%",
            padding: "20px 10px",
          }}
        >
          <div
            style={{
              ...props.layout.generic_card_ctp,
              overflow: "auto",
            }}
          >
            <TitleCard
              title="Fleet detail"
              customBorderHeight={2}
              customTitleSize={{ large: 19, small: 17 }}
            ></TitleCard>
            <div
              style={{
                marginTop: 10,
                marginBottom: 10,
                padding: "0px 15px",
              }}
            >
              {props.transportData.map((obj) => {
                return (
                  <>
                    <div
                      style={{
                        width: "100%",
                        borderBottom: "1px solid #666666",
                        marginTop: 20,
                        fontSize: 14,
                      }}
                    >
                      {obj.name}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        fontSize: 20,
                      }}
                    >
                      <TextField
                        value={obj.value}
                        onChange={(e) => {
                          props.setTransportData(
                            props.transportData.map((t) => {
                              if (t.id !== obj.id) {
                                return t;
                              } else {
                                return {
                                  ...t,
                                  value: e.target.value,
                                  deltaPerc: Math.round(
                                    ((e.target.value - obj.default_value) /
                                      obj.default_value) *
                                      100
                                  ),
                                };
                              }
                            })
                          );
                        }}
                        disabled={props.read_only}
                        type="number"
                        variant="standard"
                        InputProps={{
                          disableUnderline: true, // <== added this
                          style: {
                            fontFamily: "Montserrat",
                            fontSize: 22,
                          },
                        }}
                        style={{
                          width: 55,
                        }}
                      />
                      <TextField
                        type="number"
                        variant="standard"
                        disabled={props.read_only}
                        value={obj.deltaPerc}
                        onChange={(e) =>
                          props.setTransportData(
                            props.transportData.map((t) => {
                              if (t.id !== obj.id) {
                                return t;
                              } else {
                                return {
                                  ...t,
                                  deltaPerc: e.target.value,
                                  value:
                                    Math.round(
                                      (e.target.value / 100 + 1) *
                                        obj.default_value
                                    ) === obj.value
                                      ? obj.value
                                      : Math.round(
                                          (e.target.value / 100 + 1) *
                                            obj.default_value
                                        ),
                                };
                              }
                            })
                          )
                        }
                        InputProps={{
                          disableUnderline: true, // <== added this
                          style: {
                            fontFamily: "Montserrat",
                            fontSize: 22,
                          },
                          endAdornment: (
                            <InputAdornment
                              position="start"
                              style={{ color: "black", fontWeight: "bold" }}
                            >
                              <span style={{ fontSize: 20, marginLeft: 5 }}>
                                %
                              </span>
                            </InputAdornment>
                          ),
                        }}
                        style={{
                          width: 90,
                        }}
                      />
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </Col>
        <Col
          lg="8"
          md="12"
          style={{
            height: width > 770 ? "100%" : "50%",
            padding: "20px 10px",
          }}
        >
          <div
            style={{
              ...props.layout.generic_card_ctp,
              overflow: "auto",
            }}
          >
            <TitleCard
              title="Vehicles selected"
              customBorderHeight={2}
              customTitleSize={{ large: 19, small: 17 }}
            ></TitleCard>
            <div
              style={{
                marginTop: 10,
                marginBottom: 10,
                padding: "0px 15px",
                width: "100%",
                height: "87%",
              }}
            >
              <div
                id="vehiclesSelectedChart"
                style={{
                  width: "100%",
                  height: "100%",
                }}
              ></div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(SelectValueTab);
