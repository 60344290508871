import React, { useEffect, useState } from "react";

export default function CheckboxHeader(props) {
  const [headerChecked, setHeaderChecked] = useState(false);

  useEffect(() => {
    var checked = true;
    var list_id_all = [];
    props.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
      list_id_all.push(rowNode.data.identifier);
      if (!props.selectedFeatures.includes(rowNode.data.identifier)) {
        checked = false;
      }
    });
    if (list_id_all.length === 0) {
      setHeaderChecked(false);
    } else {
      setHeaderChecked(checked);
    }
  }, [props.filterChanged, props.api]);

  const checkedHandler = (e) => {
    if (props.read_only === false) {
      var list_id = [];
      props.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
        list_id.push(rowNode.data.identifier);
      });
      if (e.target.checked === false) {
        props.setSelectedFeautures(
          props.selectedFeatures.filter((id) => !list_id.includes(id))
        );
      } else {
        props.setSelectedFeautures([
          ...new Set([...props.selectedFeatures, ...list_id]),
        ]);
      }
    }
  };

  return (
    <input
      type="checkbox"
      onClick={checkedHandler}
      checked={headerChecked}
      readOnly
    />
  );
}
