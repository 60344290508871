import React from "react";
import { useWindowDimensions } from "../../../utils/useWindowDimensions";
import { Row, Col } from "reactstrap";
import LogoPoli from "../../../../images/logo_est_polito_EC_noBackgroud.png";

export const BottomRow = (props) => {
  const { width, height } = useWindowDimensions();

  return (
    <Row style={{ margin: 0 }}>
      <Col
        xs="12"
        style={{
          width: "100%",
          height: height * 0.1,
          display: "flex",
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        <img
          src={LogoPoli}
          alt="logo"
          style={{
            height: "100%",
            width: "auto",
            padding: 5,
          }}
        ></img>
      </Col>
    </Row>
  );
};

export default BottomRow;
