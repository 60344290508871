export const exportSheetNameTable = (params, sheetName) => {
  return [
    "copy",
    "copyWithHeaders",
    "paste",
    "separator",
    {
      name: "Export",
      icon: '<span class="ag-icon ag-icon-save" unselectable="on" role="presentation"></span>',
      subMenu: [
        "csvExport",
        {
          name: "Excel Export (.xlsx)",
          icon: '<span class="ag-icon ag-icon-excel" unselectable="on" role="presentation"></span>',
          action: () => {
            params.api.exportDataAsExcel({
              sheetName: sheetName,
            });
          },
        },
      ],
    },
  ];
};
