import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";
import TitleCard from "../../../Homepage/components/TitleCard";
import { FaSpinner } from "react-icons/fa";
import { getHeightWorkspace } from "../../../../utils/getHeightWorkspace";
import CardImpactAssessment from "./CardImpactAssessment";
import { roundAtMost2Digits } from "../../../../utils/roundAtMost2Digits";

function ImpactAssessment(props) {
  const { width, height } = useWindowDimensions();
  const distinctCategories = ["residHeating", "publTransport", "enCommunity"];

  const diffPerc = (value1, value2) => {
    var diff = ((value1 - value2) / value2) * 100;
    if (String(diff) === "NaN" || String(diff) === "Infinity") {
      diff = "--";
    } else if (diff >= 10 || diff <= -10) {
      diff = diff.toFixed(1);
    } else {
      diff = roundAtMost2Digits(diff).toLocaleString();
    }
    return `${diff}%`;
  };

  return (
    <Col
      xxl={width > 1700 ? "8" : "6"}
      xl="6"
      lg="6"
      md="12"
      sm="12"
      style={{
        padding: 10,
        height: getHeightWorkspace(
          props.pages,
          height,
          props.layout.subnavbar_height_empty_ctp,
          props.layout.subnavbar_height_not_empty_ctp
        ),
        marginBottom: 40,
      }}
    >
      <div
        style={{
          ...props.layout.generic_card_ctp,
          display: "flex",
          flexFlow: "column",
          height: "100%",
          position: "relative",
        }}
      >
        {props.isLoading === true ? (
          <div style={{ padding: 20 }}>
            <FaSpinner className="spinner"></FaSpinner>
          </div>
        ) : (
          <>
            <TitleCard title="Impact assessment"></TitleCard>
            {/* legenda */}
            <div
              style={{
                border: "1px solid #E2E2E2",
                padding: 10,
                borderRadius: 10,
                fontFamily: "Montserrat",
                fontWeight: 500,
                fontSize: 13,
                width: 180,
                position: "absolute",
                top: 20,
                right: 20,
                backgroundColor: "white",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: 10,
                    height: 10,
                    backgroundColor: "gray",
                    borderRadius: "50%",
                    marginRight: 10,
                  }}
                ></div>
                BASELINE
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: 10,
                    height: 10,
                    backgroundColor: props.layout.color_sidebar_ctp,
                    borderRadius: "50%",
                    marginRight: 10,
                  }}
                ></div>
                ACTION IMPACT
              </div>
            </div>
            <div
              style={{
                // padding: "10px 15px",
                flex: "1 1 auto",
                borderRadius: props.layout.generic_card_ctp.borderRadius,
                height: "auto",
                width: "100%",
                overflow: "auto",
              }}
            >
              <div
                style={{
                  width: "100%",
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  fontSize: 13,
                }}
              >
                {distinctCategories.map((category) => {
                  return (
                    <>
                      <div
                        style={{
                          padding: "25px 15px 0px 15px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: 18,
                            height: 18,
                            backgroundColor: props.layout.yellow_detail_ctp,
                            borderRadius: 5,
                            marginRight: 10,
                          }}
                        ></div>
                        <div style={{ fontWeight: "bold", fontSize: 15 }}>
                          {category === "residHeating"
                            ? "Residential heating"
                            : category === "publTransport"
                            ? "Public transport"
                            : category === "enCommunity"
                            ? "Energy Community"
                            : ""}
                        </div>
                      </div>
                      <Row style={{ margin: 0, padding: 10 }}>
                        {props.baseScenario
                          .filter((obj) => obj.domain === category)
                          .map((obj) => {
                            return (
                              <CardImpactAssessment
                                key={obj.key}
                                title={`${obj.description_short} [${obj.uom}]`}
                                value_1={roundAtMost2Digits(
                                  obj.value
                                ).toLocaleString()}
                                value_2={
                                  props.actionImpact.length > 0
                                    ? roundAtMost2Digits(
                                        props.actionImpact.find(
                                          (aI) => aI.key === obj.key
                                        ).value
                                      ).toLocaleString()
                                    : "--"
                                }
                                delta={
                                  props.actionImpact.length > 0
                                    ? diffPerc(
                                        props.actionImpact.find(
                                          (aI) => aI.key === obj.key
                                        ).value,
                                        obj.value
                                      )
                                    : ""
                                }
                              ></CardImpactAssessment>
                            );
                          })}
                      </Row>
                    </>
                  );
                })}
              </div>
            </div>
          </>
        )}
      </div>
    </Col>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(ImpactAssessment);
