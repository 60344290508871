import React, { useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import DropDownMenu from "./DropDownMenu";

function Sidebar(props) {
  const [showActions, setShowActions] = useState(false);
  const [showPolicy, setShowPolicy] = useState(false);
  const [showData, setShowData] = useState(false);
  const [showTracking, setShowTracking] = useState(false);
  const [showCLICC, setShowCLICC] = useState(false);
  const location = useLocation();

  const styleDivButton = {
    width: "100%",
    display: "flex",
    alignItems: "center",
    color: "white",
    cursor: "pointer",
    userSelect: "none",
  };

  const styleButton = {
    display: "flex",
    justifyContent: "center",
    fontSize: 18,
    fontWeight: "semi-bold",
    fontFamily: "Montserrat",
    transform: "rotate(-90deg)",
    width: 120,
    borderRadius: 10,
    backgroundColor: props.layout.color_background_button_sidebar_ctp,
  };

  const menuStructure = [
    {
      name: "ACTIONS",
      idParentLeft: "divActions",
      show: showActions,
      setShow: setShowActions,
      children: [
        {
          name: "Create Actions",
          url: "/ctp/dashboard/create_actions",
        },
      ],
    },
    {
      name: "POLICY",
      idParentLeft: "divPolicy",
      show: showPolicy,
      setShow: setShowPolicy,
      children: [
        {
          name: "Create Policy",
          url: "/ctp/dashboard/create_policy",
        },
        {
          name: "Compare Policy",
          url: "/ctp/dashboard/compare_policy",
        },
      ],
    },
    {
      name: "DATA",
      idParentLeft: "divData",
      show: showData,
      setShow: setShowData,
      children: [
        {
          name: "Buildings",
          url: "/ctp/dashboard/data/buildings",
        },
      ],
    },
    {
      name: "TRACKING",
      idParentLeft: "divTracking",
      show: showTracking,
      setShow: setShowTracking,
      children: [
        {
          name: "Not yet available",
          url: undefined,
        },
      ],
    },
    {
      name: "CLICC",
      idParentLeft: "divClicc",
      show: showCLICC,
      setShow: setShowCLICC,
      children: [
        {
          name: "Dashboard",
          url: undefined,
          external_url: true,
        },
      ],
    },
  ];

  return (
    <div
      style={{
        position: "absolute",
        height: "100vh",
        zIndex: 2,
        width: props.layout.width_sidebar_ctp,
        borderTopRightRadius: 25,
        borderBottomRightRadius: 25,
        backgroundColor: props.layout.color_sidebar_ctp,
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        alignContent: "space-around",
        justifyContent: "center",
        paddingTop: 50,
        paddingBottom: 50,
      }}
    >
      {menuStructure.map((menu) => {
        return (
          <div
            onMouseEnter={() => menu.setShow(true)}
            onMouseLeave={() => menu.setShow(false)}
          >
            <div style={styleDivButton} id={menu.idParentLeft}>
              <div
                style={{
                  ...styleButton,
                  border: menu.children
                    .map((obj) => obj.url)
                    .includes(location.pathname)
                    ? `2px solid ${props.layout.yellow_detail_ctp}`
                    : `2px solid ${props.layout.color_border_button_sidebar_ctp}`,
                }}
              >
                {menu.name}
              </div>
            </div>
            {menu.show === true ? (
              <DropDownMenu
                idParentLeft={menu.idParentLeft}
                pages={menu.children}
              ></DropDownMenu>
            ) : null}
          </div>
        );
      })}
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(Sidebar);
