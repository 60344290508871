import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import { connect, useDispatch } from "react-redux";
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";
import TitleCard from "../../../Homepage/components/TitleCard";
import { FaSpinner, FaPencilAlt, FaTimes } from "react-icons/fa";
import { Button } from "reactstrap";
import { getHeightWorkspace } from "../../../../utils/getHeightWorkspace";
import { Modal, ModalHeader, ModalBody, ModalFooter, Card } from "reactstrap";
import { API_URL, IS_LOADING } from "../../../../actions/types";
import axios from "axios";
import { tokenConfig } from "../../../../utils/tokenConfig";
import { TextField } from "@mui/material";
import SuccessAlert from "../../../../components/Modals/SuccessAlert";

function ActionDetails(props) {
  const [isOpenSavePolicy, setIsOpenSavePolicy] = useState(false);
  const [policyName, setPolicyName] = useState("");
  const [policyDescription, setPolicyDescription] = useState("");
  const { width, height } = useWindowDimensions();
  const dispatch = useDispatch();

  const toggleSavePolicy = () => setIsOpenSavePolicy(!isOpenSavePolicy);

  return (
    <Col
      xxl={width > 1700 ? "2" : "3"}
      xl="3"
      lg="3"
      md="6"
      sm="12"
      style={{
        position: "relative",
        padding: 10,
        height: getHeightWorkspace(
          props.pages,
          height,
          props.layout.subnavbar_height_empty_ctp,
          props.layout.subnavbar_height_not_empty_ctp
        ),
      }}
    >
      <div
        style={{
          ...props.layout.generic_card_ctp,
          display: "flex",
          flexFlow: "column",
          height: "100%",
        }}
      >
        {props.isLoading === true ? (
          <div style={{ padding: 20 }}>
            <FaSpinner className="spinner"></FaSpinner>
          </div>
        ) : (
          <>
            <TitleCard title="Action details"></TitleCard>
            <div
              style={{
                padding: "10px 15px",
                flex: "1 1 auto",
                borderRadius: props.layout.generic_card_ctp.borderRadius,
                height: "auto",
                width: "100%",
                overflow: "auto",
              }}
            >
              <div style={{ height: "90%", overflow: "auto" }}>
                <div
                  style={{
                    width: "100%",
                    fontFamily: "Montserrat",
                    fontWeight: 500,
                    fontSize: 14,
                  }}
                >
                  {props.actionDetails.map((sub_action) => {
                    return (
                      <>
                        <div
                          style={{
                            marginTop: 15,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {sub_action.subActionName}
                          <FaTimes
                            style={{ color: "#EE3C3C", cursor: "pointer" }}
                            onClick={() => {
                              props.setActionDetails(
                                props.actionDetails.filter(
                                  (a) => a.id !== sub_action.id
                                )
                              );
                            }}
                          ></FaTimes>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
              <div
                style={{
                  height: "10%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={() => setIsOpenSavePolicy(true)}
                  style={{
                    width: 150,
                    borderRadius: 15,
                    backgroundColor: props.layout.color_sidebar_ctp,
                    borderColor: "transparent",
                    cursor: "pointer",
                    fontWeight: 500,
                    fontFamily: "Montserrat",
                    marginTop: 15,
                  }}
                >
                  SAVE POLICY
                </Button>
              </div>
            </div>
          </>
        )}
      </div>

      {/* run arrow */}
      <div
        className="arrow-run"
        onClick={async () => {
          dispatch({
            type: IS_LOADING,
          });
          const res = await axios.post(
            `${API_URL}/scenarios/compute/`,
            props.actionDetails,
            tokenConfig()
          );
          props.setActionImpact(res.data);
          dispatch({
            type: IS_LOADING,
          });
        }}
        style={{
          position: "absolute",
          zIndex: 9,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          top: "50%",
          right: 0,
          marginRight: width < 1000 ? "16px" : "-16px",
          cursor: "pointer",
          fontFamily: "Montserrat",
          fontSize: 16,
        }}
      >
        <span style={{ marginLeft: 5 }}>RUN</span>
      </div>
      <Modal isOpen={isOpenSavePolicy} toggle={toggleSavePolicy} size="lg">
        <ModalHeader toggle={toggleSavePolicy}>Save policy</ModalHeader>
        <ModalBody>
          <Row style={{ margin: 0 }}>
            <Col lg="4" md="6" sm="12" style={{ height: "100%" }}>
              <div
                style={{
                  ...props.layout.generic_card_ctp,
                }}
              >
                <TitleCard
                  title="Assign a name"
                  customBorderHeight={2}
                  customTitleSize={{ large: 19, small: 17 }}
                ></TitleCard>
                <div style={{ padding: "15px 15px 25px 15px" }}>
                  <TextField
                    variant="standard"
                    value={policyName}
                    inputProps={{
                      style: {
                        fontWeight: 500,
                        padding: 0,
                        fontFamily: "Montserrat",
                      },
                    }}
                    style={{ width: "100%" }}
                    onChange={(e) => setPolicyName(e.target.value)}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row style={{ margin: 0 }}>
            <Col xs="12" style={{ height: "100%" }}>
              <div
                style={{
                  ...props.layout.generic_card_ctp,
                  marginTop: 20,
                }}
              >
                <TitleCard
                  title="Description"
                  customBorderHeight={2}
                  customTitleSize={{ large: 19, small: 17 }}
                ></TitleCard>
                <div style={{ padding: "15px 15px 25px 15px" }}>
                  <TextField
                    variant="outlined"
                    placeholder="..."
                    multiline
                    rows={6}
                    value={policyDescription}
                    inputProps={{
                      style: {
                        fontWeight: 500,
                        padding: 0,
                        fontFamily: "Montserrat",
                      },
                    }}
                    style={{ width: "100%" }}
                    onChange={(e) => setPolicyDescription(e.target.value)}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            onClick={async () => {
              await axios.post(
                `${API_URL}/actions/policies/`,
                {
                  name: policyName,
                  description: policyDescription,
                  sub_actions: props.actionDetails,
                },
                tokenConfig()
              );
              toggleSavePolicy();
              SuccessAlert("Policy created successfully");
            }}
          >
            Save
          </Button>
          <Button color="secondary" onClick={() => toggleSavePolicy()}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Col>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(ActionDetails);
