import { combineReducers } from "redux";
import handleOpenClose from "./handleOpenClose";
import errors from "./errors";
import auth from "./auth";
import toolbar from "./toolbar";
import isLoading from "./isLoading";
import layout from "./layout";

export default combineReducers({
  handleOpenClose,
  errors,
  auth,
  toolbar,
  isLoading,
  layout,
});
