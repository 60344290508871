import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from "classnames";
import SelectAreaTab from "./SelectAreaTab";
import SelectValueTab from "./SelectValueTab";
import CreateTab from "./CreateTab";
import TitleCard from "../../../../Homepage/components/TitleCard";

function ModalResidentialHeating(props) {
  const [activeTab, setActiveTab] = useState(1);
  const [selectedFeatures, setSelectedFeautures] = useState([]);
  const [percIncrease, setPercIncrease] = useState(0);
  const [subActionName, setSubActionName] = useState("");
  const [subActionDescription, setSubActionDescription] = useState("");

  const toggle = () => {
    props.setIsOpen(!props.isOpen);
  };

  useEffect(() => {
    if (props.selectedFeatures !== undefined) {
      setSelectedFeautures(props.selectedFeatures);
    }
    if (props.percIncrease !== undefined) {
      setPercIncrease(props.percIncrease);
    }
    if (props.subActionName !== undefined) {
      setSubActionName(props.subActionName);
    }
    if (props.subActionDescription !== undefined) {
      setSubActionDescription(props.subActionDescription);
    }
  }, []);
  const styleTabPane = {
    height: "100%",
    border: "1px solid #666666",
    marginTop: "-1px",
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
    borderBottomLeftRadius: 15,
    padding: "0px 15px",
  };

  return (
    <Modal
      unmountOnClose={true}
      isOpen={props.isOpen}
      toggle={toggle}
      style={{ maxWidth: "90vw" }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <TitleCard title={props.subActionName}></TitleCard>
        {/* show badge if read only = true */}
        {props.read_only === true ? (
          <div style={{ marginRight: 20, marginTop: 15 }}>
            <div
              style={{
                backgroundColor: "#FF9D51",
                padding: "2px 8px",
                borderRadius: 7,
                fontSize: 12,
              }}
            >
              Read only
            </div>
          </div>
        ) : null}
      </div>
      <ModalBody
        style={{
          height: "87vh",
          marginTop: 10,
          marginBottom: "-20px",
        }}
      >
        {/* leave 1% to hide "gray" line to be visible because of black border radious */}
        <Nav tabs style={{ width: "99%" }}>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 1 })}
              onClick={() => {
                setActiveTab(1);
              }}
              style={{ fontSize: 14 }}
            >
              SELECT AREA
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 2 })}
              onClick={() => {
                setActiveTab(2);
              }}
              style={{ fontSize: 14 }}
            >
              SELECT VALUE
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 3 })}
              onClick={() => {
                setActiveTab(3);
              }}
              style={{ fontSize: 14 }}
            >
              CREATE
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} style={{ height: "90%" }}>
          {activeTab === 1 ? (
            <TabPane tabId={1} style={styleTabPane}>
              <SelectAreaTab
                selectedFeatures={selectedFeatures}
                setSelectedFeatures={setSelectedFeautures}
                read_only={props.read_only}
              />
            </TabPane>
          ) : null}

          {activeTab === 2 ? (
            <TabPane tabId={2} style={styleTabPane}>
              <SelectValueTab
                selectedFeatures={selectedFeatures}
                percIncrease={percIncrease}
                setPercIncrease={setPercIncrease}
                read_only={props.read_only}
              ></SelectValueTab>
            </TabPane>
          ) : null}

          {activeTab === 3 ? (
            <TabPane tabId={3} style={styleTabPane}>
              <CreateTab
                idInputCategory={props.idInputCategory}
                idInputAction={props.idInputAction}
                selectedFeatures={selectedFeatures}
                percIncrease={percIncrease}
                subActionName={subActionName}
                setSubActionName={setSubActionName}
                subActionDescription={subActionDescription}
                setSubActionDescription={setSubActionDescription}
                read_only={props.read_only}
                type={props.type}
                actionDetails={props.actionDetails}
                setActionDetails={props.setActionDetails}
                toggle={toggle}
              ></CreateTab>
            </TabPane>
          ) : null}
        </TabContent>
      </ModalBody>
    </Modal>
  );
}

export default ModalResidentialHeating;
