import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";
import TitleCard from "../../../Homepage/components/TitleCard";
import { FaSpinner, FaPencilAlt, FaTimes } from "react-icons/fa";
import { Button } from "reactstrap";
import { getHeightWorkspace } from "../../../../utils/getHeightWorkspace";
import { Modal, ModalHeader, ModalBody, ModalFooter, Card } from "reactstrap";
import InfoAlert from "../../../../components/Modals/InfoAlert";
import { TextField } from "@mui/material";
import { API_URL } from "../../../../actions/types";
import { tokenConfig } from "../../../../utils/tokenConfig";
import SuccessAlert from "../../../../components/Modals/SuccessAlert";
import { useHistory } from "react-router-dom";
import axios from "axios";

function ActionDetails(props) {
  const [isOpenSaveAction, setIsOpenSaveAction] = useState(false);
  const [actionName, setActionName] = useState("");
  const [actionDescription, setActionDescription] = useState("");
  const { width, height } = useWindowDimensions();
  const history = useHistory();
  const toggleSaveAction = () => setIsOpenSaveAction(!isOpenSaveAction);

  return (
    <Col
      xxl={width > 1700 ? "2" : "3"}
      xl="3"
      lg="3"
      md="6"
      sm="12"
      style={{
        padding: 10,
        height: getHeightWorkspace(
          props.pages,
          height,
          props.layout.subnavbar_height_empty_ctp,
          props.layout.subnavbar_height_not_empty_ctp
        ),
      }}
    >
      <div
        style={{
          ...props.layout.generic_card_ctp,
          display: "flex",
          flexFlow: "column",
          height: "100%",
        }}
      >
        {props.isLoading === true ? (
          <div style={{ padding: 20 }}>
            <FaSpinner className="spinner"></FaSpinner>
          </div>
        ) : (
          <>
            <TitleCard title="Action details"></TitleCard>
            <div
              style={{
                padding: "10px 15px",
                flex: "1 1 auto",
                borderRadius: props.layout.generic_card_ctp.borderRadius,
                height: "auto",
                width: "100%",
                overflow: "auto",
              }}
            >
              <div style={{ height: "90%", overflow: "auto" }}>
                <div
                  style={{
                    width: "100%",
                    fontFamily: "Montserrat",
                    fontWeight: 500,
                    fontSize: 14,
                  }}
                >
                  {props.actionDetails.map((sub_action, index) => {
                    return (
                      <div
                        key={sub_action.id}
                        style={{
                          marginTop: index === 0 ? 3 : 20,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {sub_action.subActionName}
                        <div>
                          {/* <FaPencilAlt
                            style={{
                              cursor: "pointer",
                              marginRight: "5px",
                            }}
                          ></FaPencilAlt> */}
                          <FaTimes
                            onClick={() =>
                              props.setActionDetails(
                                props.actionDetails.filter(
                                  (obj) => obj.id !== sub_action.id
                                )
                              )
                            }
                            style={{
                              cursor: "pointer",
                              color: "red",
                              fontSize: 18,
                              marginLeft: 5,
                              marginRight: 2,
                            }}
                          ></FaTimes>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                style={{
                  height: "10%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  style={{
                    width: 150,
                    borderRadius: 15,
                    backgroundColor: props.layout.color_sidebar_ctp,
                    borderColor: "transparent",
                    cursor: "pointer",
                    fontWeight: 500,
                    fontFamily: "Montserrat",
                    marginTop: 15,
                  }}
                  onClick={() => {
                    if (props.actionDetails.length > 0) {
                      toggleSaveAction();
                    } else {
                      InfoAlert("No actions detected");
                    }
                  }}
                >
                  SAVE ACTION
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
      <Modal isOpen={isOpenSaveAction} toggle={toggleSaveAction} size="lg">
        <ModalHeader toggle={toggleSaveAction}>Save action</ModalHeader>
        <ModalBody>
          <Row style={{ margin: 0 }}>
            <Col lg="4" md="6" sm="12" style={{ height: "100%" }}>
              <div
                style={{
                  ...props.layout.generic_card_ctp,
                }}
              >
                <TitleCard
                  title="Assign a name"
                  customBorderHeight={2}
                  customTitleSize={{ large: 19, small: 17 }}
                ></TitleCard>
                <div style={{ padding: "15px 15px 25px 15px" }}>
                  <TextField
                    variant="standard"
                    value={actionName}
                    inputProps={{
                      style: {
                        fontWeight: 500,
                        padding: 0,
                        fontFamily: "Montserrat",
                      },
                    }}
                    style={{ width: "100%" }}
                    onChange={(e) => setActionName(e.target.value)}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row style={{ margin: 0 }}>
            <Col xs="12" style={{ height: "100%" }}>
              <div
                style={{
                  ...props.layout.generic_card_ctp,
                  marginTop: 20,
                }}
              >
                <TitleCard
                  title="Description"
                  customBorderHeight={2}
                  customTitleSize={{ large: 19, small: 17 }}
                ></TitleCard>
                <div style={{ padding: "15px 15px 25px 15px" }}>
                  <TextField
                    variant="outlined"
                    placeholder="..."
                    multiline
                    rows={6}
                    value={actionDescription}
                    inputProps={{
                      style: {
                        fontWeight: 500,
                        padding: 0,
                        fontFamily: "Montserrat",
                      },
                    }}
                    style={{ width: "100%" }}
                    onChange={(e) => setActionDescription(e.target.value)}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            onClick={async () => {
              await axios.post(
                `${API_URL}/actions/`,
                {
                  name: actionName,
                  description: actionDescription,
                  sub_actions: props.actionDetails,
                },
                tokenConfig()
              );
              toggleSaveAction();
              history.push(`/ctp/dashboard/create_policy`);
              SuccessAlert("Action created successfully");
            }}
          >
            Save
          </Button>
          <Button color="secondary" onClick={() => toggleSaveAction()}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Col>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(ActionDetails);
