import React, { useEffect, useState } from "react";

export default function CheckboxHeader(props) {
  const [headerChecked, setHeaderChecked] = useState(false);

  useEffect(() => {
    let checked = true
    props.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
      if(props.selectedFeatures[rowNode.data.identifier] !== true){
        checked = false
        return
      }
    });
    setHeaderChecked(checked)
  }, [props.filterChanged, props.api]);

  const checkedHandler = (e) => {
    if (e.target.checked === true) {
      var selectedFeatures = [];
      props.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
        selectedFeatures[rowNode.data.identifier] = true;
      });
      props.setSelectedFeautures(selectedFeatures)
    }else{
      props.setSelectedFeautures({})
    }
    setHeaderChecked(e.target.checked)
  };

  return (
    <input
      type="checkbox"
      onClick={checkedHandler}
      checked={headerChecked}
      readOnly
      style={{
        accentColor: "seagreen",
        backgroundColor: "white",
        marginLeft: 2,
      }}
    />
  );
}
