import React, { useRef, useEffect, useState, useMemo } from "react";
import * as Cesium from "cesium";
import "cesium/Build/Cesium/Widgets/widgets.css";
import { API_URL } from "../../../actions/types";
import TIFFImageryProvider from 'tiff-imagery-provider';
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TextField } from "@mui/material";
import moment from "moment";
import { FormGroup, Input, Label, } from 'reactstrap'
import Plotly from "plotly.js-dist";
import axios from 'axios'
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import gearth from "./gearth.png"

export default function Pollutants(params) {
    const container = useRef<HTMLDivElement>(null);
    const viewer = useRef<Cesium.Viewer>();
    const buildings = useRef<Cesium.Cesium3DTileset>();
    const raster = useRef<Cesium.Cesium3DTileset>();
    const [startDate, setStartDate] = useState(moment("2023-01-01"))
    const [endDate, setEndDate] = useState(moment("2023-12-31").endOf('day'))
    const [pollutant, setPollutant] = useState("co")
    const [modal, setModal] = useState(false)
    const mapHandler = useRef<Cesium.ScreenSpaceEventHandler>()
    const google = useRef<Cesium.Cesium3DTileset>();


    const toggle = () => {
        setModal(!modal)
    }

    const addRaster = () => {

        const startDateFormatted = startDate.format("YYYY-MM-DD HH:mm:ss")
        const endDateFormatted = endDate.format("YYYY-MM-DD HH:mm:ss")
        fetch(`${API_URL}/data/pollutants_raster/?start_date=${startDateFormatted}&end_date=${endDateFormatted}&pollutant=${pollutant}`)
            .then(resp => resp.blob())
            .then(async blob => {
                raster.current = await TIFFImageryProvider.fromUrl(blob, {
                    enablePickFeatures: true,
                    hasAlphaChannel: false,
                    renderOptions: {
                        convertToRGB: true
                    }
                });
                var imageryLayer = viewer.current?.imageryLayers.addImageryProvider(raster.current);
                imageryLayer.raiseToTop = true;
                imageryLayer.bringToFront = true;
                imageryLayer.show = true;
                const addedLayer = viewer.current?.imageryLayers.get(viewer.current?.imageryLayers.length - 1)
                addedLayer!.alpha = 0.5
                if (viewer.current?.imageryLayers.length > 2) {
                    setTimeout(() => {
                        viewer.current?.imageryLayers.remove(viewer.current?.imageryLayers._layers[1])
                    }, 250);
                }

            })

    };
    const addNuildings = async () => {
        buildings.current = await Cesium.Cesium3DTileset.fromUrl(`${API_URL.replace("api", "")}tilesets/torino/tileset.json`, {
            maximumScreenSpaceError: 1,
            lightColor: new Cesium.Cartesian3(10, 10, 10),
        });
        viewer.current?.scene.primitives.add(buildings.current);
    };

    const OSMImagery = () => {
        viewer.current?.imageryLayers.removeAll();
        const osmImagery = new Cesium.OpenStreetMapImageryProvider({
            url: "https://a.tile.openstreetmap.org/",
        });
        viewer.current?.imageryLayers.addImageryProvider(osmImagery);
    };

    const addClickHandler = () => {
        viewer.current?.entities.removeAll();
        mapHandler.current?.destroy();
        mapHandler.current = new Cesium.ScreenSpaceEventHandler(viewer.current?.canvas);
        mapHandler.current?.setInputAction(movement => {
            const cartesian = viewer.current!.scene.pickPosition(movement.position);
            const cartographic = Cesium.Cartographic.fromCartesian(cartesian);
            const longitude = Cesium.Math.toDegrees(cartographic.longitude)
            const latitude = Cesium.Math.toDegrees(cartographic.latitude)

            axios.post(`${API_URL}/data/building_details/`, { longitude, latitude }).then(response => {
                viewer.current?.entities.removeAll()
                const data = response.data
                if (data === undefined || data === null || data === '') return
                let description = '<table class="cesium-infoBox-defaultTable"><tbody>';
                Object.keys(data)
                    .filter(fieldName => fieldName !== 'footprint')
                    .forEach(fieldName => {
                        description += `<tr><th>${fieldName}</th><td>`;
                        description += `${data[fieldName]}</td></tr>`;
                    })
                description += `</tbody></table>`;

                const targetHighlight = new Cesium.Entity({
                    description,
                    name: 'Building description',
                    polygon: {
                        hierarchy: Cesium.Cartesian3.fromDegreesArray(
                            data.footprint.flat(2)
                        ),
                        material: Cesium.Color.YELLOW.withAlpha(0.4),
                        classificationType: Cesium.ClassificationType.BOTH,
                    },
                });


                viewer.current!.entities.add(targetHighlight);
                viewer.current!.selectedEntity = targetHighlight
            })
        }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    }

    useEffect(() => {
        if (!container.current || viewer.current) return;

        viewer.current = new Cesium.Viewer(container.current, {
            timeline: false,
            animation: false,
            baseLayerPicker: false,
            homeButton: false,
            navigationHelpButton: false,
            infoBox: false,
            scene3DOnly: true,
            geocoder: false,
            shadows: false,
            selectionIndicator: false,
        });

        viewer.current.camera.setView({
            destination: Cesium.Cartesian3.fromDegrees(7.78, 45.02, 10000),
            orientation: {
                heading: Cesium.Math.toRadians(-60.0),
                pitch: Cesium.Math.toRadians(-45.0),
            },
        });
        viewer.current.screenSpaceEventHandler.removeInputAction(
            Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK
        );
        viewer.current.scene.globe.depthTestAgainstTerrain = true;



        addNuildings()
        OSMImagery();
        generateGoogleInstance()
        addClickHandler()
    }, []);

    useEffect(() => {
        addRaster();
        viewer.current?.screenSpaceEventHandler.removeInputAction(
            Cesium.ScreenSpaceEventType.LEFT_CLICK
        );
        viewer.current?.screenSpaceEventHandler.setInputAction(event => {
            const cartesian = viewer.current!.scene.pickPosition(event.position);
            const cartographic = Cesium.Cartographic.fromCartesian(cartesian);
            const lon = Cesium.Math.toDegrees(cartographic.longitude)
            const lat = Cesium.Math.toDegrees(cartographic.latitude)
            const startDateFormatted = startDate.format("YYYY-MM-DD HH:mm:ss")
            const endDateFormatted = endDate.format("YYYY-MM-DD HH:mm:ss")
            axios.get(`${API_URL}/data/pollutants/?start_date=${startDateFormatted}&end_date=${endDateFormatted}&pollutant=${pollutant}&lon=${lon}&lat=${lat}`)
                .then(response => {
                    toggle()
                    var dataset = {
                        type: "scatter",
                        mode: "lines",
                        name: pollutant,
                        x: response.data.map(d => d.datetime),
                        y: response.data.map(d => d.value),
                        line: { color: '#17BECF' }
                    }


                    var data = [dataset];

                    var layout = {
                        title: `${pollutant.toUpperCase()} Time Series`
                    };

                    Plotly.newPlot('chart', data, layout);
                })

        }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

    }, [startDate, endDate, pollutant])

    const generateGoogleInstance = () => {
        Cesium.Cesium3DTileset.fromIonAssetId(2275207, {
            shadows: Cesium.ShadowMode.DISABLED,
            skipLevelOfDetail: true,
            baseScreenSpaceError: 10240,
            skipScreenSpaceErrorFactor: 16,
            skipLevels: 1,
            immediatelyLoadDesiredLevelOfDetail: false,
            loadSiblings: false,
            cullWithChildrenBounds: true
        }).then(g => {
            google.current = g;
        })
    }

    function toggleGoogle() {
        if (viewer.current?.scene.primitives.contains(google.current!)) {
            viewer.current?.scene.primitives.remove(google.current!)
            generateGoogleInstance()
        } else {
            viewer.current?.scene.primitives.add(google.current!)
        }
    }

    return (
        <>
            <Modal isOpen={modal} toggle={toggle} size="xl">
                <ModalBody>
                    <div id="chart" style={{ height: '50vh' }}></div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>

            <div
                ref={container}
                style={{ width: "100%", height: "100%", position: "relative" }}
            >
                <div style={{ position: "absolute", backgroundColor: "white", zIndex: 99, top: 10, left: 10, padding: 10, borderRadius: 10 }}>
                    <DesktopDatePicker
                        label="Start date"
                        inputFormat="DD-MM-YYYY"
                        value={startDate}
                        minDate={moment("2023-01-01")}
                        maxDate={endDate}
                        onChange={(v) => setStartDate(v)}
                        views={['month', 'day']}
                        renderInput={(params) => <TextField {...params} sx={{ width: 150 }} />}
                    />

                    <DesktopDatePicker
                        label="End date"
                        inputFormat="DD-MM-YYYY"
                        value={endDate}
                        minDate={startDate}
                        maxDate={moment("2023-12-31")}
                        onChange={(v) => setEndDate(v.endOf('day'))}
                        views={['month', 'day']}
                        renderInput={(params) => <TextField {...params} sx={{ width: 150, marginLeft: 2 }} />}
                    />
                </div>
                <div style={{ position: "absolute", backgroundColor: "white", zIndex: 99, top: 100, left: 10, padding: 10, borderRadius: 10, fontSize: 14 }}>
                    <FormGroup check onClick={() => setPollutant("co")}>
                        <Input type="checkbox" checked={pollutant === "co"} />
                        <Label check>
                            CO
                        </Label>
                    </FormGroup>
                    <FormGroup check onClick={() => setPollutant("no")}>
                        <Input type="checkbox" checked={pollutant === "no"} />
                        <Label check>
                            NO
                        </Label>
                    </FormGroup>
                    <FormGroup check onClick={() => setPollutant("no2")}>
                        <Input type="checkbox" checked={pollutant === "no2"} />
                        <Label check>
                            NO2
                        </Label>
                    </FormGroup>
                    <FormGroup check onClick={() => setPollutant("pm10")}>
                        <Input type="checkbox" checked={pollutant === "pm10"} />
                        <Label check>
                            PM10
                        </Label>
                    </FormGroup>
                    <FormGroup check onClick={() => setPollutant("so2")}>
                        <Input type="checkbox" checked={pollutant === "so2"} />
                        <Label check>
                            SO2
                        </Label>
                    </FormGroup>
                </div>
            </div>
        </>
    );
}
