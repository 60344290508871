import React, { useState, useLayoutEffect } from "react";
import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { connect } from "react-redux";
import AppBarDisplayLong from "./AppBarDisplayLong";
import clsx from "clsx";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  text: {
    padding: 10,
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: 10,
  },
  appBar: {
    top: "auto",
    bottom: 0,
    height: "0px",
    transition: "1s",
  },
  appBarUpDown: {
    height: "64px",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
  },
  grow: {
    flexGrow: 1,
  },

  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    backgroundImage: "linear-gradient(to bottom left, #808080, #808080	)",
  },
}));

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

export const BottomInfoBar = ({ open, info_bottom }) => {
  const classes = useStyles();
  const [width] = useWindowSize();

  return (
    <React.Fragment>
      {info_bottom === true ? (
        <AppBar
          position="fixed"
          className={clsx(
            classes.appBar,
            open && classes.appBarShift,
            info_bottom && classes.appBarUpDown
          )}
        >
          <Toolbar className={classes.toolbar}>
            <div className={classes.grow} />
            <AppBarDisplayLong></AppBarDisplayLong>
          </Toolbar>
        </AppBar>
      ) : null}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  open: state.handleOpenClose.open,
  info_bottom: state.handleOpenClose.info_bottom,
});

export default connect(mapStateToProps)(BottomInfoBar);
