import React, { useRef, useEffect, useState } from "react";
import axios from 'axios'
import * as Cesium from 'cesium';
import "cesium/Build/Cesium/Widgets/widgets.css";
import { Polygon } from 'ol/geom';
import { circular } from 'ol/geom/Polygon'
import WKT from 'ol/format/WKT.js';
import Feature from 'ol/Feature.js';
import circle from "./circle.png"
import polygon from "./polygon.png"
import { API_URL } from "../../../../../actions/types";

export default function Map3D({ selectedFeaturesOBJ, setSelectedFeaturesOBJ, drawActive = true }) {
  const [polygonDrawActive, setPolygonDrawActive] = useState<boolean>(false)
  const [circleDrawActive, setCircleDrawActive] = useState<boolean>(false)

  const container = useRef<HTMLDivElement>(null)
  const viewer = useRef<Cesium.Viewer>()
  const mapHandler = useRef<Cesium.ScreenSpaceEventHandler>()
  const tileset = useRef<Cesium.Cesium3DTileset>()
  const features = useRef({})

  const addTorinoTileset = async () => {
    tileset.current = new Cesium.Cesium3DTileset({
      url: `${API_URL.replace("api", "")}tilesets/torino/tileset.json`,
      maximumScreenSpaceError: 1,
      lightColor: new Cesium.Cartesian3(10, 10, 10),
    })
    tileset.current.style = new Cesium.Cesium3DTileStyle({
      color: {
        conditions: [
          // eslint-disable-next-line no-template-curly-in-string
          ["${selected} === true", 'color("aqua")'],
          // eslint-disable-next-line no-template-curly-in-string
          ['${feature["Tipologia edificio"]} === "Residenziale"', 'color("#698269")'],
          // eslint-disable-next-line no-template-curly-in-string
          ['${feature["Tipologia edificio"]} === "Servizi"', 'color("#B99B6B")'],
          // eslint-disable-next-line no-template-curly-in-string
          ['${feature["Tipologia edificio"]} === "Produzione"', 'color("#AA5656")'],
        ],
      },
    });

    viewer.current?.scene.primitives.add(tileset.current)
    tileset.current!.tileLoad.addEventListener((tile) => {
      const content = tile.content;
      const featuresLength = content.featuresLength;
      for (let i = 0; i < featuresLength; i++) {
        const feature = content.getFeature(i);
        const feature_id = feature.getProperty("Identifier");
        features.current[feature_id] = feature
        features.current[feature_id].setProperty("selected", selectedFeaturesOBJ[feature_id] ?? false);
      }
    });
  }

  const addClickHandler = () => {
    viewer.current?.entities.removeAll()
    mapHandler.current?.destroy()
    mapHandler.current = new Cesium.ScreenSpaceEventHandler(viewer.current?.canvas);
    mapHandler.current.setInputAction(click => {
      const selectedBuilding = viewer.current?.scene.pick(click.position);
      if (Cesium.defined(selectedBuilding)) {
        const identifier = selectedBuilding.getProperty("Identifier");
        setSelectedFeaturesOBJ((oldState) => ({ [identifier]: true }));
      }
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    mapHandler.current.setInputAction(click => {
      const selectedBuilding = viewer.current?.scene.pick(click.position);

      if (Cesium.defined(selectedBuilding)) {
        const identifier = selectedBuilding.getProperty("Identifier");
        setSelectedFeaturesOBJ((old) => ({
          ...old,
          [identifier]: !(old[identifier] ?? false),
        }));
      }
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK, Cesium.KeyboardEventModifier.CTRL);
  }

  useEffect(() => {
    if (!tileset.current || !selectedFeaturesOBJ) return
    tileset.current!.tileLoad._listeners = [];
    tileset.current!.tileLoad._scopes = [];
    tileset.current!.tileLoad.addEventListener((tile) => {
      const content = tile.content;
      const featuresLength = content.featuresLength;
      for (let i = 0; i < featuresLength; i++) {
        const feature = content.getFeature(i);
        const feature_id = feature.getProperty("Identifier");
        features.current[feature_id] = feature
        features.current[feature_id].setProperty("selected", selectedFeaturesOBJ[feature_id] ?? false);
      }
    });
    Object.entries(features.current).forEach(([id, feature]) => {
      feature.setProperty("selected", selectedFeaturesOBJ[id] ?? false)
    });
  }, [selectedFeaturesOBJ])

  const OSMImagery = () => {
    viewer.current?.imageryLayers.removeAll()
    const osmImagery = new Cesium.OpenStreetMapImageryProvider({
      url: 'https://a.tile.openstreetmap.org/'
    });
    viewer.current?.imageryLayers.addImageryProvider(osmImagery)
  }

  const pointsAreClose = (point1: Cesium.Cartesian3, point2: Cesium.Cartesian3) => {
    const cameraHeight = Cesium.Cartographic.fromCartesian(viewer.current?.camera.positionWC!).height
    const distanceToInitialPosition = Cesium.Cartesian3.distance(point1, point2)
    if (100 * distanceToInitialPosition / cameraHeight < 4) {
      return true
    } else {
      return false
    }
  }

  const geoserverSelectedFeatures = (polygon: Polygon) => {
    const p = new Feature(polygon)
    const geometryWKT = new WKT().writeFeature(p)
    axios.get("https://geoserver.est.polito.it/geoserver/citta/ows", {
      params: {
        "service": "WFS",
        "version": "1.0.0",
        "request": "GetFeature",
        "typeName": "citta:buildings",
        "outputFormat": "text/csv",
        "propertyName": "identifier",
        "cql_filter": `intersects(geometry, ${geometryWKT})`
      }
    }).then(response => {
      const data = response.data.split("\r\n").slice(1).filter(d => d !== "")
      const featuresIDs = data
        .map(d => d.split(",")[1])
        .reduce((obj, id) => {
          obj[id] = true;
          return obj
        }, {})
      setSelectedFeaturesOBJ(featuresIDs)
    })
  }

  useEffect(() => {
    if (polygonDrawActive && viewer.current && selectedFeaturesOBJ) {
      viewer.current?.entities.removeAll()
      mapHandler.current?.destroy()
      mapHandler.current = new Cesium.ScreenSpaceEventHandler(viewer.current!.canvas);

      var points: Cesium.Cartesian3[] = [];
      let lastPointadded: Cesium.Cartesian3;
      let mousePosition = viewer.current?.entities.add({
        position: undefined,
        id: 'mousePosition',
        point: {
          pixelSize: 10,
          color: Cesium.Color.fromCssColorString("#0099ff"),
          heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
        },
      });

      mapHandler.current?.setInputAction((event) => {
        const ray = viewer.current!.camera.getPickRay(event.position);
        if (ray !== undefined) {
          var position = viewer.current!.scene.globe.pick(ray, viewer.current!.scene);
          if (Cesium.defined(position)) {
            switch (true) {
              case points.length === 0:
                points.push(position!)
                viewer.current!.entities.add({
                  id: "drawnShape",
                  polyline: {
                    positions: new Cesium.CallbackProperty(() => points, false),
                    clampToGround: true,
                    material: Cesium.Color.fromCssColorString("#0099ff"),
                    width: 5,
                  },
                  polygon: {
                    hierarchy: new Cesium.CallbackProperty(() => new Cesium.PolygonHierarchy(points), false),
                    material: Cesium.Color.WHITE.withAlpha(0.3),
                  },
                });
                lastPointadded = position!
                break;

              case pointsAreClose(points[0], position!):
                if (points.at(-1) !== lastPointadded) points.pop()
                position = points[0].clone()
                points.push(position)
                setPolygonDrawActive(false)
                const coordinates = points.map(point => {
                  const cartographic = Cesium.Cartographic.fromCartesian(point);
                  return [Cesium.Math.toDegrees(cartographic.longitude), Cesium.Math.toDegrees(cartographic.latitude)]
                })
                geoserverSelectedFeatures(new Polygon([coordinates]))
                break

              default:
                if (points.at(-1) !== lastPointadded) points.pop()
                points.push(position!)
                lastPointadded = position!
                break;
            }
          }
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK)

      mapHandler.current?.setInputAction((event) => {
        const ray = viewer.current?.camera.getPickRay(event.endPosition);
        if (ray !== undefined) {
          var position = viewer.current!.scene.globe.pick(ray, viewer.current!.scene);
          if (Cesium.defined(position) && points.length > 0) {
            if (points.at(-1) !== lastPointadded) points.pop()
            if (pointsAreClose(points[0], position!)) {
              position = points[0].clone()
            }
            points.push(position!)
          }
          mousePosition.position = new Cesium.ConstantPositionProperty(position!)
        }
      }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

    } else if (circleDrawActive === false && polygonDrawActive === false) {
      addClickHandler()
      viewer.current?.entities.removeById("mousePosition")
    }
  }, [polygonDrawActive])



  useEffect(() => {
    if (circleDrawActive && viewer.current && selectedFeaturesOBJ) {
      viewer.current?.entities.removeAll()
      mapHandler.current?.destroy()
      mapHandler.current = new Cesium.ScreenSpaceEventHandler(viewer.current!.canvas);

      let center: Cesium.Cartographic;
      let circle: Polygon;
      let mousePosition = viewer.current?.entities.add({
        position: undefined,
        id: 'mousePosition',
        point: {
          pixelSize: 10,
          color: Cesium.Color.fromCssColorString("#0099ff"),
          heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
        },
      });

      mapHandler.current?.setInputAction((event) => {
        const ray = viewer.current!.camera.getPickRay(event.position);
        if (ray !== undefined) {
          var position = viewer.current!.scene.globe.pick(ray, viewer.current!.scene);
          if (Cesium.defined(position)) {
            switch (true) {
              case center === undefined:
                center = Cesium.Cartographic.fromCartesian(position!.clone())
                circle = circular([
                  Cesium.Math.toDegrees(center.longitude),
                  Cesium.Math.toDegrees(center.latitude),
                ], 1, 64)

                viewer.current!.entities.add({
                  id: "drawnShape",
                  polyline: {
                    positions: new Cesium.CallbackProperty(() => {
                      return circle.getCoordinates()[0].map(coordinate => Cesium.Cartographic.toCartesian(Cesium.Cartographic.fromDegrees(coordinate[0], coordinate[1])))
                    }, false),
                    clampToGround: true,
                    material: Cesium.Color.fromCssColorString("#0099ff"),
                    width: 5,
                  },
                  polygon: {
                    hierarchy: new Cesium.CallbackProperty(() => new Cesium.PolygonHierarchy(circle.getCoordinates()[0].map(coordinate => Cesium.Cartographic.toCartesian(Cesium.Cartographic.fromDegrees(coordinate[0], coordinate[1])))), false),
                    material: Cesium.Color.WHITE.withAlpha(0.3),
                  },
                });
                break
              default:
                setCircleDrawActive(false)
                geoserverSelectedFeatures(circle)
                break;
            }
          }
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK)

      mapHandler.current?.setInputAction((event) => {
        const ray = viewer.current?.camera.getPickRay(event.endPosition);
        if (ray !== undefined) {
          var position = viewer.current!.scene.globe.pick(ray, viewer.current!.scene);
          if (Cesium.defined(position) && center !== undefined) {
            const radius = Cesium.Cartesian3.distance(Cesium.Cartographic.toCartesian(center), position!)
            circle = circular([
              Cesium.Math.toDegrees(center.longitude),
              Cesium.Math.toDegrees(center.latitude),
            ], radius, 64)
          }
          mousePosition.position = new Cesium.ConstantPositionProperty(position!)
        }
      }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

    } else if (circleDrawActive === false && polygonDrawActive === false) {
      addClickHandler()
      viewer.current?.entities.removeById("mousePosition")
    }
  }, [circleDrawActive])

  useEffect(() => {
    if (!container.current || viewer.current || !selectedFeaturesOBJ) return;

    viewer.current = new Cesium.Viewer(container.current, {
      timeline: false,
      animation: false,
      baseLayerPicker: false,
      homeButton: false,
      navigationHelpButton: false,
      infoBox: false,
      scene3DOnly: true,
      geocoder: false,
      shadows: false,
      selectionIndicator: false,
      fullscreenButton: false
    });

    viewer.current.camera.setView({
      destination: Cesium.Cartesian3.fromDegrees(7.725, 45, 5000),
      orientation: {
        heading: Cesium.Math.toRadians(-30.0),
        pitch: Cesium.Math.toRadians(-30.0),
      }
    });
    viewer.current.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
    addTorinoTileset()
    OSMImagery()
    addClickHandler()
  }, [])

  return (
    <div ref={container} style={{ width: '100%', height: '100%', position: 'relative' }}>
      {drawActive === true ?
        (
          <div style={{ position: 'absolute', top: '0.5em', left: '0.5em', backdropFilter: 'rgba(0, 0, 0, 0)', pointerEvents: 'auto', zIndex: 1 }}>
            <div
              style={{ backgroundColor: polygonDrawActive ? 'aqua' : 'white', cursor: 'pointer', width: '100%', height: '100%', border: '1px solid gray', borderRadius: 5, padding: 2.5 }}
              onClick={() => { setPolygonDrawActive(!polygonDrawActive); setCircleDrawActive(false); }}
            >
              <img src={polygon} style={{ width: 25, height: 25 }} alt="polygon" />
            </div>
            <div
              style={{ backgroundColor: circleDrawActive ? 'aqua' : 'white', cursor: 'pointer', width: '100%', height: '100%', border: '1px solid gray', borderRadius: 5, padding: 2.5 }}
              onClick={() => { setCircleDrawActive(!circleDrawActive); setPolygonDrawActive(false); }}
            >
              <img src={circle} style={{ width: 25, height: 25 }} alt="circle" />
            </div>
          </div>
        )
        : ""}

    </div>
  );
}
