import React, { useState } from "react";
import { Tooltip } from "@mui/material";
import { Row, Col } from "reactstrap";
import { connect, useDispatch } from "react-redux";
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";
import TitleCard from "../../../Homepage/components/TitleCard";
import ModalDomains from "./ModalDomains";
import ModalResidentialHeating from "./ModalResidentialHeating";
import ModalPublicTransport from "./ModalPublicTransport";
import ModalEnergyCommunity from "./ModalEnergyCommunity";
import { FaPlus, FaPencilAlt, FaSpinner } from "react-icons/fa";
import { getIconMapped } from "../../../../utils/getIconMapped";
import { Button } from "reactstrap";
import { getHeightWorkspace } from "../../../../utils/getHeightWorkspace";
import { API_URL, IS_LOADING } from "../../../../actions/types";
import { tokenConfig } from "../../../../utils/tokenConfig";
import axios from "axios";

function ActionDomains(props) {
  const { width, height } = useWindowDimensions();
  const [isOpen, setIsOpen] = useState(false);
  const [domainSelected, setDomainSelected] = useState("");
  const [openResidentialHeating, setOpenResidentialHeating] = useState(false);
  const [openPublicTransport, setOpenPublicTransport] = useState(false);
  const [openEnergyCommunity, setOpenEnergyCommunity] = useState(false);
  const [idInputCategory, setIdInputCategory] = useState("");
  const [idInputAction, setIdInputAction] = useState("");
  const [subActionName, setSubActionName] = useState("");

  const dispatch = useDispatch();

  return (
    <Col
      xxl={width > 1700 ? "2" : "3"}
      xl="3"
      lg="3"
      md="6"
      sm="12"
      style={{
        padding: 10,
        height: getHeightWorkspace(
          props.pages,
          height,
          props.layout.subnavbar_height_empty_ctp,
          props.layout.subnavbar_height_not_empty_ctp
        ),
      }}
    >
      <div
        style={{
          ...props.layout.generic_card_ctp,
          display: "flex",
          flexFlow: "column",
          height: "100%",
        }}
      >
        {props.isLoading === true ? (
          <div style={{ padding: 20 }}>
            <FaSpinner className="spinner"></FaSpinner>
          </div>
        ) : (
          <>
            <TitleCard
              title="Action domains"
              icon={
                <Tooltip title="Select domains">
                  <div
                    style={{
                      backgroundColor: props.layout.color_sidebar_ctp,
                      padding: "2px 8px 5.5px 8px",
                      borderRadius: "50%",
                      marginTop: 10,
                      cursor: "pointer",
                    }}
                    onClick={() => setIsOpen(true)}
                  >
                    <FaPlus
                      style={{
                        color: "white",
                        fontSize: 15,
                      }}
                    ></FaPlus>
                  </div>
                </Tooltip>
              }
            ></TitleCard>
            <div
              style={{
                padding: "10px 15px",
                flex: "1 1 auto",
                borderRadius: props.layout.generic_card_ctp.borderRadius,
                height: "auto",
                width: "100%",
                overflow: "auto",
              }}
            >
              <div style={{ height: "90%", overflow: "auto" }}>
                <div
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "bold",
                    color: props.layout.color_footer_ctp,
                    fontSize: width > 1450 ? 16 : 14,
                  }}
                >
                  {domainSelected === ""
                    ? "Select a domain..."
                    : props.domainParams.find(
                        (obj) => obj.id === domainSelected
                      ).name}
                </div>
                <div
                  style={{
                    display: "flex",
                    marginTop: 15,
                  }}
                >
                  <div
                    style={{
                      width: width > 1600 ? 60 : 50,
                      padding: 5,
                    }}
                  >
                    <div>
                      {props.domainParams
                        .filter((obj) => obj.active === true)
                        .map((domain, index) => {
                          return (
                            <Tooltip title={domain.name} key={domain.id}>
                              <div
                                onClick={() => {
                                  setDomainSelected(domain.id);
                                }}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "100%",
                                  backgroundColor:
                                    domainSelected === domain.id
                                      ? "#FFAF1D"
                                      : "#FFDB99",
                                  color:
                                    domainSelected === domain.id
                                      ? "black"
                                      : "#AEAEAE",
                                  cursor:
                                    domainSelected === domain.id
                                      ? "pointer"
                                      : "default",
                                  aspectRatio: "1 / 1",
                                  borderRadius: 10,
                                  cursor: "pointer",
                                  marginTop: index === 0 ? 0 : 20,
                                }}
                              >
                                {getIconMapped(domain.icon, {
                                  width: "100%",
                                })}
                              </div>
                            </Tooltip>
                          );
                        })}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      padding: "0px 15px",
                      fontFamily: "Montserrat",
                      fontWeight: 500,
                      fontSize: 14,
                    }}
                  >
                    {domainSelected === ""
                      ? null
                      : props.domainParams
                          .find((obj) => obj.id === domainSelected)
                          .params.map((sub_action, index) => {
                            return (
                              <div
                                style={{
                                  marginTop: index === 0 ? 3 : 20,
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                {sub_action.name}
                                <FaPencilAlt
                                  onClick={() => {
                                    if (
                                      sub_action.id_input_category ===
                                      "residHeating"
                                    ) {
                                      setOpenResidentialHeating(true);
                                      setIdInputCategory("residHeating");
                                      setIdInputAction(sub_action.id);
                                      setSubActionName(sub_action.name);
                                    } else if (
                                      sub_action.id_input_category ===
                                      "publTransport"
                                    ) {
                                      setOpenPublicTransport(true);
                                      setIdInputCategory("publTransport");
                                      setIdInputAction(sub_action.id);
                                      setSubActionName(sub_action.name);
                                    } else if (
                                      sub_action.id_input_category ===
                                      "enCommunity"
                                    ) {
                                      setOpenEnergyCommunity(true);
                                      setIdInputCategory("enCommunity");
                                      setIdInputAction(sub_action.id);
                                      setSubActionName(sub_action.name);
                                    }
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    marginRight: "-8px",
                                  }}
                                ></FaPencilAlt>
                              </div>
                            );
                          })}
                  </div>
                </div>
              </div>
              <div
                style={{
                  height: "10%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  style={{
                    width: 150,
                    borderRadius: 15,
                    backgroundColor: props.layout.color_sidebar_ctp,
                    borderColor: "transparent",
                    cursor: "pointer",
                    fontWeight: 500,
                    fontFamily: "Montserrat",
                    marginTop: 15,
                  }}
                  onClick={async () => {
                    dispatch({
                      type: IS_LOADING,
                    });
                    const res = await axios.post(
                      `${API_URL}/scenarios/compute/`,
                      props.actionDetails,
                      tokenConfig()
                    );
                    props.setActionImpact(res.data);
                    dispatch({
                      type: IS_LOADING,
                    });
                  }}
                >
                  RUN
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
      <ModalDomains
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        domainParams={props.domainParams}
        setDomainParams={props.setDomainParams}
        domainSelected={domainSelected}
        setDomainSelected={setDomainSelected}
      ></ModalDomains>
      {openResidentialHeating === true ? (
        <ModalResidentialHeating
          read_only={false}
          isOpen={openResidentialHeating}
          setIsOpen={setOpenResidentialHeating}
          idInputCategory={idInputCategory}
          idInputAction={idInputAction}
          subActionName={subActionName}
          type="insert"
          actionDetails={props.actionDetails}
          setActionDetails={props.setActionDetails}
        ></ModalResidentialHeating>
      ) : null}
      {openPublicTransport === true ? (
        <ModalPublicTransport
          read_only={false}
          isOpen={openPublicTransport}
          setIsOpen={setOpenPublicTransport}
          idInputCategory={idInputCategory}
          idInputAction={idInputAction}
          subActionName={subActionName}
          type="insert"
          actionDetails={props.actionDetails}
          setActionDetails={props.setActionDetails}
        ></ModalPublicTransport>
      ) : null}
      {openEnergyCommunity === true ? (
        <ModalEnergyCommunity
          read_only={false}
          isOpen={openEnergyCommunity}
          setIsOpen={setOpenEnergyCommunity}
          idInputCategory={idInputCategory}
          idInputAction={idInputAction}
          subActionName={subActionName}
          type="insert"
          actionDetails={props.actionDetails}
          setActionDetails={props.setActionDetails}
        ></ModalEnergyCommunity>
      ) : null}
    </Col>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(ActionDomains);
