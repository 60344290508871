import { Control } from "ol/control.js";

export default class LegendControl extends Control {
  /**
   * @param {Object} [opt_options] Control options.
   */
  constructor(opt_options) {
    const options = opt_options || {};

    const element = document.createElement("div");
    element.className = "ol-unselectable ol-control";
    element.style.bottom = ".5em";
    element.style.left = ".5em";
    element.style.width = "100px";
    element.style.height = "52px";
    element.style.background = "white";

    const image = document.createElement("img");
    image.style.width = "100%";
    image.style.height = "100%";
    image.style.padding = "5px";
    image.src = options.url;

    element.append(image);

    super({
      element: element,
      image: image,
      target: options.target,
    });
  }

  update(url) {
    this.element.children[0].src = url;
  }
}
