import * as Cesium from "cesium";
import { Vector as VectorLayer } from "ol/layer";
import GeoJSON from "ol/format/GeoJSON.js";
import olcsAbstractSynchronizer from "olcs/AbstractSynchronizer";
import olcsFeatureConverter from "olcs/FeatureConverter";

export default class VectorSynchronizer extends olcsAbstractSynchronizer {
  constructor(map, scene, dataSources, getBuildingColor3D) {
    super(map, scene);
    this.dataSources = dataSources;
    this.getBuildingColor3D = getBuildingColor3D;
    /**
     * @protected
     */
    this.converter = new olcsFeatureConverter(scene);
    /**
     * @private
     */
    this.csAllPrimitives_ = new Cesium.PrimitiveCollection();
    scene.primitives.add(this.csAllPrimitives_);
    this.csAllPrimitives_.destroyPrimitives = false;
  }
  addCesiumObject(counterpart) {
    console.assert(counterpart);
    counterpart.getRootPrimitive()["counterpart"] = counterpart;
    this.csAllPrimitives_.add(counterpart.getRootPrimitive());
  }

  createSingleLayerCounterparts(olLayerWithParents) {
    const olLayer = olLayerWithParents.layer;
    if (
      olLayer instanceof VectorLayer === false ||
      olLayer.getSource().getFormat() instanceof GeoJSON === false
    ) {
      return null;
    }
    if (this.dataSources.length !== 0) return;

    new Cesium.GeoJsonDataSource()
      .load(olLayer.getSource().getUrl())
      .then((dataSource) => {
        dataSource.entities.values.forEach((entity) => {
          entity.polygon.extrudedHeight = entity.properties.elevation;
          entity.polygon.material = this.getBuildingColor3D(entity);
          entity.polygon.outline = true;
          entity.polygon.outlineColor =
            Cesium.Color.fromCssColorString("black");
        });
        this.dataSources.add(dataSource);
      });
  }
}
