export const getHeightWorkspace = (
  pages,
  height,
  subnavbar_height_empty_ctp,
  subnavbar_height_not_empty_ctp
) => {
  if (pages === undefined) {
    return height * 0.86 - subnavbar_height_empty_ctp;
  } else {
    return height * 0.86 - subnavbar_height_not_empty_ctp;
  }
};
