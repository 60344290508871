import React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { connect } from "react-redux";
import logoSidebar from "../../../images/logoSidebar.png";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  toolbarIcon: {
    padding: "0 5px",
  },
  drawerPaper: {
    // overflow: "hidden",
    position: "fixed",
    whiteSpace: "nowrap",
    height: "100%",
    width: drawerWidth,
    transition: "1s",
    backgroundColor: "#0A56A7",
    color: "white",
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: "1s",
    width: 65,
  },
}));

export const DrawerWrapper = ({ open, mainList, secondaryList, title }) => {
  const classes = useStyles();
  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}
    >
      {open ? (
        <div className={classes.toolbarIcon}>
          <img
            src={logoSidebar}
            alt="logo"
            style={{
              height: "auto",
              display: "block",
              // marginLeft: "5px",
              marginRight: "0",
              width: "70%",
              marginBottom: "0px",
              marginTop: "5px",
              imageRendering: "-webkit-optimize-contrast",
            }}
          />
          {/* <h6
            style={{
              fontSize: "13px",
              marginTop: "15px",
              paddingRight: "55px",
            }}
          >
            WEB APP
          </h6> */}
        </div>
      ) : (
        <h6
          style={{
            marginLeft: "12px",
            marginTop: "15px",
            marginBottom: "10px",
          }}
        >
          APP
        </h6>
      )}
      <Divider />
      {title !== undefined && open ? (
        <h5
          style={{
            paddingTop: "10px",
            paddingLeft: "18px",
            marginBottom: 0,
          }}
        >
          {title}
        </h5>
      ) : null}
      <List
        style={
          open
            ? { paddingTop: "10px" }
            : { paddingTop: "10px", marginTop: "14px" }
        }
      >
        {mainList}
      </List>
      <List
        style={{
          position: "absolute",
          bottom: "10px",
          left: "0",
          width: "100%",
        }}
      >
        {secondaryList}
      </List>
    </Drawer>
  );
};

const mapStateToProps = (state) => ({
  open: state.handleOpenClose.open,
});

export default connect(mapStateToProps)(DrawerWrapper);
