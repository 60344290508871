export const HANDLE_MENU_OC = "HANDLE_MENU_OC";
export const OPEN_MENU_OC = "OPEN_MENU_OC";
export const CLOSE_MENU_OC = "CLOSE_MENU_OC";
export const HANDLE_INFOBOTTOM_OC = "HANDLE_INFOBOTTOM_OC";
export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const IS_LOADING = "IS_LOADING";
export const PAGE_TOOLBAR = "PAGE_TOOLBAR";

// export const API_URL = `http://polito-cittatorino/api`;
// export const API_URL = `https://citta.est.polito.it/api`;
export const API_URL = `https://ctp-polito.gdpanalytics.com/api`;
// export const API_URL = `https://opencitta.est.polito.it/api`;