import React from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import TitleCard from "../../../../Homepage/components/TitleCard";
import { TextField } from "@mui/material";
import { Button } from "reactstrap";

function CreateTab(props) {
  return (
    <div style={{ fontFamily: "Montserrat" }}>
      <Row style={{ margin: 0 }}>
        <Col xxl="3" xl="4" lg="5" md="6" sm="12" style={{ height: "100%" }}>
          <div
            style={{
              ...props.layout.generic_card_ctp,
              marginTop: 20,
            }}
          >
            <TitleCard
              title="Assign a name"
              customBorderHeight={2}
              customTitleSize={{ large: 19, small: 17 }}
            ></TitleCard>
            <div style={{ padding: "15px 15px 25px 15px" }}>
              <TextField
                variant="standard"
                disabled={props.read_only}
                value={props.subActionName}
                inputProps={{
                  style: {
                    fontWeight: 500,
                    padding: 0,
                    fontFamily: "Montserrat",
                  },
                }}
                style={{ width: "100%" }}
                onChange={(e) => props.setSubActionName(e.target.value)}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row style={{ margin: 0 }}>
        <Col xs="12" style={{ height: "100%" }}>
          <div
            style={{
              ...props.layout.generic_card_ctp,
              marginTop: 20,
            }}
          >
            <TitleCard
              title="Description"
              customBorderHeight={2}
              customTitleSize={{ large: 19, small: 17 }}
            ></TitleCard>
            <div style={{ padding: "15px 15px 25px 15px" }}>
              <TextField
                variant="outlined"
                placeholder="..."
                multiline
                rows={6}
                disabled={props.read_only}
                value={props.subActionDescription}
                inputProps={{
                  style: {
                    fontWeight: 500,
                    padding: 0,
                    fontFamily: "Montserrat",
                  },
                }}
                style={{ width: "100%" }}
                onChange={(e) => props.setSubActionDescription(e.target.value)}
              />
            </div>
          </div>
        </Col>
      </Row>
      <div style={{ padding: 15 }}>
        {props.type === "insert" ? (
          <Button
            disabled={props.read_only}
            onClick={() => {
              props.setActionDetails([
                ...props.actionDetails,
                {
                  id: Date.now(),
                  idInputCategory: props.idInputCategory,
                  idInputAction: props.idInputAction,
                  selectedFeatures: props.selectedFeatures,
                  percIncrease: parseInt(props.percIncrease),
                  subActionName: props.subActionName,
                  subActionDescription: props.subActionDescription,
                },
              ]);
              props.toggle();
            }}
            style={{
              width: 150,
              borderRadius: 15,
              backgroundColor: props.layout.color_sidebar_ctp,
              borderColor: "transparent",
              fontWeight: 500,
              fontFamily: "Montserrat",
              marginTop: 15,
            }}
          >
            INSERT
          </Button>
        ) : null}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(CreateTab);
