import React, { useEffect, useState } from "react";
import TitleCard from "../../../../Homepage/components/TitleCard";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import { useWindowDimensions } from "../../../../../utils/useWindowDimensions";
import axios from "axios";
import { API_URL } from "../../../../../actions/types";
import { tokenConfig } from "../../../../../utils/tokenConfig";
import { TextField } from "@mui/material";
import { InputAdornment } from "@mui/material";
import Plotly from "plotly.js-dist";
import { usePlotlyConfig } from "../../../../../utils/usePlotlyConfig";

function SelectValueTab(props) {
  const [buildingsData, setBuildingsData] = useState([]);
  const { width, height } = useWindowDimensions();
  const plotlyConfig = usePlotlyConfig();

  useEffect(async () => {
    const res = await axios.get(`${API_URL}/data/santa_rita/`, tokenConfig());
    setBuildingsData(res.data);
  }, []);

  const dimPercIncreseChart = () => {
    if (width > 1600) {
      return 200;
    } else if (width > 1300) {
      return 180;
    } else if (width > 1100) {
      return 150;
    } else {
      return 130;
    }
  };

  // donut
  useEffect(() => {
    var data = [
      {
        values: [
          props.percIncrease > 100
            ? 100
            : props.percIncrease < 0
            ? 0
            : props.percIncrease,
          props.percIncrease > 100
            ? 0
            : props.percIncrease < 0
            ? 100
            : 100 - props.percIncrease,
        ],
        labels: ["Increase", "diff"],
        // domain: { column: 1 },
        marker: {
          colors: [props.layout.color_footer_ctp, "#E4E4E4"],
        },
        name: "GHG Emissions",
        hoverinfo: "none",
        hole: 0.7,
        type: "pie",
        textinfo: "none",
      },
    ];

    var layout = {
      annotations: [
        {
          font: {
            size: 25,
            family: "Montserrat",
          },
          showarrow: false,
          text: `${props.percIncrease}%`,
          x: 0.5,
          y: 0.5,
        },
      ],
      showlegend: false,
      margin: {
        l: 20,
        r: 20,
        b: 20,
        t: 20,
      },
      paper_bgcolor: "rgba(0,0,0,0)",
      plot_bgcolor: "rgba(0,0,0,0)",
    };

    Plotly.newPlot("percIncreaseDonut", data, layout, {
      ...plotlyConfig,
      displayModeBar: false,
    });
  }, [props.layout, props.percIncrease]);

  // avg producibility by heating type
  useEffect(() => {
    if (buildingsData.length > 0) {
      const average = (arr) => arr.reduce((p, c) => p + c, 0) / arr.length;

      var dictValues = {};
      buildingsData.forEach((obj) => {
        if (!Object.keys(dictValues).includes(obj.riscaldamento)) {
          dictValues[obj.riscaldamento] = [];
        }
      });
      buildingsData.forEach((obj) => {
        dictValues[obj.riscaldamento].push(obj.epv);
      });

      Object.keys(dictValues).forEach((k) => {
        dictValues[k] = Math.round(average(dictValues[k]));
      });
      var items = Object.keys(dictValues).map(function (key) {
        return [key, dictValues[key]];
      });
      items.sort(function (first, second) {
        return first[1] - second[1];
      });

      var data = [
        {
          type: "bar",
          y: items.map((v) => v[0]),
          x: items.map((v) => v[1]),
          orientation: "h",
          marker: {
            color: "#6DA8F6",
          },
          text: items.map((v) => `${v[1].toLocaleString()} [kWh/y]`),
          textfont: {
            family: "Montserrat",
            color: "white",
          },
        },
      ];
      var layout = {
        showlegend: false,
        margin: {
          l: 130,
          r: 50,
          b: 80,
          t: 20,
          pad: 5,
        },
        paper_bgcolor: "rgba(0,0,0,0)",
        plot_bgcolor: "rgba(0,0,0,0)",
      };
      Plotly.newPlot("avgProdChart", data, layout, {
        ...plotlyConfig,
        displayModeBar: false,
      });
    }
  }, [buildingsData]);

  return (
    <div style={{ fontFamily: "Montserrat" }}>
      <Row style={{ margin: 0 }}>
        <Col
          xxl={width > 2200 ? "2" : "3"}
          xl="4"
          lg="5"
          md="6"
          sm="12"
          style={{ height: "110px" }}
        >
          <div
            style={{
              ...props.layout.generic_card_ctp,
              marginTop: 20,
            }}
          >
            <TitleCard
              title="Num. buildings selected"
              customBorderHeight={2}
              customTitleSize={{ large: 19, small: 17 }}
            ></TitleCard>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 10,
                fontWeight: "bold",
                fontSize: 30,
              }}
            >
              {props.selectedFeatures.length}
            </div>
          </div>
        </Col>
      </Row>
      <Row style={{ margin: 0 }}>
        <Col
          lg="4"
          md="6"
          sm="12"
          style={{ height: width > 770 ? "300px" : "250px", marginTop: 20 }}
        >
          <div
            style={{
              ...props.layout.generic_card_ctp,
              marginTop: 25,
            }}
          >
            <TitleCard
              title="Percentage increase"
              customBorderHeight={2}
              customTitleSize={{ large: 19, small: 17 }}
            ></TitleCard>
            <div style={{ display: "flex", height: "90%" }}>
              <div
                style={{
                  width: "50%",

                  padding: 15,
                  fontWeight: 500,
                  fontSize: 14,
                }}
              >
                <div>Set percentage increase for the selected action</div>
                <div
                  style={{
                    marginTop: 80,
                    display: "flex",
                    alignItems: "end",
                  }}
                >
                  <TextField
                    type="number"
                    variant="standard"
                    disabled={props.read_only}
                    value={props.percIncrease}
                    onChange={(e) => props.setPercIncrease(e.target.value)}
                    inputProps={{
                      style: {
                        fontSize: 30,
                        padding: 0,
                        fontFamily: "Montserrat",
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="start"
                          style={{ color: "black", fontWeight: "bold" }}
                        >
                          <span style={{ fontSize: 20, marginLeft: 5 }}>%</span>
                        </InputAdornment>
                      ),
                    }}
                    style={{ width: "100px" }}
                  />
                </div>
              </div>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  id="percIncreaseDonut"
                  style={{
                    width: dimPercIncreseChart(),
                    height: dimPercIncreseChart(),
                  }}
                ></div>
              </div>
            </div>
          </div>
        </Col>
        <Col
          lg="8"
          md="6"
          sm="12"
          style={{ height: width > 770 ? "300px" : "250px", marginTop: 20 }}
        >
          <div
            style={{
              ...props.layout.generic_card_ctp,
              marginTop: 25,
            }}
          >
            <TitleCard
              title="Avg producibility by heating type [kWh/y]"
              customBorderHeight={2}
              customTitleSize={{ large: 19, small: 17 }}
            ></TitleCard>
            <div
              id="avgProdChart"
              style={{
                width: "100%",
                height: "100%",
              }}
            ></div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(SelectValueTab);
