import React, { Component } from "react";

export default function CheckboxRenderer(props) {
  const checkedHandler = (e) => {
    if (props.read_only === false) {
      let checked = e.target.checked;
      let colId = props.column.colId;
      props.node.setDataValue(colId, checked);
    }
  };

  return (
    <input
      type="checkbox"
      onClick={checkedHandler}
      checked={props.value}
      readOnly
    />
  );
}
