import React from "react";
import { connect } from "react-redux";
import { FaAngleDoubleLeft } from "react-icons/fa";
import { useHistory, useLocation } from "react-router-dom";

function SubNavbar(props) {
  const history = useHistory();
  const location = useLocation();

  return (
    <div
      style={{
        width: "100%",
        zIndex: 1,
        backgroundColor: props.layout.color_footer_ctp,
        height:
          props.pages === undefined
            ? props.layout.subnavbar_height_empty_ctp
            : props.layout.subnavbar_height_not_empty_ctp,
        padding: `0px 20px 0px ${props.layout.width_sidebar_ctp + 20}px`,
      }}
    >
      {/* workspace */}
      <div
        style={{
          width: "100%",
          height: "100%",
          display: props.pages === undefined ? "none" : "flex",
          justifyContent: "space-between",
          alignItems: "center",
          color: "white",
          fontFamily: "Montserrat",
          fontSize: 14,
        }}
      >
        <div style={{ display: "flex" }}>
          {props.pages !== undefined
            ? props.pages.map((obj, index) => {
                return (
                  <div
                    className="underlineOnHover"
                    onClick={() => history.push(obj.link)}
                    style={{
                      paddingLeft: 15,
                      height: 20,
                      display: "flex",
                      alignItems: "center",
                      borderLeft: "3px solid",
                      cursor: "pointer",
                      marginLeft: index === 0 ? 0 : 30,
                      borderLeftColor:
                        location.pathname === obj.link
                          ? props.layout.yellow_detail_ctp
                          : "white",
                      color:
                        location.pathname === obj.link
                          ? props.layout.yellow_detail_ctp
                          : "white",
                    }}
                  >
                    {obj.name}
                  </div>
                );
              })
            : null}
        </div>
        <div
          className="underlineOnHover"
          onClick={() => history.push("/ctp/dashboard/homepage")}
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        >
          <FaAngleDoubleLeft
            style={{ fontSize: 16, marginRight: 2 }}
          ></FaAngleDoubleLeft>
          Home
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(SubNavbar);
