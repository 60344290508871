import React, { useState, useRef, useEffect, useCallback } from "react";
import axios from "axios"
import Map from "ol/Map";
import View from "ol/View";
import OSM from "ol/source/OSM.js";
import TileLayer from "ol/layer/Tile.js";
import { fromLonLat } from "ol/proj";
import TileWMS from "ol/source/TileWMS.js";
import Overlay from "ol/Overlay.js";
import { renderToString } from "react-dom/server";
import moment from "moment";
import { Stack } from "@mui/material";
import { Slider } from "@mui/material";
import { FaPlay, FaStop } from "react-icons/fa";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TextField } from "@mui/material";
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { API_URL } from "../../../actions/types";
import { AgGridReact, AgGridColumn } from "ag-grid-react";

function TransportMap() {
  const [miliseconds, setMiliseconds] = useState(10800000);
  const [modal, setModal] = useState(false);
  const [startDate, setStartDate] = useState(moment().hours(0).minutes(0).seconds(0).subtract(5, 'days'));
  const [endDate, setEndDate] = useState(moment().hours(0).minutes(0).seconds(0));
  const interval = useRef();
  const [data, setData] = useState(null)
  const [totalData, setTotalData] = useState(null)

  const step = 10800000

  const toggle = () => {
    setModal(!modal)
  }

  const getRowStyle = useCallback((params) => {
    console.log(params)
    if (params.node.rowPinned) {
      return { fontWeight: 'bold' };
    }
  }, []);

  useEffect(() => {
    if (modal === false) {
      setData(null)
    }

    axios.post(`${API_URL}/data/public_transport/`, {
      start_date: moment.utc(startDate).local().format("YYYY-MM-DD HH:mm:ss"),
      end_date: moment.utc(endDate).local().format("YYYY-MM-DD HH:mm:ss")
    })
      .then(res => {
        const total = res.data.reduce((total, curr) => {
          total['NOx'] += curr["NOx"]
          total['PM'] += curr["PM"]
          total['distance'] += curr["distance"]
          total['energy'] += curr["energy"]
          total['CO'] += curr["CO"]
          return total
        }, { "veh_id": "Total", "NOx": 0, "CO": 0, "PM": 0, "distance": 0, "energy": 0 })
        setTotalData([total])
        setData(res.data)
      })
  }, [modal])

  const mapRef = useRef();
  const [view] = useState(
    new View({
      projection: "EPSG:3857",
      center: fromLonLat([7.646, 45.04543]),
      zoom: 12,
    })
  );
  const [wmsLayer] = useState(
    new TileWMS({
      url: "https://geoserver.est.polito.it/geoserver/wms",
      params: {
        LAYERS: "citta:pub_trans_positions",
        TILED: true,
        TIME: moment(startDate)
          .add(miliseconds, "milliseconds")
          .utc()
          .toISOString(),
      },
      serverType: "geoserver",
      transition: 0,
    })
  );

  const [map] = useState(
    new Map({
      target: null,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        new TileLayer({
          source: wmsLayer,
        }),
      ],
      view: view,
      controls: [],
    })
  );

  const getTipo = (tipo) => {
    switch (tipo) {
      case "B":
        return "BUS";
      case "T":
        return "TRAM";
      default:
        break;
    }
  };

  useEffect(() => {
    interval.current = null;
    map.setTarget(mapRef.current);
    const overlayContainer = document.createElement("div");
    overlayContainer.classList.add("ol-popup");
    const overlayContent = document.createElement("div");
    overlayContainer.appendChild(overlayContent);

    const overlay = new Overlay({
      element: overlayContainer,
      autoPan: {
        animation: {
          duration: 250,
        },
      },
    });
    map.addOverlay(overlay);

    map.on("singleclick", (evt) => {
      const url = wmsLayer.getFeatureInfoUrl(
        evt.coordinate,
        view.getResolution(),
        view.getProjection(),
        {
          INFO_FORMAT: "application/json",
        }
      );
      if (url) {
        fetch(url)
          .then((response) => response.json())
          .then((json) => {
            if (json.numberReturned === 1) {
              const properties = json.features[0].properties;
              overlayContent.innerHTML = renderToString(
                <div>
                  <h6 style={{ textAlign: "center" }}>
                    Linea {properties.linea}
                  </h6>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>Numero mezzo:</span> <span>{properties.veh_id}</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>Tipo:</span> <span>{getTipo(properties.tipo)}</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>Ora:</span>{" "}
                    <span>
                      {moment
                        .utc(properties.aggiornamento)
                        .local()
                        .format("YYYY-MM-DD HH:mm:ss")}
                    </span>
                  </div>
                </div>
              );
              overlay.setPosition(json.features[0].geometry.coordinates);
            } else {
              overlay.setPosition(undefined);
            }
          });
      }
    });
    axios.get(`${API_URL}/data/public_transport_max_date/`)
      .then(res => {
        console.log(res.data)
        setEndDate(moment(res.data))
        setStartDate(moment(res.data).subtract(1, 'days'))
      })
    // interval.current = setInterval(() => {
    //     setMiliseconds(time => moment(time).add(1, "minutes").valueOf());
    // }, 1000);
    // return () => clearInterval(interval.current);
  }, []);

  useEffect(() => {
    wmsLayer.updateParams({
      TIME: moment(startDate)
        .add(miliseconds, "milliseconds")
        .utc()
        .toISOString(),
    });
  }, [miliseconds]);

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} size="xl">
        <ModalBody>
          <div className="ag-theme-balham" style={{ height: '75vh' }}>
            <AgGridReact
              rowData={data}
              defaultColDef={{
                flex: 1,
                sortable: true,
                resizable: true,
                filter: true,
                filterParams: { buttons: ["reset"] },
                allowedAggFuncs: [
                  "sum",
                  "avg",
                ],
              }}
              sideBar={{
                toolPanels: [
                  {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    toolPanelParams: {
                      suppressPivotMode: true,
                    }
                  },
                  {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',

                  }
                ],
                defaultToolPanel: 'columns',

              }}
              sideba
              groupDisplayType="singleColumn"
              groupUseEntireRow={false}
              groupMultiAutoColumn={true}
              pinnedBottomRowData={totalData}
              getRowStyle={getRowStyle}
              onColumnRowGroupChanged={(node) => {
                node.columnApi
                  .getAllColumns()
                  .forEach((col) =>
                    node.columnApi.setColumnVisible(col.colId, true)
                  );
                node.columns.forEach((column) => {
                  node.columnApi.setColumnVisible(column.colId, false);
                });
              }}

            >
              <AgGridColumn field="veh_id" headerName="Vehicle ID" />
              <AgGridColumn field="category" enableRowGroup={true} />
              <AgGridColumn field="distance" headerName="Distance [km]" enableValue={true} />
              <AgGridColumn field="energy" headerName="Energy [kWh]" enableValue={true} />
              <AgGridColumn field="NOx" headerName="NOx" enableValue={true} />
              <AgGridColumn field="PM" headerName="PM" enableValue={true} />
              <AgGridColumn field="CO" headerName="CO [kg]" enableValue={true} />
            </AgGridReact>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <div ref={mapRef} style={{ width: "100%", height: "100%", position: "relative" }} >
        <div style={{ position: "absolute", zIndex: 999, backgroundColor: "white", padding: 10, borderRadius: 5, bottom: 10, left: '50%', transform: "translateX(-50%)" }}>
          <Stack
            spacing={2}
            direction="row"
            sx={{ margin: "auto", width: 800 }}
            alignItems="center"
          >
            <DesktopDatePicker
              label="Start Date"
              inputFormat="DD-MM-YYYY"
              value={startDate}
              onChange={(v) => {
                setStartDate(v);
                setMiliseconds(step);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
            <div style={{ width: 10 }} />
            <FaPlay
              id="play-icon"
              style={{ zIndex: 999, cursor: "pointer" }}
              onClick={(e) => {
                if (interval.current === null) {
                  interval.current = setInterval(() => {
                    setMiliseconds((minutes) => minutes + 60000);
                    const playElement = document.getElementById("play-icon");
                    playElement.style.color = "gray";
                    playElement.style.cursor = "not-allowed";
                    const stopElement = document.getElementById("stop-icon");
                    stopElement.style.color = "black";
                    stopElement.style.cursor = "pointer";
                  }, 1000);
                }
              }}
            />
            <FaStop
              id="stop-icon"
              style={{ zIndex: 999, cursor: "not-allowed", color: "gray" }}
              onClick={(e) => {
                clearInterval(interval.current);
                interval.current = null;
                const stopElement = document.getElementById("stop-icon");
                stopElement.style.color = "gray";
                stopElement.style.cursor = "not-allowed";
                const playElement = document.getElementById("play-icon");
                playElement.style.color = "black";
                playElement.style.cursor = "pointer";
              }}
            />
            <div style={{ width: 0 }} />
            <Slider
              valueLabelDisplay="auto"
              style={{ width: 580 }}
              value={miliseconds}
              min={step}
              // max={97200000}
              max={(endDate - startDate).valueOf() + step}
              step={60000}
              valueLabelFormat={(value) =>
                moment(startDate)
                  .add(value, "milliseconds")
                  .format("YYYY-MM-DD HH:mm:ss")
              }
              onChange={(e) => {
                setMiliseconds(e.target.value);
                clearInterval(interval.current);
                interval.current = null;
                const stopElement = document.getElementById("stop-icon");
                stopElement.style.color = "gray";
                stopElement.style.cursor = "not-allowed";
                const playElement = document.getElementById("play-icon");
                playElement.style.color = "black";
                playElement.style.cursor = "pointer";
              }}
            />
            <div style={{ width: 10 }} />
            <DesktopDatePicker
              label="End Date"
              inputFormat="DD-MM-YYYY"
              value={endDate}
              onChange={(v) => {
                setEndDate(v);
                setMiliseconds(step);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Stack>
        </div>
        <div style={{
          position: "absolute",
          backgroundColor: "white",
          zIndex: 999,
          top: 10,
          left: 10,
          borderRadius: 5,
          padding: 10,
          display: "flex",
          alignItems: "center",
          justifyContent: "center", flexDirection: "column"
        }}>
          <div style={{ fontWeight: 'bold' }}>Displayed Date</div>
          <div>{moment
            .utc(startDate)
            .add(miliseconds, "milliseconds")
            .local()
            .format("YYYY-MM-DD HH:mm:ss")}</div>
        </div>
        <div
          style={{
            position: "absolute",
            zIndex: 999,
            top: 10,
            right: 10,
            borderRadius: 5,
            padding: 10,
            fontWeight: "bold",
            cursor: "pointer",
            backgroundColor: "#2b35af",
            color: "white",
            userSelect: "none",
          }}
          onClick={() => { toggle() }}
        >Analysis</div>
      </div>
    </>
  );
}

export default TransportMap;
