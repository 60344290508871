import React, { useEffect } from "react";
import { connect } from "react-redux";
import { setToolbarPage } from "../../../actions/toolbar";
import { closeMenuOC } from "../../../actions/handleOpenCloseActions";
import Sidebar from "../../../layout/CTP/Sidebar/Sidebar";
import Footer from "../../../layout/CTP/Footer/Footer";
import Navbar from "../../../layout/CTP/Navbar/Navbar";
import SubNavbar from "../../../layout/CTP/Navbar/SubNavbar";
import Content from "./components/Content";

export const CreateActions = (props) => {
  useEffect(() => {
    props.setToolbarPage("Create actions CTP");
    props.closeMenuOC();
  }, []);

  const pages = [
    { name: "Create actions", link: "/ctp/dashboard/create_actions" },
  ];

  return (
    <div>
      <Sidebar></Sidebar>
      <Navbar></Navbar>
      <SubNavbar pages={pages}></SubNavbar>
      <Content pages={pages}></Content>
      <Footer></Footer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setToolbarPage, closeMenuOC })(
  CreateActions
);
