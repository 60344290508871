import React, { useRef, useEffect, useState } from "react";
import * as Cesium from "cesium";
import "cesium/Build/Cesium/Widgets/widgets.css";
import { Polygon } from 'ol/geom';
import circle from "./circle.png"
import polygon from "./polygon.png"
import { circular } from 'ol/geom/Polygon'
import Feature from 'ol/Feature.js';
import axios from 'axios'
import WKT from 'ol/format/WKT.js';
import GML from 'ol/format/GML.js';

export default function Map3DRoadNetwork({ selectedFeaturesOBJ, setSelectedFeaturesOBJ }) {
  const [polygonDrawActive, setPolygonDrawActive] = useState<boolean>(false)
  const [circleDrawActive, setCircleDrawActive] = useState<boolean>(false)

  const container = useRef<HTMLDivElement>(null);
  const viewer = useRef<Cesium.Viewer>();
  const mapHandler = useRef<Cesium.ScreenSpaceEventHandler>()
  const roadNetworkLayer = useRef<Cesium.ImageryLayer>();

  const OSMImagery = () => {
    viewer.current?.imageryLayers.removeAll();
    const osmImagery = new Cesium.OpenStreetMapImageryProvider({
      url: "https://a.tile.openstreetmap.org/",
    });
    viewer.current?.imageryLayers.addImageryProvider(osmImagery);
  };

  const pointsAreClose = (point1: Cesium.Cartesian3, point2: Cesium.Cartesian3) => {
    const cameraHeight = Cesium.Cartographic.fromCartesian(viewer.current?.camera.positionWC!).height
    const distanceToInitialPosition = Cesium.Cartesian3.distance(point1, point2)
    if (100 * distanceToInitialPosition / cameraHeight < 4) {
      return true
    } else {
      return false
    }
  }

  const addRoadNetwork = (gml) => {
    const provider = new Cesium.WebMapServiceImageryProvider({
      url: 'https://geoserver.est.polito.it/geoserver/citta/wms',
      layers: 'citta:road_network',
      parameters: {
        format: "image/png",
        transparent: true,
        SLD_BODY: getSldBody(gml)
      }
    });
    roadNetworkLayer.current = new Cesium.ImageryLayer(provider);
    viewer.current?.imageryLayers.add(roadNetworkLayer.current);
  }

  const getSldBody = (gmlGeometry) => {
    return `
      <?xml version="1.0" encoding="UTF-8"?>
      <StyledLayerDescriptor 
        xmlns="http://www.opengis.net/sld"
        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" 
        xmlns:ogc="http://www.opengis.net/ogc"
        xmlns:gml="http://www.opengis.net/gml"
        xmlns:se="http://www.opengis.net/se" 
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xsi:schemaLocation="http://www.opengis.net/sld http://schemas.opengis.net/sld/1.1.0/StyledLayerDescriptor.xsd"
        version="1.1.0"
      >
        <NamedLayer>
          <se:Name>road_network</se:Name>
          <UserStyle>
            <se:Name>road_network</se:Name>
            <se:FeatureTypeStyle>
              <se:Rule>
                <se:Name>Not Selected</se:Name>
                <se:LineSymbolizer>
                  <se:Stroke>
                    <se:SvgParameter name="stroke">#f2ca34</se:SvgParameter>
                    <se:SvgParameter name="stroke-width">3.5</se:SvgParameter>
                    <se:SvgParameter name="stroke-linejoin">bevel</se:SvgParameter>
                    <se:SvgParameter name="stroke-linecap">square</se:SvgParameter>
                  </se:Stroke>
                </se:LineSymbolizer>
              </se:Rule>
              ${gmlGeometry ? `
              <se:Rule>
                <se:Name>Selected</se:Name>
                <ogc:Filter xmlns:ogc="http://www.opengis.net/ogc">
                  <ogc:Within>
                    <ogc:PropertyName>geometry</ogc:PropertyName>
                      ${gmlGeometry}
                  </ogc:Within>
                </ogc:Filter>
                <se:LineSymbolizer>
                  <se:Stroke>
                    <se:SvgParameter name="stroke">#00FFFF</se:SvgParameter>
                    <se:SvgParameter name="stroke-width">3</se:SvgParameter>
                  </se:Stroke>
                </se:LineSymbolizer>
              </se:Rule>
              `: ""}
            </se:FeatureTypeStyle>
          </UserStyle>
        </NamedLayer>
      </StyledLayerDescriptor>
      `;
  }


  const geoserverSelectedFeatures = (polygon: Polygon) => {
    const p = new Feature(polygon)
    const geometryWKT = new WKT().writeFeature(p)
    axios.get("https://geoserver.est.polito.it/geoserver/citta/ows", {
      params: {
        "service": "WFS",
        "version": "1.0.0",
        "request": "GetFeature",
        "typeName": "citta:road_network",
        "outputFormat": "text/csv",
        "propertyName": "id",
        "cql_filter": `within(geometry, ${geometryWKT})`
      }
    }).then(response => {
      const data = response.data.split("\r\n").slice(1).filter(d => d !== "")
      const featuresIDs = data
        .map(d => d.split(",")[1])
        .reduce((obj, id) => {
          obj[id] = true;
          return obj
        }, {})
      // setSelectedFeaturesOBJ(featuresIDs)
      viewer.current?.imageryLayers.remove(roadNetworkLayer.current!);
      addRoadNetwork(new GML().writeGeometry(polygon))
    })
  }

  const addClickHandler = () => {
    viewer.current?.entities.removeAll()
    mapHandler.current?.destroy()
    mapHandler.current = new Cesium.ScreenSpaceEventHandler(viewer.current?.canvas);
    mapHandler.current.setInputAction(click => {
      var pickRay = viewer.current?.camera.getPickRay(click.position);
      var featuresPromise = viewer.current?.imageryLayers.pickImageryLayerFeatures(pickRay, viewer.current?.scene);
      if (Cesium.defined(featuresPromise)) {
        // featuresPromise?.then(features => {
        //   setSelectedFeaturesOBJ((oldState) => ({ [features[0].data.properties.id]: true }));
        // })
      }
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    mapHandler.current.setInputAction(click => {
      var pickRay = viewer.current?.camera.getPickRay(click.position);
      var featuresPromise = viewer.current?.imageryLayers.pickImageryLayerFeatures(pickRay, viewer.current?.scene);
      if (Cesium.defined(featuresPromise)) {
        featuresPromise?.then(features => {
          // setSelectedFeaturesOBJ((old) => ({
          //   ...old,
          //   [features[0].data.properties.id]: !(old[features[0].data.properties.id] ?? false),
          // }));
        })
      }
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK, Cesium.KeyboardEventModifier.CTRL);
  }



  useEffect(() => {
    // if (polygonDrawActive && viewer.current && selectedFeaturesOBJ) {
    if (polygonDrawActive && viewer.current) {
      viewer.current?.entities.removeAll()
      mapHandler.current?.destroy()
      mapHandler.current = new Cesium.ScreenSpaceEventHandler(viewer.current!.canvas);

      var points: Cesium.Cartesian3[] = [];
      let lastPointadded: Cesium.Cartesian3;
      let mousePosition = viewer.current?.entities.add({
        position: undefined,
        id: 'mousePosition',
        point: {
          pixelSize: 10,
          color: Cesium.Color.fromCssColorString("#0099ff"),
          heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
        },
      });

      mapHandler.current?.setInputAction((event) => {
        const ray = viewer.current!.camera.getPickRay(event.position);
        if (ray !== undefined) {
          var position = viewer.current!.scene.globe.pick(ray, viewer.current!.scene);
          if (Cesium.defined(position)) {
            switch (true) {
              case points.length === 0:
                points.push(position!)
                viewer.current!.entities.add({
                  id: "drawnShape",
                  polyline: {
                    positions: new Cesium.CallbackProperty(() => points, false),
                    clampToGround: true,
                    material: Cesium.Color.fromCssColorString("#0099ff"),
                    width: 5,
                  },
                  polygon: {
                    hierarchy: new Cesium.CallbackProperty(() => new Cesium.PolygonHierarchy(points), false),
                    material: Cesium.Color.WHITE.withAlpha(0.3),
                  },
                });
                lastPointadded = position!
                break;

              case pointsAreClose(points[0], position!):
                if (points.at(-1) !== lastPointadded) points.pop()
                position = points[0].clone()
                points.push(position)
                setPolygonDrawActive(false)
                const coordinates = points.map(point => {
                  const cartographic = Cesium.Cartographic.fromCartesian(point);
                  return [Cesium.Math.toDegrees(cartographic.longitude), Cesium.Math.toDegrees(cartographic.latitude)]
                })
                geoserverSelectedFeatures(new Polygon([coordinates]))
                break

              default:
                if (points.at(-1) !== lastPointadded) points.pop()
                points.push(position!)
                lastPointadded = position!
                break;
            }
          }
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK)

      mapHandler.current?.setInputAction((event) => {
        const ray = viewer.current?.camera.getPickRay(event.endPosition);
        if (ray !== undefined) {
          var position = viewer.current!.scene.globe.pick(ray, viewer.current!.scene);
          if (Cesium.defined(position) && points.length > 0) {
            if (points.at(-1) !== lastPointadded) points.pop()
            if (pointsAreClose(points[0], position!)) {
              position = points[0].clone()
            }
            points.push(position!)
          }
          mousePosition.position = new Cesium.ConstantPositionProperty(position!)
        }
      }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

    } else if (circleDrawActive === false && polygonDrawActive === false) {
      addClickHandler()
      viewer.current?.entities.removeById("mousePosition")
    }
  }, [polygonDrawActive])



  useEffect(() => {
    if (circleDrawActive && viewer.current && selectedFeaturesOBJ) {
      viewer.current?.entities.removeAll()
      mapHandler.current?.destroy()
      mapHandler.current = new Cesium.ScreenSpaceEventHandler(viewer.current!.canvas);

      let center: Cesium.Cartographic;
      let circle: Polygon;
      let mousePosition = viewer.current?.entities.add({
        position: undefined,
        id: 'mousePosition',
        point: {
          pixelSize: 10,
          color: Cesium.Color.fromCssColorString("#0099ff"),
          heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
        },
      });

      mapHandler.current?.setInputAction((event) => {
        const ray = viewer.current!.camera.getPickRay(event.position);
        if (ray !== undefined) {
          var position = viewer.current!.scene.globe.pick(ray, viewer.current!.scene);
          if (Cesium.defined(position)) {
            switch (true) {
              case center === undefined:
                center = Cesium.Cartographic.fromCartesian(position!.clone())
                circle = circular([
                  Cesium.Math.toDegrees(center.longitude),
                  Cesium.Math.toDegrees(center.latitude),
                ], 1, 64)

                viewer.current!.entities.add({
                  id: "drawnShape",
                  polyline: {
                    positions: new Cesium.CallbackProperty(() => {
                      return circle.getCoordinates()[0].map(coordinate => Cesium.Cartographic.toCartesian(Cesium.Cartographic.fromDegrees(coordinate[0], coordinate[1])))
                    }, false),
                    clampToGround: true,
                    material: Cesium.Color.fromCssColorString("#0099ff"),
                    width: 5,
                  },
                  polygon: {
                    hierarchy: new Cesium.CallbackProperty(() => new Cesium.PolygonHierarchy(circle.getCoordinates()[0].map(coordinate => Cesium.Cartographic.toCartesian(Cesium.Cartographic.fromDegrees(coordinate[0], coordinate[1])))), false),
                    material: Cesium.Color.WHITE.withAlpha(0.3),
                  },
                });
                break
              default:
                setCircleDrawActive(false)
                // geoserverSelectedFeatures(circle)
                break;
            }
          }
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK)

      mapHandler.current?.setInputAction((event) => {
        const ray = viewer.current?.camera.getPickRay(event.endPosition);
        if (ray !== undefined) {
          var position = viewer.current!.scene.globe.pick(ray, viewer.current!.scene);
          if (Cesium.defined(position) && center !== undefined) {
            const radius = Cesium.Cartesian3.distance(Cesium.Cartographic.toCartesian(center), position!)
            circle = circular([
              Cesium.Math.toDegrees(center.longitude),
              Cesium.Math.toDegrees(center.latitude),
            ], radius, 64)
          }
          mousePosition.position = new Cesium.ConstantPositionProperty(position!)
        }
      }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

    } else if (circleDrawActive === false && polygonDrawActive === false) {
      addClickHandler()
      viewer.current?.entities.removeById("mousePosition")
    }
  }, [circleDrawActive])

  useEffect(() => {
    if (!container.current || viewer.current) return;

    viewer.current = new Cesium.Viewer(container.current, {
      timeline: false,
      animation: false,
      baseLayerPicker: false,
      homeButton: false,
      navigationHelpButton: false,
      infoBox: true,
      scene3DOnly: true,
      geocoder: false,
      shadows: false,
      selectionIndicator: true,
      fullscreenButton: false,
    });

    viewer.current.camera.setView({
      destination: Cesium.Cartesian3.fromDegrees(7.78, 45.02, 10000),
      orientation: {
        heading: Cesium.Math.toRadians(-60.0),
        pitch: Cesium.Math.toRadians(-45.0),
      },
    });
    viewer.current.screenSpaceEventHandler.removeInputAction(
      Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK
    );
    viewer.current.scene.globe.depthTestAgainstTerrain = true;
    OSMImagery();
    addRoadNetwork(null)
    addClickHandler()
  }, []);

  return (
    <div ref={container} style={{ width: '100%', height: '100%', position: 'relative' }}>
      <div style={{ position: 'absolute', top: '0.5em', left: '0.5em', backdropFilter: 'rgba(0, 0, 0, 0)', pointerEvents: 'auto', zIndex: 1 }}>
        <div
          style={{ backgroundColor: polygonDrawActive ? 'aqua' : 'white', cursor: 'pointer', width: '100%', height: '100%', border: '1px solid gray', borderRadius: 5, padding: 2.5 }}
          onClick={() => { setPolygonDrawActive(!polygonDrawActive); setCircleDrawActive(false); }}
        >
          <img src={polygon} style={{ width: 25, height: 25 }} alt="polygon" />
        </div>
        <div
          style={{ backgroundColor: circleDrawActive ? 'aqua' : 'white', cursor: 'pointer', width: '100%', height: '100%', border: '1px solid gray', borderRadius: 5, padding: 2.5 }}
          onClick={() => { setCircleDrawActive(!circleDrawActive); setPolygonDrawActive(false); }}
        >
          <img src={circle} style={{ width: 25, height: 25 }} alt="circle" />
        </div>
      </div>


    </div>
  );
}
