import React from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { API_URL } from "../../../actions/types";

function DropDownMenu(props) {
  const history = useHistory();
  const location = useLocation();

  const styleSubPage = {
    width: 180,
    height: props.layout.height_button_submenu_ctp,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 16,
    fontWeight: 500,
    fontFamily: "Montserrat",
    borderRadius: 7,
    backgroundColor: props.layout.color_background_button_sidebar_ctp,
    marginTop: 10,
    cursor: "pointer",
  };

  const parentLeftPosition = document
    .getElementById(props.idParentLeft)
    .getBoundingClientRect();
  const midParentLeft =
    (parentLeftPosition.top + parentLeftPosition.bottom) / 2;
  const calculatedHeightDropdown =
    props.pages.length * (props.layout.height_button_submenu_ctp + 10) +
    10 +
    20;
  const halfHeightDropdown = calculatedHeightDropdown / 2;
  const topPosition = midParentLeft - halfHeightDropdown;

  return (
    <div
      style={{
        backgroundColor: props.layout.color_sidebar_ctp,
        position: "absolute",
        left: props.layout.width_sidebar_ctp - 10,
        top: topPosition,
        padding: 20,
        paddingLeft: 30,
        paddingTop: 10,
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        color: "white",
      }}
    >
      {props.pages.map((page) => {
        if (page.external_url === true) {
          return (
            <a
              style={{
                ...styleSubPage,
                textDecoration: "none",
                color: "white",
                border:
                  page.url === location.pathname
                    ? `2px solid ${props.layout.yellow_detail_ctp}`
                    : `2px solid ${props.layout.color_border_button_sidebar_ctp}`,
              }}
              href={
                window.location.hostname.includes("localhost")
                  ? `http://localhost:3009/clicc/dashboard/homepage/?token=${localStorage.getItem(
                      "token"
                    )}`
                  : API_URL.replace("citta.est", "clicc.est").replace(
                      "/api",
                      `/clicc/dashboard/homepage/?token=${localStorage.getItem(
                        "token"
                      )}`
                    )
              }
              target="_blank"
            >
              {page.name}
            </a>
          );
        } else {
          return (
            <div
              style={{
                ...styleSubPage,
                border:
                  page.url === location.pathname
                    ? `2px solid ${props.layout.yellow_detail_ctp}`
                    : `2px solid ${props.layout.color_border_button_sidebar_ctp}`,
              }}
              onClick={() => {
                history.push(page.url);
              }}
            >
              {page.name}
            </div>
          );
        }
      })}
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(DropDownMenu);
