import React, { useEffect, useState } from "react";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import { useWindowDimensions } from "../../../utils/useWindowDimensions";
import { API_URL } from "../../../actions/types";
import axios from "axios";
import { tokenConfig } from "../../../utils/tokenConfig";
import { exportSheetNameTable } from "../../../utils/exportSheetNameTable";
import { getFontSize } from "../../../utils/getFontSize";

export const GasPrice = () => {
  const [rowData, setRowData] = useState(null);
  const { width, height } = useWindowDimensions();

  useEffect(async () => {
    const res = await axios.get(`${API_URL}/data/santa_rita/`, tokenConfig());
    setRowData(res.data);
  }, []);
  return (
    <div style={{ padding: 20 }}>
      <h2>Buildings</h2>
      {rowData === null ? (
        <div>Loading...</div>
      ) : (
        <div
          style={{ width: "100%", height: height - 110, marginTop: 30 }}
          className="ag-theme-balham"
        >
          <AgGridReact
            rowData={rowData}
            defaultColDef={{
              filter: true,
              sortable: true,
              resizable: true,
              enableRowGroup: true,
              enablePivot: true,
              enableValue: true,
              flex: 1,
              filterParams: { buttons: ["reset"] },
              cellStyle: (params) => {
                return {
                  fontSize: getFontSize("extraSmall"),
                };
              },
              headerClass: function (params) {
                if (width > 5000) {
                  return "bigAgGridHeader";
                } else {
                  return "normalAgGridHeader";
                }
              },
            }}
            sideBar={{
              toolPanels: ["columns", "filters"],
            }}
            getContextMenuItems={(params) =>
              exportSheetNameTable(params, "Export")
            }
          >
            <AgGridColumn field="id" headerName="ID" />
            <AgGridColumn field="identifier" />
            <AgGridColumn field="type" />
            <AgGridColumn field="description" />
            <AgGridColumn field="elevation" headerName="Elevation [m]" />
            <AgGridColumn field="area" headerName="Area [m²]" />
            <AgGridColumn field="epv" headerName="EPV [kWh/year]" />
            <AgGridColumn field="riscaldamento" />
            <AgGridColumn field="age" />
            <AgGridColumn field="enthermgr" />
          </AgGridReact>
        </div>
      )}
    </div>
  );
};

export default GasPrice;
