import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { logout } from "../../actions/auth";
import { connect } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import CreditsPhoto from "../../../images/torino_logo_full.png";
import { FaRegCopyright } from "react-icons/fa";

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  customBadge: {
    backgroundColor: "#debb71",
    color: "white",
  },
}));

const AppBarDisplayLong = ({ logout, page, lastName, open, isStaff }) => {
  const classes = useStyles();

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  return (
    <React.Fragment>
      <div className="row" style={{ width: "100%" }}>
        <div className="col" style={{ marginTop: "8px" }}>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
            style={open ? { marginLeft: "0px" } : { marginLeft: "80px" }}
          >
            {page}
          </Typography>
        </div>
        {/* <div
          className="col"
          style={
            open
              ? { paddingLeft: "20px", marginTop: "15px", fontSize: "16px" }
              : {
                  paddingLeft: "150px",
                  marginTop: "15px",
                  fontSize: "16px",
                }
          }
        >
          <PersonIcon
            style={{ marginRight: "5px", marginTop: "-4px" }}
          ></PersonIcon>
          {isStaff ? (
            <Badge
              classes={{ badge: classes.customBadge }}
              badgeContent={" Admin "}
            >
              <h6 style={{ marginRight: "35px" }}>{lastName}</h6>
            </Badge>
          ) : (
            <React.Fragment>{lastName}</React.Fragment>
          )}
        </div> */}
        {/* <div
          className="col"
          align="center"
          style={{
            paddingLeft: "30px",
            marginTop: "15px",
            fontSize: "16px",
            marginLeft: "10px",
            cursor: "pointer",
          }}
          onClick={toggle}
        >
          <div
            style={{
              color: "blue",
              border: "1px solid blue",
              borderRadius: 20,
              width: "120px",
              padding: "5px",
            }}
          >
            <FaRegCopyright
              style={{
                marginRight: "5px",
                marginTop: "-4px",
              }}
            ></FaRegCopyright>
            Credits
          </div>
          <Modal isOpen={modal} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle}>Credits</ModalHeader>
            <ModalBody>
              <img
                src={CreditsPhoto}
                alt="logo"
                style={{
                  width: "100%",
                }}
              ></img>
            </ModalBody>
          </Modal>
        </div> */}

        <div className="col" align="right">
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => {
              logout();
            }}
            style={{
              padding: "11px 40px",
              backgroundColor: "#e04943",
              marginRight: "5px",
              borderColor: "#e04943",
            }}
          >
            Logout
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  page: state.toolbar.page,
  firstName: state.auth.firstName,
  lastName: state.auth.lastName,
  open: state.handleOpenClose.open,
  isStaff: state.auth.isStaff,
});

export default connect(mapStateToProps, { logout })(AppBarDisplayLong);
