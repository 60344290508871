import React, { useEffect } from "react";
import { connect } from "react-redux";
import { setToolbarPage } from "../../actions/toolbar";
import { closeMenuOC } from "../../actions/handleOpenCloseActions";
import TopRow from "../../layout/CTP/_old/TopRow";
import BottomRow from "../../layout/CTP/_old/BottomRow";
import MainContent from "./components/MainContent";

export const Dashboard = (props) => {
  useEffect(() => {
    props.setToolbarPage("Energy balance CTP");
    props.closeMenuOC();
  }, []);

  return (
    // <div className="light-blue-background">
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: props.layout.color_main_content_background_ctp,
      }}
    >
      <TopRow showSettings={false}></TopRow>

      <MainContent></MainContent>

      <BottomRow></BottomRow>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  layout: state.layout,
});

export default connect(mapStateToProps, { setToolbarPage, closeMenuOC })(
  Dashboard
);
