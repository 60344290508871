import React, { useState } from "react";
import { Checkbox } from "@mui/material";
import { Modal, ModalHeader, ModalBody, ModalFooter, Card } from "reactstrap";
import { Button } from "reactstrap";
import { getIconMapped } from "../../../../utils/getIconMapped";
import { FaSearch } from "react-icons/fa";
import { TextField } from "@mui/material";
import { connect } from "react-redux";
import { API_URL } from "../../../../actions/types";
import axios from "axios";
import { tokenConfig } from "../../../../utils/tokenConfig";
import SuccessAlert from "../../../../components/Modals/SuccessAlert";

function ModalDeleteAction(props) {
  const [searchText, setSearchText] = useState("");
  const toggle = () => props.setIsOpen(!props.isOpen);
  return (
    <Modal isOpen={props.isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Attention</ModalHeader>
      <ModalBody>
        <div
          style={{
            marginBottom: 20,
            fontFamily: "Montserrat",
            fontWeight: 500,
            fontSize: 14,
          }}
        >
          Do you really want to delete the selected action?
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="danger"
          onClick={async () => {
            await axios.delete(
              `${API_URL}/actions/${props.actionSelected.id}/`,
              tokenConfig()
            );
            // get list of actions
            const resActionsAnagrafica = await axios.get(
              `${API_URL}/actions/`,
              [],
              tokenConfig()
            );
            props.setActionsAnagrafica(resActionsAnagrafica.data);
            props.setSubActionsChecked([]);
            props.setActionSelected(null);
            props.setIsOpen(false);
            SuccessAlert("Action deleted successfully");
          }}
        >
          Delete
        </Button>
        <Button onClick={() => props.setIsOpen(false)}>Close</Button>
      </ModalFooter>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(ModalDeleteAction);
