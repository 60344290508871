import React, { useState } from "react";
import { Checkbox } from "@mui/material";
import { Modal, ModalHeader, ModalBody, ModalFooter, Card } from "reactstrap";
import { Button } from "reactstrap";
import { getIconMapped } from "../../../../utils/getIconMapped";
import { FaSearch } from "react-icons/fa";
import { TextField } from "@mui/material";
import { connect } from "react-redux";
import { API_URL } from "../../../../actions/types";
import axios from "axios";
import { tokenConfig } from "../../../../utils/tokenConfig";

function ModalActions(props) {
  const [searchText, setSearchText] = useState("");
  const toggle = () => props.setIsOpen(!props.isOpen);
  return (
    <Modal isOpen={props.isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Select an action</ModalHeader>
      <ModalBody>
        <div style={{ marginBottom: 20 }}>
          <FaSearch style={{ color: "gray" }}></FaSearch>
          <TextField
            variant="standard"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            style={{ marginLeft: 10, marginTop: "-5px" }}
            placeholder="Search..."
          />
        </div>
        {/* filter domains by searchText in either domain name or action name */}
        {props.actionsAnagrafica
          .filter(
            (action) =>
              action.name.toLowerCase().includes(searchText.toLowerCase()) ||
              action.description
                .toLowerCase()
                .includes(searchText.toLowerCase())
          )
          .map((action, index) => {
            return (
              <Card
                body
                style={{
                  marginTop: index === 0 ? 0 : 20,
                  fontFamily: "Montserrat",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>{action.name}</span>
                  <Button
                    onClick={async () => {
                      const res = await axios.get(
                        `${API_URL}/actions/${action.id}`,
                        tokenConfig()
                      );
                      props.setActionSelected({
                        ...action,
                        sub_actions: res.data,
                      });
                      toggle();
                    }}
                    style={{
                      width: 80,
                      borderRadius: 15,
                      backgroundColor: props.layout.color_sidebar_ctp,
                      borderColor: "transparent",
                      cursor: "pointer",
                      fontWeight: 500,
                      fontFamily: "Montserrat",
                      fontSize: 12,
                    }}
                  >
                    OPEN
                  </Button>
                </div>

                <span style={{ fontWeight: 500, fontSize: 14 }}>
                  Description:{" "}
                  {action.description === "" ? "--" : action.description}
                </span>
              </Card>
            );
          })}
      </ModalBody>
      {/* <ModalFooter>
        <Button onClick={() => props.setIsOpen(false)}>Close</Button>
      </ModalFooter> */}
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(ModalActions);
