import React, { useState } from "react";
import { Tooltip } from "@mui/material";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";
import TitleCard from "../../../Homepage/components/TitleCard";
import ModalPolicies from "./ModalPolicies";
import {
  FaPlus,
  FaPencilAlt,
  FaSpinner,
  FaAngleDoubleRight,
  FaTimes,
  FaSearchPlus,
} from "react-icons/fa";
import { getIconMapped } from "../../../../utils/getIconMapped";
import { Button } from "reactstrap";
import { getHeightWorkspace } from "../../../../utils/getHeightWorkspace";

function ComparePolicies(props) {
  const { width, height } = useWindowDimensions();
  const [isOpen, setIsOpen] = useState(false);
  const [actionSelected, setActionSelected] = useState("");

  return (
    <Col
      xxl={width > 1900 ? "2" : "3"}
      xl="3"
      lg="3"
      md="12"
      sm="12"
      style={{
        position: "relative",
        padding: 10,
        height: getHeightWorkspace(
          props.pages,
          height,
          props.layout.subnavbar_height_empty_ctp,
          props.layout.subnavbar_height_not_empty_ctp
        ),
      }}
    >
      <div
        style={{
          ...props.layout.generic_card_ctp,
          display: "flex",
          flexFlow: "column",
          height: "100%",
        }}
      >
        {props.isLoading === true ? (
          <div style={{ padding: 20 }}>
            <FaSpinner className="spinner"></FaSpinner>
          </div>
        ) : (
          <>
            <TitleCard
              title="Compare policies"
              icon={
                <Tooltip title="Select domains">
                  <div
                    style={{
                      backgroundColor: props.layout.color_sidebar_ctp,
                      padding: "2px 8px 5.5px 8px",
                      borderRadius: "50%",
                      marginTop: 10,
                      cursor: "pointer",
                    }}
                    onClick={() => setIsOpen(true)}
                  >
                    <FaPlus
                      style={{
                        color: "white",
                        fontSize: 15,
                      }}
                    ></FaPlus>
                  </div>
                </Tooltip>
              }
            ></TitleCard>
            <div
              style={{
                padding: "10px 15px",
                flex: "1 1 auto",
                borderRadius: props.layout.generic_card_ctp.borderRadius,
                height: "auto",
                width: "100%",
                overflow: "auto",
              }}
            >
              <div style={{ height: "90%", overflow: "auto" }}>
                <div
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: 500,
                    fontSize: 14,
                  }}
                >
                  {props.policiesSelected.length === 0 ? (
                    <div
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "bold",
                        color: props.layout.color_footer_ctp,
                        fontSize: width > 1450 ? 16 : 14,
                      }}
                    >
                      Select one or more policies
                    </div>
                  ) : (
                    props.policiesSelected.map((policy) => {
                      return (
                        <>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginTop: 15,
                            }}
                          >
                            <div>
                              <FaTimes
                                onClick={() =>
                                  props.setPoliciesSelected(
                                    props.policiesSelected.filter(
                                      (p) => p.id !== policy.id
                                    )
                                  )
                                }
                                style={{ color: "#EE3C3C", cursor: "pointer" }}
                              ></FaTimes>
                              <span style={{ marginLeft: 8 }}>
                                {policy.name}
                              </span>
                            </div>
                            {/* <FaSearchPlus
                              style={{ marginRight: 5, cursor: "pointer" }}
                            ></FaSearchPlus> */}
                          </div>
                        </>
                      );
                    })
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <ModalPolicies
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        policiesAnagrafica={props.policiesAnagrafica}
        policiesSelected={props.policiesSelected}
        setPoliciesSelected={props.setPoliciesSelected}
      ></ModalPolicies>
    </Col>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(ComparePolicies);
