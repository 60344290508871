import React, { useEffect } from "react";
import { useWindowDimensions } from "../../../utils/useWindowDimensions";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import TitleCard from "./TitleCard";
import TorinoScreen from "../../../../images/screen_torino_cropped.png";
import Plotly from "plotly.js-dist";
import { usePlotlyConfig } from "../../../utils/usePlotlyConfig";
import {
  consumiPerSettore2020,
  consumiPerCommodity2020,
} from "../../../utils/energyBalance";
import axios from "axios";
import { tokenConfig } from "../../../utils/tokenConfig";
import { API_URL } from "../../../actions/types";
import { getHeightWithFooterWONavbar } from "../../../utils/getHeightWithFooterWONavbar";

function Content(props) {
  const { width, height } = useWindowDimensions();
  const plotlyConfig = usePlotlyConfig();
  const history = useHistory();

  const cardsContent = [
    {
      title: (
        <div>
          CO<sub>2</sub>
        </div>
      ),
      description: "Concentration and trend of GreenHouse Gases in [t]",
      value: "2.4M",
      deltaPerc: -21.01,
    },
    {
      title: "PM10",
      description: (
        <div>
          Concentration and trend of particles in [μg/m<sup>3</sup>]
        </div>
      ),
      value: 19,
      deltaPerc: -17.4,
    },
    {
      title: "Running TPL",
      description: "In use vehicles number and share respect to the fleet",
      value: 679,
      deltaPerc: 56.07,
      hideArrow: true,
    },
    {
      title: width > 1700 ? "Thermal energy" : "Thermal en.",
      description: "Residential heating [GWh/y] and share to city energy use",
      value: 480,
      deltaPerc: 4.04,
      hideArrow: true,
    },
  ];

  useEffect(async () => {
    // get homepage
    const res = await axios.get(`${API_URL}/homepage/`, tokenConfig());

    var data = [
      {
        x: res.data.emissions.map((obj) => obj.date),
        y: res.data.emissions.map((obj) => obj.no2),
        type: "scatter",
        line: {
          color: props.layout.color_footer_ctp,
          shape: "spline",
        },
        hovertemplate: "NO2: %{y:.2f} [μg/m3]",
        name: "",
      },
      {
        x: res.data.emissions.map((obj) => obj.date),
        y: res.data.emissions.map((obj) => obj.pm10),
        type: "scatter",
        line: {
          color: props.layout.yellow_detail_ctp,
          shape: "spline",
        },
        hovertemplate: "PM10: %{y:.2f} [μg/m3]",
        name: "",
      },
    ];

    var layout = {
      hovermode: "x",
      margin: {
        t: 20,
        b: 30,
        l: 50,
        r: 50,
      },
      // legend: {
      //   orientation: "h",
      //   xanchor: "center",
      //   x: 0.5,
      // },
      showlegend: false,
    };

    Plotly.newPlot("no2_pm10", data, layout, { ...plotlyConfig });

    // pm10 and no2 per inhabitants and surface
    var data = [
      {
        x: res.data.emissions.map((obj) => obj.date),
        y: res.data.emissions.map((obj) => obj.no2_area),
        type: "scatter",
        line: {
          color: props.layout.color_footer_ctp,
          shape: "spline",
        },
        hovertemplate: "NO2 area: %{y:.2f} [μg/m3/km2]",
        name: "",
      },
      {
        x: res.data.emissions.map((obj) => obj.date),
        y: res.data.emissions.map((obj) => obj.no2_people),
        type: "scatter",
        line: {
          color: props.layout.yellow_detail_ctp,
          shape: "spline",
        },
        hovertemplate: "NO2 inhabitants: %{y:.2f} [μg/m3/1000 persone]",
        name: "",
      },
      {
        x: res.data.emissions.map((obj) => obj.date),
        y: res.data.emissions.map((obj) => obj.pm10_area),
        type: "scatter",
        line: {
          color: props.layout.color_sidebar_ctp,
          shape: "spline",
        },
        hovertemplate: "PM10 area: %{y:.2f} [μg/m3/km2]",
        name: "",
      },
      {
        x: res.data.emissions.map((obj) => obj.date),
        y: res.data.emissions.map((obj) => obj.pm10_people),
        type: "scatter",
        line: {
          color: "#C0C0C0",
          shape: "spline",
        },
        hovertemplate: "PM10 inhabitants: %{y:.2f} [μg/m3/1000 persone]",
        name: "",
      },
    ];

    var layout = {
      hovermode: "x",
      margin: {
        t: 40,
        b: 30,
        l: 50,
        r: 50,
      },
      // legend: {
      //   orientation: "h",
      //   xanchor: "center",
      //   x: 0.5,
      // },
      showlegend: false,
    };

    Plotly.newPlot("no2_pm10_in_ar", data, layout, { ...plotlyConfig });

    // sector energy balance
    var data = [
      {
        values: consumiPerSettore2020.map((obj) => obj.contributo),
        labels: consumiPerSettore2020.map((obj) => obj.settore),
        type: "pie",
        marker: {
          colors: [
            props.layout.yellow_detail_ctp,
            props.layout.color_footer_ctp,
            props.layout.color_sidebar_ctp,
            props.layout.color_main_content_background_ctp,
          ],
        },
        textinfo: "label+percent",
        automargin: true,
      },
    ];

    var layout = {
      margin: {
        t: 35,
        b: 10,
        l: 35,
        r: 35,
      },
      showlegend: false,
    };

    Plotly.newPlot("sectorEnergyBalance", data, layout, { ...plotlyConfig });

    // commodity energy usage
    var data = [
      {
        values: consumiPerCommodity2020.map((obj) => obj.contributo),
        labels: consumiPerCommodity2020.map((obj) => obj.settore),
        type: "pie",
        marker: {
          colors: [
            props.layout.yellow_detail_ctp,
            props.layout.color_footer_ctp,
            props.layout.color_sidebar_ctp,
            props.layout.color_main_content_background_ctp,
            "#4CAFCA",
          ],
        },
        textinfo: "none",
        // automargin: true,
        hole: 0.6,
      },
    ];

    var layout = {
      margin: {
        t: 20,
        b: 10,
        l: 20,
        r: 20,
      },
      legend: {
        orientation: "h",
        xanchor: "center",
        x: 0.5,
      },
      showlegend: true,
    };

    Plotly.newPlot("commodityEnergyUsage", data, layout, { ...plotlyConfig });
  }, []);

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#E4E6E8",
        height: getHeightWithFooterWONavbar(
          props.pages,
          height,
          props.layout.subnavbar_height_empty_ctp,
          props.layout.subnavbar_height_not_empty_ctp
        ),
        paddingLeft: props.layout.width_sidebar_ctp,
        paddingBottom: props.layout.footer_height_ctp,
        overflow: "auto",
      }}
    >
      {/* workspace without padding */}
      <div
        style={{
          height: "100%",
          width: "100%",
          padding: 10,
        }}
      >
        {/* workspace */}
        <div
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          {/* cards */}
          <Row style={{ margin: 0 }}>
            {cardsContent.map((card) => {
              return (
                <Col
                  lg="3"
                  md="6"
                  sm="12"
                  style={{
                    padding: 10,
                    height: height * 0.155,
                  }}
                >
                  <div style={props.layout.generic_card_ctp}>
                    <Row
                      style={{
                        margin: 0,
                        height: "100%",
                      }}
                    >
                      <Col xs="7" style={{ padding: 0 }}>
                        <TitleCard title={card.title}></TitleCard>

                        {height < 900 || width < 1400 ? null : (
                          <div
                            style={{
                              padding: 15,
                              fontSize: 15,
                              fontWeight: 200,
                            }}
                          >
                            {card.description}
                          </div>
                        )}
                      </Col>
                      <Col xs="5" style={{ paddingRight: 20 }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <div>
                            <div
                              style={{
                                fontFamily: "Montserrat",
                                fontWeight: "bold",
                                fontSize: width > 1500 ? 38 : 31,
                                color: props.layout.color_footer_ctp,
                                textAlign: "end",
                              }}
                            >
                              {card.round === true
                                ? card.value.toFixed(2)
                                : card.value}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "end",
                                fontFamily: "Montserrat",
                                fontWeight: 500,
                                fontSize: width > 1500 ? 23 : 18,
                                color: props.layout.color_footer_ctp,
                              }}
                            >
                              {card.hideArrow ===
                              true ? null : card.deltaPerc >= 0 ? (
                                <FaArrowUp
                                  style={{ marginRight: 10, color: "#E44747" }}
                                ></FaArrowUp>
                              ) : (
                                <FaArrowDown
                                  style={{ marginRight: 10, color: "#41A14B" }}
                                ></FaArrowDown>
                              )}
                              {card.deltaPerc.toFixed(2)}%
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              );
            })}
          </Row>
          <Row style={{ margin: 0 }}>
            <Col
              lg="6"
              md="6"
              sm="12"
              style={{
                padding: 10,
                height: "100%",
              }}
            >
              <Row style={{ margin: 0, height: height * 0.335 }}>
                <Col xs="12" style={{ padding: 0, marginBottom: 10 }}>
                  <div
                    style={{
                      ...props.layout.generic_card_ctp,
                      display: "flex",
                      flexFlow: "column",
                      cursor: "pointer",
                      height: "100%",
                    }}
                  >
                    <TitleCard
                      title={
                        <div>
                          DATA: NO<sub>2</sub> and PM10
                        </div>
                      }
                    ></TitleCard>
                    <div
                      style={{
                        flex: "0.92 1 auto",
                        borderBottomLeftRadius:
                          props.layout.generic_card_ctp.borderRadius,
                        borderBottomRightRadius:
                          props.layout.generic_card_ctp.borderRadius,
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                        id="no2_pm10"
                      ></div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row style={{ margin: 0, height: height * 0.335 }}>
                <Col xs="12" style={{ padding: 0, marginTop: 10 }}>
                  <div
                    style={{
                      ...props.layout.generic_card_ctp,
                      display: "flex",
                      flexFlow: "column",
                      cursor: "pointer",
                      height: "100%",
                    }}
                  >
                    <TitleCard
                      title={
                        <div>
                          METRICS: NO<sub>2</sub> and PM10 per inhabitants and
                          area
                        </div>
                      }
                    ></TitleCard>
                    <div
                      style={{
                        flex: "0.92 1 auto",
                        borderBottomLeftRadius:
                          props.layout.generic_card_ctp.borderRadius,
                        borderBottomRightRadius:
                          props.layout.generic_card_ctp.borderRadius,
                      }}
                    >
                      <div
                        style={{ width: "100%", height: "100%" }}
                        id="no2_pm10_in_ar"
                      ></div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col
              lg="3"
              md="6"
              sm="12"
              style={{
                padding: 10,
                height: "100%",
              }}
            >
              <Row style={{ margin: 0, height: height * 0.335 }}>
                <Col xs="12" style={{ padding: 0, marginBottom: 10 }}>
                  <div
                    style={{
                      ...props.layout.generic_card_ctp,
                      display: "flex",
                      flexFlow: "column",
                      cursor: "pointer",
                      height: "100%",
                    }}
                    onClick={() => {
                      history.push("/ctp/dashboard/energy_balance");
                    }}
                  >
                    <TitleCard
                      title={
                        width > 1750
                          ? "Energy Balance per sector 2020"
                          : "En. Bal. per sector 2020"
                      }
                    ></TitleCard>
                    <div
                      style={{
                        flex: "0.92 1 auto",
                        borderBottomLeftRadius:
                          props.layout.generic_card_ctp.borderRadius,
                        borderBottomRightRadius:
                          props.layout.generic_card_ctp.borderRadius,
                      }}
                    >
                      <div
                        style={{ width: "100%", height: "100%" }}
                        id="sectorEnergyBalance"
                      ></div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row style={{ margin: 0, height: height * 0.335 }}>
                <Col xs="12" style={{ padding: 0, marginTop: 10 }}>
                  <div
                    style={{
                      ...props.layout.generic_card_ctp,
                      display: "flex",
                      flexFlow: "column",
                      cursor: "pointer",
                      height: "100%",
                    }}
                    onClick={() => {
                      history.push("/ctp/dashboard/energy_balance");
                    }}
                  >
                    <TitleCard
                      title={
                        width > 1980
                          ? "Final energy use by commodity 2020"
                          : "En. use by comm. 2020"
                      }
                    ></TitleCard>
                    <div
                      style={{
                        flex: "0.92 1 auto",
                        borderBottomLeftRadius:
                          props.layout.generic_card_ctp.borderRadius,
                        borderBottomRightRadius:
                          props.layout.generic_card_ctp.borderRadius,
                      }}
                    >
                      <div
                        style={{ width: "100%", height: "100%" }}
                        id="commodityEnergyUsage"
                      ></div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col
              lg="3"
              md="12"
              sm="12"
              style={{
                padding: 10,
                height: "100%",
              }}
            >
              <Row
                style={{
                  margin: 0,
                  height: height * 0.67 + 10,
                  overflow: "hidden",
                }}
              >
                <Col xs="12" style={{ padding: 0, marginBottom: 10 }}>
                  <div
                    style={{
                      ...props.layout.generic_card_ctp,
                      display: "flex",
                      flexFlow: "column",
                      cursor: "pointer",
                      height: "100%",
                    }}
                    onClick={() => history.push("/ctp/dashboard/city_explorer")}
                  >
                    <TitleCard title="CITY EXPLORER"></TitleCard>
                    <div
                      style={{
                        paddingTop: 5,
                        flex: "1 1 auto",
                        borderRadius:
                          props.layout.generic_card_ctp.borderRadius,
                        height: "auto",
                        width: "100%",
                      }}
                    >
                      <div
                        src={TorinoScreen}
                        style={{
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundImage: `url(${TorinoScreen})`,
                          height: "100%",
                          borderRadius:
                            props.layout.generic_card_ctp.borderRadius,
                        }}
                      ></div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(Content);
