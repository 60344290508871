import React from "react";
import { Route } from "react-router-dom";
import { makeStyles } from "@mui/styles";

// CTP import
import HomepageCTP from "../routes/Homepage/Homepage";
import CreateActionsCTP from "../routes/Actions/CreateActions/CreateActions";
import CreatePolicyCTP from "../routes/Policy/CreatePolicy/CreatePolicy";
import ComparePolicyCTP from "../routes/Policy/ComparePolicy/ComparePolicy";
import ModellingParametersCTP from "../routes/ModellingParameters/ModellingParameters";
import CityExplorerCTP from "../routes/CityExplorer/CityExplorer";
import DataCTP from "../routes/Data/Data";
import EnergyBalanceCTP from "../routes/EnergyBalance/EnergyBalance";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24,
  },
  appBarSpacer: {},
  content: {
    flexGrow: 1,
    height: "100vh",
    paddingLeft: `${drawerWidth}px`,
    transition: "1s",
  },
  contentShift: {
    paddingLeft: "70px",
  },
  container: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  paper: {
    padding: 10,
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

export const ContentWrapper = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Route
        path="/ctp/dashboard/homepage"
        render={(props) => (
          <HomepageCTP classes={classes} {...props}></HomepageCTP>
        )}
      ></Route>
      <Route
        path="/ctp/dashboard/create_actions"
        render={(props) => (
          <CreateActionsCTP classes={classes} {...props}></CreateActionsCTP>
        )}
      ></Route>
      <Route
        path="/ctp/dashboard/create_policy"
        render={(props) => (
          <CreatePolicyCTP classes={classes} {...props}></CreatePolicyCTP>
        )}
      ></Route>
      <Route
        path="/ctp/dashboard/compare_policy"
        render={(props) => (
          <ComparePolicyCTP classes={classes} {...props}></ComparePolicyCTP>
        )}
      ></Route>
      <Route
        path="/ctp/dashboard/modelling_parameters"
        render={(props) => (
          <ModellingParametersCTP
            classes={classes}
            {...props}
          ></ModellingParametersCTP>
        )}
      ></Route>
      <Route
        path="/ctp/dashboard/energy_balance"
        render={(props) => (
          <EnergyBalanceCTP classes={classes} {...props}></EnergyBalanceCTP>
        )}
      ></Route>
      <Route
        path="/ctp/dashboard/city_explorer"
        render={(props) => (
          <CityExplorerCTP classes={classes} {...props}></CityExplorerCTP>
        )}
      ></Route>
      <Route
        path="/ctp/dashboard/data"
        render={(props) => <DataCTP classes={classes} {...props}></DataCTP>}
      ></Route>
    </React.Fragment>
  );
};

export default ContentWrapper;
