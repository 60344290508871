import React from "react";
import * as Cesium from "cesium";
import "./BuildingMap.css";
import Map3DRoadNetwork from "./Map3DRoadNetwork.tsx";


Cesium.Ion.defaultAccessToken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIyNTE4YTU4MS0xZDIyLTRhMmEtYWM2Yy1hZDYxNjI3MGNiYzgiLCJpZCI6MTIyNTI5LCJpYXQiOjE2NzQ4OTU4Nzd9.a65a_dI7VQTEA1_OW1LhDqNwQ15bfyE3wzPcQmG2eac";

function RoadNetwork() {
  return (
    <div style={{ display: "flex", width: "100%", height: "100%" }}>
      <div style={{ height: "100%", width: "100%" }}>
        <Map3DRoadNetwork />
      </div>
    </div>
  );
}

export default RoadNetwork;
