import React, { useState, useRef, useEffect } from "react";
import Map from "ol/Map";
import View from "ol/View";
import { fromLonLat } from "ol/proj";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { OSM, Vector as VectorSource, TileWMS } from "ol/source";
import GeoJSON from "ol/format/GeoJSON.js";
import { Fill, Stroke, Style } from "ol/style.js";
import { Select } from "ol/interaction.js";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import CheckboxRenderer from "./CheckboxRenderer";
import CheckboxHeader from "./CheckboxHeader";
import DrawControl from "./DrawControl";
import OLCesium from "olcs/OLCesium";
import olcsRasterSynchronizer from "olcs/RasterSynchronizer";
import * as Cesium from "cesium";
import VectorSynchronizer from "./VectorSynchronizer";

function SelectAreaTab(props) {
  const mapRef = useRef();

  const GeoJSONURL =
    "https://geoserver.est.polito.it/geoserver/citta/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=citta%3Abuildings_santa_rita&outputFormat=application%2Fjson&cql_filter=line_type IS NOT NULL";

  const [agGridData, setAgGridData] = useState([]);
  const [cabina, setCabina] = useState("buildings_santa_rita_cabina_primaria");
  const [map3D, setMap3D] = useState(null);
  const [map3DEnabled, setMap3DEnabled] = useState(false);
  const [filterChanged, setFilterChanged] = useState(0);

  const [clickHandler3D, setClickHandler3D] = useState();
  const clickHandler3DAction = (click) => {
    var selectedBuilding = map3D?.getCesiumScene().pick(click.position);

    if (Cesium.defined(selectedBuilding)) {
      const identifier = selectedBuilding.id.properties.identifier.valueOf();
      props.setSelectedFeautures([identifier]);
    }
  };
  const [ctrlClickHandler3D, setCtrlClickHandler3D] = useState();
  const ctrlClickHandler3DAction = (click) => {
    var selectedBuilding = map3D?.getCesiumScene().pick(click.position);

    if (Cesium.defined(selectedBuilding)) {
      const identifier = selectedBuilding.id.properties.identifier.valueOf();
      if (props.selectedFeatures.includes(identifier) === false) {
        props.setSelectedFeautures([...props.selectedFeatures, identifier]);
      } else {
        props.setSelectedFeautures(
          props.selectedFeatures.filter((f) => f !== identifier)
        );
      }
    }
  };

  const [view] = useState(
    new View({
      projection: "EPSG:3857",
      center: fromLonLat([7.646, 45.0445]),
      zoom: 14.5,
    })
  );

  const getBuildingColor = (properties) => {
    if (cabina === "buildings_santa_rita_cabina_primaria") {
      switch (properties.line_type) {
        case "Line_BOST":
          return "#be1800";
        case "Line_RITA":
          return "#00d918";
        default:
          break;
      }
    } else {
      switch (properties.elt_sec_subst) {
        case "3608":
          return "#35e297";
        case "4752":
          return "#3791db";
        case "5591":
          return "#a4da74";
        case "5861":
          return "#4b88e4";
        case "S009":
          return "#d982d5";
        case "S020":
          return "#45dac6";
        case "S021":
          return "#ea837c";
        case "S023":
          return "#54d51c";
        case "S026":
          return "#de7fb0";
        case "S027":
          return "#d57a18";
        case "S028":
          return "#6ae053";
        case "S029":
          return "#7ae5a7";
        case "S030":
          return "#c82863";
        case "S032":
          return "#38e635";
        case "S033":
          return "#2f29d1";
        case "S034":
          return "#67dcbb";
        case "S035":
          return "#3ba1cd";
        case "S037":
          return "#1ce8e8";
        case "S038":
          return "#ca52ef";
        case "S039":
          return "#b980dc";
        case "S048":
          return "#dad12c";
        case "S049":
          return "#a0d34d";
        case "S050":
          return "#e827a4";
        case "S054":
          return "#e96213";
        case "S055":
          return "#efa111";
        case "S056":
          return "#7256f0";
        case "S057":
          return "#495ad8";
        case "S058":
          return "#d36749";
        case "S059":
          return "#dd62c4";
        case "S060":
          return "#6e42cf";
        case "S061":
          return "#d7ed0f";
        case "S062":
          return "#7fd38b";
        case "S063":
          return "#6b88d6";
        case "S064":
          return "#ca3253";
        case "S065":
          return "#b1de1e";
        case "S066":
          return "#813ece";
        case "S067":
          return "#2dcc5a";
        case "S068":
          return "#ddc35b";
        case "S069":
          return "#1dbad6";
        case "S071":
          return "#c650d3";
        default:
          break;
      }
    }
  };
  const getBuildingColor3D = (entity, selectedFeatures = []) => {
    if (selectedFeatures.includes(entity.properties.identifier.valueOf())) {
      return Cesium.Color.AQUA;
    }
    if (cabina === "buildings_santa_rita_cabina_primaria") {
      switch (entity.properties.line_type.toString()) {
        case "Line_BOST":
          return Cesium.Color.fromCssColorString("#be1800");
        case "Line_RITA":
          return Cesium.Color.fromCssColorString("#00d918");
        default:
          break;
      }
    } else {
      switch (entity.properties.elt_sec_subst.toString()) {
        case "3608":
          return Cesium.Color.fromCssColorString("#35e297");
        case "4752":
          return Cesium.Color.fromCssColorString("#3791db");
        case "5591":
          return Cesium.Color.fromCssColorString("#a4da74");
        case "5861":
          return Cesium.Color.fromCssColorString("#4b88e4");
        case "S009":
          return Cesium.Color.fromCssColorString("#d982d5");
        case "S020":
          return Cesium.Color.fromCssColorString("#45dac6");
        case "S021":
          return Cesium.Color.fromCssColorString("#ea837c");
        case "S023":
          return Cesium.Color.fromCssColorString("#54d51c");
        case "S026":
          return Cesium.Color.fromCssColorString("#de7fb0");
        case "S027":
          return Cesium.Color.fromCssColorString("#d57a18");
        case "S028":
          return Cesium.Color.fromCssColorString("#6ae053");
        case "S029":
          return Cesium.Color.fromCssColorString("#7ae5a7");
        case "S030":
          return Cesium.Color.fromCssColorString("#c82863");
        case "S032":
          return Cesium.Color.fromCssColorString("#38e635");
        case "S033":
          return Cesium.Color.fromCssColorString("#2f29d1");
        case "S034":
          return Cesium.Color.fromCssColorString("#67dcbb");
        case "S035":
          return Cesium.Color.fromCssColorString("#3ba1cd");
        case "S037":
          return Cesium.Color.fromCssColorString("#1ce8e8");
        case "S038":
          return Cesium.Color.fromCssColorString("#ca52ef");
        case "S039":
          return Cesium.Color.fromCssColorString("#b980dc");
        case "S048":
          return Cesium.Color.fromCssColorString("#dad12c");
        case "S049":
          return Cesium.Color.fromCssColorString("#a0d34d");
        case "S050":
          return Cesium.Color.fromCssColorString("#e827a4");
        case "S054":
          return Cesium.Color.fromCssColorString("#e96213");
        case "S055":
          return Cesium.Color.fromCssColorString("#efa111");
        case "S056":
          return Cesium.Color.fromCssColorString("#7256f0");
        case "S057":
          return Cesium.Color.fromCssColorString("#495ad8");
        case "S058":
          return Cesium.Color.fromCssColorString("#d36749");
        case "S059":
          return Cesium.Color.fromCssColorString("#dd62c4");
        case "S060":
          return Cesium.Color.fromCssColorString("#6e42cf");
        case "S061":
          return Cesium.Color.fromCssColorString("#d7ed0f");
        case "S062":
          return Cesium.Color.fromCssColorString("#7fd38b");
        case "S063":
          return Cesium.Color.fromCssColorString("#6b88d6");
        case "S064":
          return Cesium.Color.fromCssColorString("#ca3253");
        case "S065":
          return Cesium.Color.fromCssColorString("#b1de1e");
        case "S066":
          return Cesium.Color.fromCssColorString("#813ece");
        case "S067":
          return Cesium.Color.fromCssColorString("#2dcc5a");
        case "S068":
          return Cesium.Color.fromCssColorString("#ddc35b");
        case "S069":
          return Cesium.Color.fromCssColorString("#1dbad6");
        case "S071":
          return Cesium.Color.fromCssColorString("#c650d3");
        default:
          break;
      }
    }
  };

  const [features] = useState(
    new VectorSource({
      url: GeoJSONURL,
      format: new GeoJSON(),
    })
  );
  const [drawFeautures] = useState(new VectorSource({ wrapX: false }));
  const [select] = useState(
    new Select({
      style: new Style({
        fill: new Fill({
          color: "aqua",
        }),
        stroke: new Stroke({
          width: 0.5,
        }),
      }),
    })
  );

  const [buildingsLayer] = useState(
    new VectorLayer({
      source: features,
      style: (feauture) => {
        return new Style({
          stroke: new Stroke({
            width: 0.5,
          }),
          fill: new Fill({
            color: getBuildingColor(feauture.getProperties()),
          }),
        });
      },
    })
  );

  const [wmsLayerLines] = useState(
    new TileWMS({
      url: "https://geoserver.est.polito.it/geoserver/wms",
      params: {
        LAYERS: "citta:electricity_grid_lines",
        TILED: true,
      },
      serverType: "geoserver",
      transition: 0,
    })
  );

  const [map] = useState(
    new Map({
      target: null,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        new TileLayer({
          source: wmsLayerLines,
        }),
        buildingsLayer,
        new VectorLayer({
          source: drawFeautures,
        }),
      ],
      view: view,
      controls: [],
    })
  );

  useEffect(() => {
    map.setTarget(mapRef.current);
    map.addInteraction(select);
    if (props.read_only === true) {
      select.setActive(false); // manage
    }

    features.on("featuresloadend", (e) => {
      setAgGridData(e.features.map((f) => f.getProperties()));
    });
    select.on("select", (e) => {
      props.setSelectedFeautures(
        e.target
          .getFeatures()
          .getArray()
          .map((f) => f.get("identifier"))
      );
    });
    select.on("propertychange", (e) => {
      props.setSelectedFeautures(
        e.target
          .getFeatures()
          .getArray()
          .map((f) => f.get("identifier"))
      );
    });

    const drawControl = new DrawControl(
      drawFeautures,
      features,
      props.setSelectedFeautures
    );
    if (props.read_only === false) {
      map.addControl(drawControl);
    }
    const ol3d = new OLCesium({
      map: map,
      createSynchronizers: (map, scene, datasources) => {
        return [
          new VectorSynchronizer(map, scene, datasources, getBuildingColor3D),
          new olcsRasterSynchronizer(map, scene),
        ];
      },
    });
    setMap3D(ol3d);
    setClickHandler3D(
      new Cesium.ScreenSpaceEventHandler(ol3d.getCesiumScene().canvas)
    );
    setCtrlClickHandler3D(
      new Cesium.ScreenSpaceEventHandler(ol3d.getCesiumScene().canvas)
    );
  }, []);
  useEffect(() => {
    map3D?.setEnabled(map3DEnabled);
    if (props.read_only === false) {
      clickHandler3D?.setInputAction(
        clickHandler3DAction,
        Cesium.ScreenSpaceEventType.LEFT_CLICK
      );
      ctrlClickHandler3D?.setInputAction(
        ctrlClickHandler3DAction,
        Cesium.ScreenSpaceEventType.LEFT_CLICK,
        Cesium.KeyboardEventModifier.SHIFT
      );
    }
  }, [map3DEnabled]);

  useEffect(() => {
    select.getFeatures().clear();
    select
      .getFeatures()
      .extend(
        features
          .getFeatures()
          .filter((f) => props.selectedFeatures.includes(f.get("identifier")))
      );
    const datasource3D = map3D?.getDataSources().get(0);
    if (datasource3D !== undefined) {
      datasource3D.entities.values.forEach((entity) => {
        entity.polygon.material = getBuildingColor3D(
          entity,
          props.selectedFeatures
        );
      });
    }
    if (props.read_only === false) {
      clickHandler3D?.setInputAction(
        clickHandler3DAction,
        Cesium.ScreenSpaceEventType.LEFT_CLICK
      );
      ctrlClickHandler3D?.setInputAction(
        ctrlClickHandler3DAction,
        Cesium.ScreenSpaceEventType.LEFT_CLICK,
        Cesium.KeyboardEventModifier.SHIFT
      );
    }
  }, [props.selectedFeatures]);
  useEffect(() => {
    const datasource3D = map3D?.getDataSources().get(0);
    if (datasource3D !== undefined) {
      datasource3D.entities.values.forEach((entity) => {
        entity.polygon.material = getBuildingColor3D(
          entity,
          props.selectedFeatures
        );
      });
    }
    buildingsLayer.setStyle((feauture) => {
      return new Style({
        stroke: new Stroke({
          width: 0.5,
        }),
        fill: new Fill({
          color: getBuildingColor(feauture.getProperties()),
        }),
      });
    });
  }, [cabina]);

  return (
    <div className="row" style={{ height: "100%" }}>
      <div className="col-6 p-3">
        <div ref={mapRef} style={{ width: "100%", height: "100%" }}>
          <div
            style={{
              position: "absolute",
              zIndex: 99,
              left: "calc(50% - 140px)",
              marginTop: "0.5em",
              cursor: "pointer",
            }}
          >
            <button
              style={{
                backgroundColor: "white",
                border: "1px solid gray",
                borderRadius: 5,
              }}
              onClick={() => {
                setMap3DEnabled(!map3DEnabled);
              }}
            >
              {map3DEnabled === true ? "Change to 2D" : "Change to 3D"}
            </button>
          </div>
          <div
            style={{
              fontSize: 14,
              position: "absolute",
              top: "5.6em",
              left: "3.1em",
              backgroundColor: "white",
              borderRadius: 5,
              padding: 5,
              zIndex: 99,
              border: "1px solid gray",
            }}
          >
            <div
              className="form-check"
              style={{ cursor: "pointer" }}
              onClick={(e) => setCabina("buildings_santa_rita_cabina_primaria")}
            >
              <input
                className="form-check-input"
                type="checkbox"
                id="style-type"
                checked={cabina === "buildings_santa_rita_cabina_primaria"}
                style={{ cursor: "pointer" }}
              />
              <label className="form-check-label" style={{ cursor: "pointer" }}>
                Cabina primaria
              </label>
            </div>
            <div
              className="form-check"
              style={{ cursor: "pointer" }}
              onClick={(e) =>
                setCabina("buildings_santa_rita_cabina_secondaria")
              }
            >
              <input
                className="form-check-input"
                type="checkbox"
                checked={cabina === "buildings_santa_rita_cabina_secondaria"}
                id="style-producibility"
                style={{ cursor: "pointer" }}
              />
              <label className="form-check-label" style={{ cursor: "pointer" }}>
                Cabina secondaria
              </label>
            </div>
          </div>
          <div
            style={{
              fontSize: 14,
              position: "absolute",
              bottom: "5.6em",
              left: "3.1em",
              backgroundColor: "white",
              borderRadius: 5,
              padding: 5,
              zIndex: 99,
              border: "1px solid gray",
            }}
          >
            <img
              style={{
                imageRendering: "-webkit-optimize-contrast",
              }}
              src={wmsLayerLines.getLegendUrl(view.getResolution())}
              width="86px"
              height="40px"
            />
          </div>
        </div>
      </div>

      <div className="ag-theme-balham col-6 p-3" style={{}}>
        <AgGridReact
          rowData={agGridData}
          defaultColDef={{
            flex: 1,
            sortable: true,
            resizable: true,
            filter: true,
            filterParams: { buttons: ["reset"] },
          }}
          onFilterChanged={() => setFilterChanged(filterChanged + 1)}
          frameworkComponents={{
            checkboxRenderer: CheckboxRenderer,
            checkboxHeader: CheckboxHeader,
          }}
        >
          <AgGridColumn
            cellRenderer="checkboxRenderer"
            cellRendererParams={{
              read_only: props.read_only,
            }}
            headerComponent="checkboxHeader"
            headerComponentParams={{
              selectedFeatures: props.selectedFeatures,
              setSelectedFeautures: props.setSelectedFeautures,
              filterChanged: filterChanged,
              read_only: props.read_only,
            }}
            valueGetter={(e) =>
              props.selectedFeatures.includes(e.data.identifier)
            }
            field="selected"
            headerName=""
            maxWidth={30}
            minWidth={30}
            onCellValueChanged={(e) => {
              if (e.oldValue === false) {
                props.setSelectedFeautures([
                  ...props.selectedFeatures,
                  e.data.identifier,
                ]);
              } else {
                props.setSelectedFeautures(
                  props.selectedFeatures.filter((f) => f !== e.data.identifier)
                );
              }
            }}
          />
          <AgGridColumn field="identifier" />
          <AgGridColumn field="description" />
          <AgGridColumn field="area" />
          <AgGridColumn field="riscaldamento" />
          <AgGridColumn field="epv" />
        </AgGridReact>
      </div>
    </div>
  );
}

export default SelectAreaTab;
