import React, { useState, useEffect } from "react";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import CheckboxHeader from "./CheckboxHeader";
import axios from "axios";
import Map3D from './Map3D.tsx'

function SelectAreaTab(props) {
  const [agGridData, setAgGridData] = useState([]);
  const [agGridAPI, setAgGridAPI] = useState();
  const [filterChanged, setFilterChanged] = useState(0);
  const [objSelectedFeatures, setObjSelectedFeatures] = useState(props.selectedFeatures.reduce((obj, f) => {
    obj[f] = true;
    return obj
  }, {}))


  useEffect(() => {
    axios.get(
      "https://geoserver.est.polito.it/geoserver/citta/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=citta%3Abuildings&outputFormat=application%2Fjson&propertyName=identifier,type,description,elevation,area,epv,riscaldamento,age,enthermgr,elt_sec_subst,line_type"
    )
      .then((response) => response.data)
      .then((data) => {
        setAgGridData(data.features.map((f) => f.properties));
      });
  }, []);


  useEffect(() => {
    const selectedIDs = Object.entries(objSelectedFeatures).filter(([feature, selected]) => (selected)).map(([feature, selected]) => parseInt(feature))
    props.setSelectedFeatures(selectedIDs)
  }, [objSelectedFeatures])


  return (
    <div className="row" style={{ height: "100%" }}>
      <div className="col-6 p-3" style={{ height: '100%' }}>
        <Map3D
          selectedFeaturesOBJ={objSelectedFeatures}
          setSelectedFeaturesOBJ={setObjSelectedFeatures}
        />
      </div>
      <div className="ag-theme-balham col-6 p-3" style={{}}>
        <AgGridReact
          rowData={agGridData}
          defaultColDef={{
            flex: 1,
            sortable: true,
            resizable: true,
            filter: true,
            filterParams: { buttons: ["reset"] },
          }}
          onFilterChanged={() => setFilterChanged(filterChanged + 1)}
          frameworkComponents={{
            checkboxHeader: CheckboxHeader,
          }}
        >
          <AgGridColumn
            headerComponent="checkboxHeader"
            headerComponentParams={{
              selectedFeatures: objSelectedFeatures,
              setSelectedFeautures: setObjSelectedFeatures,
              filterChanged: filterChanged,
            }}
            valueFormatter={(params) => (objSelectedFeatures[params.data.identifier] === true ? "✅" : "⬜")}
            field="selected"
            headerName=""
            maxWidth={37}
            minWidth={37}
            onCellClicked={(e) => {
              setObjSelectedFeatures(oldSelectedFeature => (
                {
                  ...oldSelectedFeature,
                  [e.data.identifier]: !(objSelectedFeatures[e.data.identifier] ?? false)
                }
              ))
            }}
          />
          <AgGridColumn
            field="identifier"
          />
          <AgGridColumn field="description" />
          <AgGridColumn field="area" />
          <AgGridColumn field="riscaldamento" />
          <AgGridColumn field="epv" />
        </AgGridReact>
      </div>
    </div>
  );
}

export default SelectAreaTab;
