import React, { useState, useEffect } from "react";
import { useWindowDimensions } from "../../../utils/useWindowDimensions";
import { connect } from "react-redux";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import { getFontSize } from "../../../utils/getFontSize";
import { fig } from "./fig";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import Plotly from "plotly.js-dist";
import { usePlotlyConfig } from "../../../utils/usePlotlyConfig";

export const Center = (props) => {
  const { width, height } = useWindowDimensions();
  const padding = height * 0.005;
  const plotlyConfig = usePlotlyConfig();
  const [activeTab, setActiveTab] = useState("1");

  const [rowData] = useState([
    {
      commodity: "Bio-electricity",
      flow: "Production",
      value: 1.3367499999999999,
      sort: 0,
    },
    {
      commodity: "Bio-fuels",
      flow: "Production",
      value: 0.19,
      sort: 0,
    },
    {
      commodity: "Gas",
      flow: "Production",
      value: 0.7668570855028032,
      sort: 0,
    },
    {
      commodity: "Oil",
      flow: "Production",
      value: 1.3680450000000002,
      sort: 0,
    },
    {
      commodity: "Renewables",
      flow: "Production",
      value: 4.983280062541262,
      sort: 0,
    },
    {
      commodity: "Solid fuels",
      flow: "Production",
      value: 0.08989414409999999,
      sort: 0,
    },
    {
      commodity: "TOTAL",
      flow: "Production",
      value: 8.734826292144065,
      sort: 0,
    },
    {
      commodity: "Bio-fuels",
      flow: "Import",
      value: 0.2172197813,
      sort: 1,
    },
    {
      commodity: "Electricity",
      flow: "Import",
      value: 2.84614,
      sort: 1,
    },
    {
      commodity: "Gas",
      flow: "Import",
      value: 14.193838569833407,
      sort: 1,
    },
    {
      commodity: "Oil",
      flow: "Import",
      value: 15.421684108100003,
      sort: 1,
    },
    {
      commodity: "Solid fuels",
      flow: "Import",
      value: 1.4738179407499998,
      sort: 1,
    },
    {
      commodity: "TOTAL",
      flow: "Import",
      value: 34.15270039998341,
      sort: 1,
    },
    {
      commodity: "Bio-fuels",
      flow: "Export",
      value: 0.0723327564,
      sort: 2,
    },
    {
      commodity: "Electricity",
      flow: "Export",
      value: 0.20042,
      sort: 2,
    },
    {
      commodity: "Gas",
      flow: "Export",
      value: 0.1478034707952141,
      sort: 2,
    },
    {
      commodity: "Oil",
      flow: "Export",
      value: 6.50130663,
      sort: 2,
    },
    {
      commodity: "Solid fuels",
      flow: "Export",
      value: 0.05181465169999999,
      sort: 2,
    },
    {
      commodity: "TOTAL",
      flow: "Export",
      value: 6.973677508895214,
      sort: 2,
    },
    {
      commodity: "Bio-fuels",
      flow: "Stock change",
      value: -0.0016217312999999853,
      sort: 3,
    },
    {
      commodity: "Gas",
      flow: "Stock change",
      value: -5.777500928186427,
      sort: 3,
    },
    {
      commodity: "Oil",
      flow: "Stock change",
      value: -0.985021105699996,
      sort: 3,
    },
    {
      commodity: "Solid fuels",
      flow: "Stock change",
      value: 0.10707802895000004,
      sort: 3,
    },
    {
      commodity: "TOTAL",
      flow: "Stock change",
      value: -6.657065736236423,
      sort: 3,
    },
    {
      commodity: "Bio-electricity",
      flow: "Gross inland consumption",
      value: 1.3367499999999999,
      sort: 4,
    },
    {
      commodity: "Bio-fuels",
      flow: "Gross inland consumption",
      value: 0.3365087562,
      sort: 4,
    },
    {
      commodity: "Electricity",
      flow: "Gross inland consumption",
      value: 2.64572,
      sort: 4,
    },
    {
      commodity: "Gas",
      flow: "Gross inland consumption",
      value: 20.59055894655404,
      sort: 4,
    },
    {
      commodity: "Oil",
      flow: "Gross inland consumption",
      value: 11.273443583799999,
      sort: 4,
    },
    {
      commodity: "Renewables",
      flow: "Gross inland consumption",
      value: 4.983280062541262,
      sort: 4,
    },
    {
      commodity: "Solid fuels",
      flow: "Gross inland consumption",
      value: 1.4048194042,
      sort: 4,
    },
    {
      commodity: "TOTAL",
      flow: "Gross inland consumption",
      value: 42.5710807532953,
      sort: 4,
    },
    {
      commodity: "Electricity",
      flow: "Consumption and losses",
      value: -9.08351019806074,
      sort: 5,
    },
    {
      commodity: "Gas",
      flow: "Consumption and losses",
      value: -0.5918283193236817,
      sort: 5,
    },
    {
      commodity: "Oil",
      flow: "Consumption and losses",
      value: -0.77425,
      sort: 5,
    },
    {
      commodity: "TOTAL",
      flow: "Consumption and losses",
      value: -10.449588517384422,
      sort: 5,
    },
    {
      commodity: "Bio-electricity",
      flow: "Electricity production",
      value: -1.3367499999999999,
      sort: 6,
    },
    {
      commodity: "Electricity",
      flow: "Electricity production",
      value: 12.585717865839456,
      sort: 6,
    },
    {
      commodity: "Gas",
      flow: "Electricity production",
      value: -5.28320145712912,
      sort: 6,
    },
    {
      commodity: "Oil",
      flow: "Electricity production",
      value: -0.12817,
      sort: 6,
    },
    {
      commodity: "Renewables",
      flow: "Electricity production",
      value: -4.983280062541262,
      sort: 6,
    },
    {
      commodity: "Solid fuels",
      flow: "Electricity production",
      value: -0.8543163461690737,
      sort: 6,
    },
    {
      commodity: "Bio-fuels",
      flow: "Total final use",
      value: 0.3365087562,
      sort: 7,
    },
    {
      commodity: "Electricity",
      flow: "Total final use",
      value: 6.147927667778716,
      sort: 7,
    },
    {
      commodity: "Gas",
      flow: "Total final use",
      value: 14.715529170101238,
      sort: 7,
    },
    {
      commodity: "Oil",
      flow: "Total final use",
      value: 10.3710235838,
      sort: 7,
    },
    {
      commodity: "Solid fuels",
      flow: "Total final use",
      value: 0.5505030580309263,
      sort: 7,
    },
    {
      commodity: "TOTAL",
      flow: "Total final use",
      value: 32.12149223591088,
      sort: 7,
    },
    {
      commodity: "Gas",
      flow: "Industry",
      value: 3.0440613611371106,
      sort: 8,
    },
    {
      commodity: "Oil",
      flow: "Industry",
      value: 0.6429536110516226,
      sort: 8,
    },
    {
      commodity: "Solid fuels",
      flow: "Industry",
      value: 0.5505030580309263,
      sort: 8,
    },
    {
      commodity: "TOTAL",
      flow: "Industry",
      value: 4.237518030219659,
      sort: 8,
    },
    {
      commodity: "Electricity",
      flow: "Industry",
      value: 2.6838310705056823,
      sort: 8,
    },
    {
      commodity: "Bio-fuels",
      flow: "Transport",
      value: 0.3365087562,
      sort: 9,
    },
    {
      commodity: "Gas",
      flow: "Transport",
      value: 0.24175,
      sort: 9,
    },
    {
      commodity: "Oil",
      flow: "Transport",
      value: 6.481957542687599,
      sort: 9,
    },
    {
      commodity: "TOTAL",
      flow: "Transport",
      value: 7.060216298887599,
      sort: 9,
    },
    {
      commodity: "Electricity",
      flow: "Transport",
      value: 0.2408466391135735,
      sort: 9,
    },
    {
      commodity: "Gas",
      flow: "Civil and Agriculture",
      value: 11.266467808964128,
      sort: 10,
    },
    {
      commodity: "Oil",
      flow: "Civil and Agriculture",
      value: 1.1791310900607783,
      sort: 10,
    },
    {
      commodity: "TOTAL",
      flow: "Civil and Agriculture",
      value: 12.445598899024905,
      sort: 10,
    },
    {
      commodity: "Electricity",
      flow: "Civil and Agriculture",
      value: 3.2232499581594602,
      sort: 10,
    },
    {
      commodity: "Gas",
      flow: "Non energy uses",
      value: 0.16325,
      sort: 11,
    },
    {
      commodity: "Oil",
      flow: "Non energy uses",
      value: 1.35987261,
      sort: 11,
    },
    {
      commodity: "TOTAL",
      flow: "Non energy uses",
      value: 1.5231226100000002,
      sort: 11,
    },
    {
      commodity: "Oil",
      flow: "Bunkers",
      value: 0.7071087300000001,
      sort: 12,
    },
    {
      commodity: "TOTAL",
      flow: "Bunkers",
      value: 0.7071087300000001,
      sort: 12,
    },
  ]);

  useEffect(() => {
    var data = fig.data;

    var layout = {
      title: "",
      font: {
        size: 10,
      },
    };
    var config = {
      ...plotlyConfig,
      toImageButtonOptions: {
        format: "png",
        filename: "custom_image",
        height: 550,
        width: 1600,
        scale: 2,
      },
    };
    Plotly.newPlot("sankey", data, layout, {
      ...config,
      modeBarButtonsToRemove: ["select2d", "lasso2d"],
    });
  }, []);

  return (
    <Col
      lg="8"
      md="12"
      style={{
        height: "100%",
        padding: padding,
      }}
    >
      <div
        style={{
          height: "100%",
          backgroundColor: "#E3F3F9",
          padding: 15,
          borderRadius: 7,
        }}
      >
        <Nav tabs style={{ fontSize: getFontSize("medium") }}>
          <NavItem>
            <NavLink
              className={activeTab === "1" ? "active" : ""}
              onClick={() => setActiveTab("1")}
            >
              Sankey
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "2" ? "active" : ""}
              onClick={() => setActiveTab("2")}
            >
              Data
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <div
              style={{
                height: "100%",
                backgroundColor: "white",
                borderRadius: 7,
              }}
            >
              <Row style={{ height: "100%", margin: 0 }}>
                <Col
                  md="12"
                  sm="12"
                  style={{
                    padding: 0,
                    height: width > 5000 ? height * 0.6905 : height * 0.6972,
                  }}
                >
                  <div
                    style={{ width: "100%", height: "90%" }}
                    id="sankey"
                  ></div>
                  <ul class="legend" style={{ listStyle: "none" }}>
                    <li
                      style={{
                        float: "left",
                        marginRight: "3rem",
                        marginLeft: "3.45rem",
                      }}
                    >
                      <span
                        style={{
                          border: "1px solid #ccc",
                          float: "left",
                          marginRight: "3rem",
                          width: "12px",
                          height: "12px",
                          margin: "6px 8px 4px",
                          backgroundColor: "rgba(176, 179, 182, 0.8)",
                        }}
                      ></span>
                      Solid fuels
                    </li>
                    <li
                      style={{
                        float: "left",
                        marginRight: "3rem",
                      }}
                    >
                      <span
                        style={{
                          border: "1px solid #ccc",
                          float: "left",
                          marginRight: "3rem",
                          width: "12px",
                          height: "12px",
                          margin: "6px 8px 4px",
                          backgroundColor: "rgba(0, 95, 147, 0.8)",
                        }}
                      ></span>
                      Gas
                    </li>
                    <li
                      style={{
                        float: "left",
                        marginRight: "3rem",
                      }}
                    >
                      <span
                        style={{
                          border: "1px solid #ccc",
                          float: "left",
                          marginRight: "3rem",
                          width: "12px",
                          height: "12px",
                          margin: "6px 8px 4px",
                          backgroundColor: "rgba(110, 98, 151, 0.8)",
                        }}
                      ></span>
                      Oil
                    </li>
                    <li
                      style={{
                        float: "left",
                        marginRight: "3rem",
                      }}
                    >
                      <span
                        style={{
                          border: "1px solid #ccc",
                          float: "left",
                          marginRight: "3rem",
                          width: "12px",
                          height: "12px",
                          margin: "6px 8px 4px",
                          backgroundColor: "rgba(0, 186, 218, 0.8)",
                        }}
                      ></span>
                      Electricity
                    </li>
                    <li
                      style={{
                        float: "left",
                        marginRight: "3rem",
                      }}
                    >
                      <span
                        style={{
                          border: "1px solid #ccc",
                          float: "left",
                          marginRight: "3rem",
                          width: "12px",
                          height: "12px",
                          margin: "6px 8px 4px",
                          backgroundColor: "rgba(21, 153, 21, 0.8)",
                        }}
                      ></span>
                      Renewables
                    </li>
                    <li
                      style={{
                        float: "left",
                        marginRight: "3rem",
                      }}
                    >
                      <span
                        style={{
                          border: "1px solid #ccc",
                          float: "left",
                          marginRight: "3rem",
                          width: "12px",
                          height: "12px",
                          margin: "6px 8px 4px",
                          backgroundColor: "rgba(0, 255, 72, 0.8)",
                        }}
                      ></span>
                      Bio-electricity
                    </li>
                    <li
                      style={{
                        float: "left",
                        marginRight: "3rem",
                      }}
                    >
                      <span
                        style={{
                          border: "1px solid #ccc",
                          float: "left",
                          marginRight: "3rem",
                          width: "12px",
                          height: "12px",
                          margin: "6px 8px 4px",
                          backgroundColor: "rgba(220, 255, 0, 0.8)",
                        }}
                      ></span>
                      Bio-fuel
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
          </TabPane>
          <TabPane tabId="2">
            <div
              style={{
                width: "100%",
                height: "100%",
                padding: "40px 40px",
              }}
            >
              <Row style={{ height: "100%", margin: 0 }}>
                <Col
                  md="12"
                  sm="12"
                  style={{
                    padding: 0,
                    height: width > 5000 ? height * 0.6905 : height * 0.6972,
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                    }}
                    className="ag-theme-balham"
                  >
                    <AgGridReact
                      rowData={rowData}
                      gridOptions={{
                        getRowClass: (params) => {
                          if (
                            params.node.key === "Gross inland consumption" ||
                            params.node.key === "Total final use"
                          ) {
                            return "bold-row";
                          }
                        },
                      }}
                      onFirstDataRendered={function (params) {
                        params.columnApi.autoSizeColumn("ag-Grid-AutoColumn");
                      }}
                      autoGroupColumnDef={{
                        minWidth: 200,
                        pinned: "left",
                        lockPinned: true,
                      }}
                      defaultColDef={{
                        resizable: true,
                        flex: 1,
                        valueFormatter: function (params) {
                          if (typeof params.value === "number") {
                            var num =
                              Math.round(
                                (params.value + Number.EPSILON) * 100000
                              ) / 100000;
                            num = num.toLocaleString("en-US");
                            return num;
                          } else {
                            return params.value;
                          }
                        },
                        cellRendererParams: {
                          suppressCount: true,
                        },
                      }}
                      pivotMode={true}
                      pivotHeaderHeight={0}
                      domLayout="autoHeight"
                    >
                      <AgGridColumn
                        headerName="Commodity"
                        field="commodity"
                        pivot={true}
                      />
                      <AgGridColumn
                        headerName="Flow"
                        field="flow"
                        rowGroup={true}
                      />
                      <AgGridColumn
                        headerName="Value"
                        field="value"
                        aggFunc={"sum"}
                      />
                    </AgGridReact>
                  </div>
                </Col>
              </Row>
            </div>
          </TabPane>
        </TabContent>
      </div>
    </Col>
  );
};

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(Center);
