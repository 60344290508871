import { Control } from "ol/control.js";
import Polygon from "./polygon.png"
import Circle from "./circle.png"
import { Draw, Select } from 'ol/interaction.js';
import { fromCircle } from 'ol/geom/Polygon'
import * as turf from '@turf/turf'

export default class DrawControl extends Control {
    convertGeometryToTurf(feature) {
        const geometry = feature.getGeometry().clone()
        switch (geometry.getType()) {
            case "Polygon":
                return turf.polygon(geometry.getCoordinates())
            case "MultiPolygon":
                return turf.multiPolygon(geometry.getCoordinates())
            case "Circle":
                return turf.polygon(fromCircle(geometry, 64).getCoordinates())
            default:
                break;
        }
    }

    drawAddToMap() {
        this.getMap().addInteraction(this.draw)
        const select = this.getMap().getInteractions().getArray().find(i => i instanceof Select)
        select.setActive(false)
        this.draw.on("drawstart", (e) => this.drawLayer.clear())
        this.draw.on("drawend", (drawnGeometry) => {
            const turfDrawnGeometry = this.convertGeometryToTurf(drawnGeometry.feature)
            this.setSelectedFeautures(
                this.features.getFeatures()
                    .filter((feature) => {
                        const turfFeature = this.convertGeometryToTurf(feature)
                        return turf.intersect(turfFeature, turfDrawnGeometry) !== null
                    }).map(f => f.get("identifier"))
            )
        })
    }
    drawRemoveFromMap() {
        this.drawLayer.clear()
        if (this.draw !== undefined)
            this.getMap().removeInteraction(this.draw)
        const select = this.getMap().getInteractions().getArray().find(i => i instanceof Select)
        select.setActive(true)
    }

    constructor(drawLayer, features, setSelectedFeautures) {
        const element = document.createElement("div");
        element.className = "ol-unselectable ol-control";
        element.style.top = "5em";
        element.style.left = ".5em";
        element.style.background = "rgba(0,0,0,0)";

        const polygonDiv = document.createElement('div')
        polygonDiv.style.backgroundColor = 'white'
        polygonDiv.style.cursor = "pointer"
        polygonDiv.style.width = '30px'
        polygonDiv.style.height = '30px'
        polygonDiv.style.border = '1px solid gray'
        polygonDiv.style.borderRadius = '5px'
        const polygon = document.createElement('img')
        polygon.src = Polygon
        polygon.style.width = '100%'
        polygon.style.height = '100%'
        polygon.style.padding = '5px'
        polygonDiv.appendChild(polygon)

        const circleDiv = document.createElement('div')
        circleDiv.style.backgroundColor = 'white'
        circleDiv.style.cursor = "pointer"
        circleDiv.style.width = '30px'
        circleDiv.style.height = '30px'
        circleDiv.style.border = '1px solid gray'
        circleDiv.style.borderRadius = '5px'
        const circle = document.createElement('img')
        circle.src = Circle
        circle.style.width = '100%'
        circle.style.height = '100%'
        circle.style.padding = '5px'
        circleDiv.append(circle)

        element.append(polygonDiv, circleDiv)


        super({
            element: element,
        });
        this.drawLayer = drawLayer
        this.features = features
        this.setSelectedFeautures = setSelectedFeautures


        polygonDiv.addEventListener("click", (e) => {
            const active = polygonDiv.style.backgroundColor !== "white"
            this.drawRemoveFromMap()
            if (active) {
                polygonDiv.style.backgroundColor = "white"
            } else {
                polygonDiv.style.backgroundColor = "aqua"
                circleDiv.style.backgroundColor = "white"
                this.draw = new Draw({
                    source: drawLayer,
                    type: 'Polygon'
                })
                this.drawAddToMap()
            }
        })
        circleDiv.addEventListener("click", (e) => {
            const active = circleDiv.style.backgroundColor !== "white"
            this.drawRemoveFromMap()
            if (active) {
                circleDiv.style.backgroundColor = "white"
            } else {
                circleDiv.style.backgroundColor = "aqua"
                polygonDiv.style.backgroundColor = "white"
                this.draw = new Draw({
                    source: drawLayer,
                    type: 'Circle'
                })
                this.drawAddToMap()
            }
        })

    }

}
