import React from "react";
import { connect } from "react-redux";
import { useWindowDimensions } from "../../../utils/useWindowDimensions";

function TitleCard(props) {
  const { width, height } = useWindowDimensions();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        style={{
          borderBottom: `${
            props.customBorderHeight === undefined
              ? 3
              : props.customBorderHeight
          }px solid ${props.layout.yellow_detail_ctp}`,
          width: "fit-content",
        }}
      >
        <div
          style={{
            padding: "11px 15px 2px 15px",
            fontFamily: "Montserrat",
            fontWeight: "bold",
            color: props.layout.color_footer_ctp,
            fontSize:
              props.customTitleSize === undefined
                ? width > 1450
                  ? 21
                  : 19
                : width > 1450
                ? props.customTitleSize.large
                : props.customTitleSize.small,
          }}
        >
          {props.title}
        </div>
      </div>
      <div style={{ paddingRight: "15px" }}>
        {props.icon !== undefined ? props.icon : null}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(TitleCard);
