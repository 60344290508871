import React from "react";
import { useWindowDimensions } from "../../../utils/useWindowDimensions";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import LeftColumn from "./LeftColumn";
import Center from "./Center";
import RightColumn from "./RightColumn";

export const MainContent = (props) => {
  const { width, height } = useWindowDimensions();
  const padding = height * 0.005;

  return (
    <Row style={{ margin: 0, padding: padding }}>
      <LeftColumn padding={padding}></LeftColumn>
      <Center></Center>
      <RightColumn></RightColumn>
    </Row>
  );
};

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(MainContent);
