import React, { useRef, useEffect, useState } from "react";
import * as Cesium from "cesium";
import "cesium/Build/Cesium/Widgets/widgets.css";
import { API_URL } from "../../../actions/types";
import { Checkbox, FormControlLabel } from "@mui/material";

export default function Map3DGeneration({ archiSelectedIDs, nodiSelectedIDs }) {
  const container = useRef<HTMLDivElement>(null);
  const viewer = useRef<Cesium.Viewer>();
  const tilesetImpiantiDiGenerazione = useRef<Cesium.Cesium3DTileset>();
  const tilesetIrenArchi = useRef<Cesium.WebMapServiceImageryProvider>();
  const imageLayerIrenArchi = useRef<Cesium.ImageryLayer>();
  const tilesetIrenNodi = useRef<Cesium.WebMapServiceImageryProvider>();
  const imageLayerIrenNodi = useRef<Cesium.ImageryLayer>();
  const google = useRef<Cesium.Cesium3DTileset>();
  const [showReteElettrica, setShowReteElettrica] = useState(true)
  const [showImpiantiDiGenerazione, setShowImpiantiDiGenerazione] = useState(true)

  const addTorinoTileset = async () => {
    tilesetImpiantiDiGenerazione.current = await Cesium.Cesium3DTileset.fromUrl(`${API_URL.replace(
      "api",
      ""
    )}tilesets/impianti_di_generazione/tileset.json`, {
      maximumScreenSpaceError: 1,
      // classificationType: Cesium.ClassificationType.TERRAIN,
      lightColor: new Cesium.Cartesian3(10, 10, 10),
    });
    tilesetImpiantiDiGenerazione.current.style = new Cesium.Cesium3DTileStyle({
      color: {
        conditions: [
          // eslint-disable-next-line no-template-curly-in-string
          ['${feature["Fonte"]} === "SOLARE"', 'color("yellow")'],
          // eslint-disable-next-line no-template-curly-in-string
          ['${feature["Fonte"]} === "RIFIUTI"', 'color("gray")'],
          // eslint-disable-next-line no-template-curly-in-string
          ['${feature["Fonte"]} === "NO FER"', 'color("red")'],
          // eslint-disable-next-line no-template-curly-in-string
          ['${feature["Fonte"]} === "IDRAULICA"', 'color("#007fff")'],
          // eslint-disable-next-line no-template-curly-in-string
          ['${feature["Fonte"]} === "BIOGAS"', 'color("green")'],
        ],
      },
    });
    viewer.current?.scene.primitives.add(tilesetImpiantiDiGenerazione.current);
  };

  const addIren = () => {
    tilesetIrenArchi.current = new Cesium.WebMapServiceImageryProvider({
      url: 'https://geoserver.est.polito.it/geoserver/citta/wms',
      layers: 'citta:iren_archi',
      parameters: {
        transparent: true,
        format: "image/png"
      }
    });
    imageLayerIrenArchi.current = new Cesium.ImageryLayer(tilesetIrenArchi.current, {});
    viewer.current!.imageryLayers.add(imageLayerIrenArchi.current);

    tilesetIrenNodi.current = new Cesium.WebMapServiceImageryProvider({
      url: 'https://geoserver.est.polito.it/geoserver/citta/wms',
      layers: 'citta:iren_nodi',
      parameters: {
        transparent: true,
        format: "image/png"
      }
    });
    imageLayerIrenNodi.current = new Cesium.ImageryLayer(tilesetIrenNodi.current, {});
    viewer.current!.imageryLayers.add(imageLayerIrenNodi.current);

  }

  useEffect(() => {
    if (archiSelectedIDs && tilesetIrenArchi.current) {
      const ids = archiSelectedIDs.map(id => `iren_archi.${id}`).join(',')
      tilesetIrenArchi.current!._tileProvider._resource._queryParameters.FEATUREID = `"id" IN (${ids})`
      tilesetIrenArchi.current!._reload()
    }
  }, [archiSelectedIDs])

  useEffect(() => {
    if (nodiSelectedIDs && tilesetIrenNodi.current) {
      const ids = nodiSelectedIDs.join(',')
      tilesetIrenNodi.current!._tileProvider._resource._queryParameters.FEATUREID = `"id" IN (${ids})`
      tilesetIrenNodi.current!._reload()
    }
  }, [nodiSelectedIDs])

  const OSMImagery = () => {
    viewer.current?.imageryLayers.removeAll();
    const osmImagery = new Cesium.OpenStreetMapImageryProvider({
      url: "https://a.tile.openstreetmap.org/",
    });
    viewer.current?.imageryLayers.addImageryProvider(osmImagery);
  };

  useEffect(() => {
    if (!container.current || viewer.current) return;

    viewer.current = new Cesium.Viewer(container.current, {
      timeline: false,
      animation: false,
      baseLayerPicker: false,
      homeButton: false,
      navigationHelpButton: false,
      infoBox: true,
      scene3DOnly: true,
      geocoder: false,
      shadows: false,
      selectionIndicator: false,
      fullscreenButton: false,
    });

    viewer.current.camera.setView({
      destination: Cesium.Cartesian3.fromDegrees(7.78, 45.02, 10000),
      orientation: {
        heading: Cesium.Math.toRadians(-60.0),
        pitch: Cesium.Math.toRadians(-45.0),
      },
    });
    viewer.current.screenSpaceEventHandler.removeInputAction(
      Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK
    );
    viewer.current.scene.globe.depthTestAgainstTerrain = true;
    addTorinoTileset();
    OSMImagery();
    generateGoogleInstance()
    addIren()
  }, []);

  const generateGoogleInstance = () => {
    Cesium.Cesium3DTileset.fromIonAssetId(2275207, {
      shadows: Cesium.ShadowMode.DISABLED,
      skipLevelOfDetail: true,
      baseScreenSpaceError: 10240,
      skipScreenSpaceErrorFactor: 16,
      skipLevels: 1,
      immediatelyLoadDesiredLevelOfDetail: false,
      loadSiblings: false,
      cullWithChildrenBounds: true
    }).then(g => {
      google.current = g;
    })
  }


  return (
    <div
      ref={container}
      style={{ width: "100%", height: "100%", position: "relative" }}
    >
      <div style={{ position: 'absolute', top: '0.5em', left: '0.5em', backdropFilter: 'rgba(0, 0, 0, 0)', pointerEvents: 'auto', zIndex: 1 }}>
        <div style={{ backgroundColor: "white", cursor: 'pointer', width: '100%', height: '100%', border: '1px solid gray', borderRadius: 5, padding: 2.5 }}>
          <div style={{ marginLeft: 20 }}><FormControlLabel control={<Checkbox checked={showReteElettrica}
            onChange={e => {
              setShowReteElettrica(!showReteElettrica)
              imageLayerIrenArchi.current!.show = !imageLayerIrenArchi.current?.show
              imageLayerIrenNodi.current!.show = !imageLayerIrenNodi.current?.show
            }} />} label='Electricity grid' /></div>
        </div>
        <div style={{ backgroundColor: "white", cursor: 'pointer', width: '100%', height: '100%', border: '1px solid gray', borderRadius: 5, padding: 2.5, marginTop: 2 }}>
          <div style={{ marginLeft: 20 }}><FormControlLabel control={<Checkbox checked={showImpiantiDiGenerazione}
            onChange={e => {
              setShowImpiantiDiGenerazione(!showImpiantiDiGenerazione)
              tilesetImpiantiDiGenerazione.current!.show = !tilesetImpiantiDiGenerazione.current!.show
            }} />} label='Generation plants' /></div>
        </div>
      </div>
    </div>
  );
}
