import React, { useEffect } from "react";
import { Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "./Dashboard/layout/PrivateRoute";
import Login from "./Login/Login";
import SplashScreen from "./SplashScreen/SplashScreen";
import Dashboard from "./Dashboard/layout/index";
import NotFound from "./NotFound/NotFound";
import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./Dashboard/actions/auth";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { createBrowserHistory } from "history";
import ModalsListener from "./Dashboard/components/ModalsListener/ModalsListener";
import ScrollToTop from "./Dashboard/components/ScrollToTop/ScrollToTop";

const hist = createBrowserHistory();

export const App = () => {
  var urlInitial = window.location.href;
  if (urlInitial.includes("token=")) {
    const tk =
      urlInitial.split("token=")[urlInitial.split("token=").length - 1];
    localStorage.setItem("token", tk);
  }
  useEffect(() => {
    store.dispatch(loadUser());
    document.title = "City Transition Planner";
  }, []);

  return (
    <Provider store={store}>
      <ModalsListener></ModalsListener>
      <Router history={hist}>
        <div className="App">
          <ScrollToTop />
          <Switch>
            <Route
              path="/"
              exact
              render={(props) => <SplashScreen urlInitial={urlInitial}></SplashScreen>}
            ></Route>
            <Route
              path="/ctp"
              exact
              render={(props) => <SplashScreen urlInitial={urlInitial}></SplashScreen>}
            ></Route>
            <Route
              path="/ctp/login"
              exact
              render={(props) => <Login urlInitial={urlInitial}></Login>}
            ></Route>
            <PrivateRoute
              path="/ctp/dashboard/"
              component={Dashboard}
            ></PrivateRoute>
            <Route component={NotFound}></Route>
          </Switch>
        </div>
      </Router>
    </Provider>
  );
};

export default App;
