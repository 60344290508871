import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { initialCalls } from "../actions/initialCalls";
import Content from "./Content";

export const Dashboard = (props) => {
  const [loadedUser, setLoadedUser] = useState(false);

  useEffect(() => {
    if (props.auth.isAuthenticated) {
      // do here all initial calls
      props.initialCalls();
      setLoadedUser(true);
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 1100);
    }
  }, []);

  return (
    <React.Fragment>{loadedUser ? <Content></Content> : null}</React.Fragment>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  initialCalls,
})(Dashboard);
