import React, { useState, useRef, useEffect } from "react";
import Map from "ol/Map";
import View from "ol/View";
import OSM from "ol/source/OSM.js";
import TileLayer from "ol/layer/Tile.js";
import { fromLonLat } from "ol/proj";
import TileWMS from "ol/source/TileWMS.js";
import Overlay from "ol/Overlay.js";
import { renderToString } from "react-dom/server";
import LegendControl from "./LegendControl";

function ElectricityGrid() {
  const mapRef = useRef();
  const [view] = useState(
    new View({
      projection: "EPSG:3857",
      center: fromLonLat([7.646, 45.04543]),
      zoom: 14,
    })
  );
  const [wmsLayerLines] = useState(
    new TileWMS({
      url: "https://geoserver.est.polito.it/geoserver/wms",
      params: {
        LAYERS: "citta:electricity_grid_lines",
        TILED: true,
      },
      serverType: "geoserver",
      transition: 0,
    })
  );
  const [wmsLayerPoints] = useState(
    new TileWMS({
      url: "https://geoserver.est.polito.it/geoserver/wms",
      params: {
        LAYERS: "citta:electricity_grid_points",
        TILED: true,
      },
      serverType: "geoserver",
      transition: 0,
    })
  );

  const [map] = useState(
    new Map({
      target: null,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        new TileLayer({
          source: wmsLayerLines,
        }),
        new TileLayer({
          source: wmsLayerPoints,
        }),
      ],
      view: view,
      controls: [],
    })
  );
  useEffect(() => {
    map.setTarget(mapRef.current);
    const overlayContainer = document.createElement("div");
    overlayContainer.classList.add("ol-popup");
    const overlayContent = document.createElement("div");
    overlayContainer.appendChild(overlayContent);

    const overlay = new Overlay({
      element: overlayContainer,
      autoPan: {
        animation: {
          duration: 250,
        },
      },
    });
    map.addOverlay(overlay);

    const legend = new LegendControl();
    map.addControl(legend);
    legend.update(wmsLayerLines.getLegendUrl(view.getResolution()));

    map.on("singleclick", (evt) => {
      overlay.setPosition(undefined);
      const urlPoints = wmsLayerPoints.getFeatureInfoUrl(
        evt.coordinate,
        view.getResolution(),
        view.getProjection(),
        {
          INFO_FORMAT: "application/json",
        }
      );
      if (urlPoints) {
        fetch(urlPoints)
          .then((response) => response.json())
          .then((json) => {
            if (json.numberReturned === 1) {
              const properties = json.features[0].properties;
              overlayContent.innerHTML = renderToString(
                <div>
                  <h6 style={{ textAlign: "center" }}>{properties.name}</h6>
                  {properties.line !== null ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>Line:</span> <span>{properties.line}</span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              );
              overlay.setPosition(json.features[0].geometry.coordinates);
            }
          });
      }

      const urlLines = wmsLayerLines.getFeatureInfoUrl(
        evt.coordinate,
        view.getResolution(),
        view.getProjection(),
        {
          INFO_FORMAT: "application/json",
        }
      );
      if (urlLines) {
        fetch(urlLines)
          .then((response) => response.json())
          .then((json) => {
            if (json.numberReturned === 1) {
              const properties = json.features[0].properties;
              overlayContent.innerHTML = renderToString(
                <div>
                  <h6 style={{ textAlign: "center" }}>
                    Line {properties.name}
                  </h6>
                </div>
              );
              overlay.setPosition(evt.coordinate);
            }
          });
      }
    });
  }, []);

  return <div ref={mapRef} style={{ width: "100%", height: "100%" }} />;
}

export default ElectricityGrid;
