import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import logoSplashScreen from "../images/logo_est_polito_EC_noBackgroud.png";
import logoTorino from "../images/torino_logo_full.png";
import { checkAndMakeRedirection } from "../Dashboard/actions/auth";
import "./SplashScreen.css";

export const SplashScreen = (props) => {
  const [visibleText, setVisibleText] = useState(true);
  let history = useHistory();


  setTimeout(() => {
    setVisibleText(false);
  }, 3600);

  useEffect(() => {
    if (props.isAuthenticated) {
      checkAndMakeRedirection(history, props.urlInitial);
    }
  }, [props.isAuthenticated]);


  // if (props.isAuthenticated) {
  //   return <Redirect to="/ctp/login"></Redirect>;
  // }

  return (
    <div>
      <div className="context"></div>
      {/* create moving circles and squares (each <li> tag) */}
      <div className="area">
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <div className="divLogin login">
        <div
          className="formFadeIn"
          style={visibleText ? { display: "none" } : { display: "" }}
        >
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                className="content"
                src={logoTorino}
                alt="LogoTorino"
                style={{
                  height: "95px",
                  width: "300px",
                  marginBottom: "60px",
                  imageRendering: "-webkit-optimize-contrast",
                  marginRight: 30,
                }}
              />
              <img
                className="content"
                src={logoSplashScreen}
                alt="Logo"
                style={{
                  height: "auto",
                  width: "250px",
                  marginBottom: "60px",
                  imageRendering: "-webkit-optimize-contrast",
                }}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>
              <div className="splashscreen">
                <h1>CTP</h1>
                <h1
                  style={{
                    fontFamily: "Garamond",
                    fontSize: 30,
                    fontWeight: "bold",
                  }}
                >
                  City Transition Planner
                </h1>
              </div>
              <Container
                component="main"
                maxWidth="xs"
                style={{
                  marginTop: "15px",
                  paddingLeft: 0,
                  paddingRight: 0,
                }}
              >
                <div>
                  <Link to="/ctp/login">
                    <button className="btnSplashScreen">Login</button>
                  </Link>
                </div>
                <div
                  style={{
                    width: "100%",
                    position: "absolute",
                    bottom: 15,
                    left: 0,
                  }}
                >
                  <div
                    style={{
                      color: "#F0F0F0",
                      fontSize: 15,
                      textDecoration: "underline",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                  >
                    Credits
                  </div>
                </div>
              </Container>
            </div>
          </div>
        </div>
        {visibleText ? (
          <p
            className="introApp"
            style={{ marginBottom: "50px", marginRight: "130px" }}
          >
            Loading
          </p>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {})(SplashScreen);
