import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useWindowDimensions } from "../../../utils/useWindowDimensions";
import { connect } from "react-redux";
import {
  FaRegClock,
  FaCloud,
  FaCog,
  FaIndustry,
  FaPrint,
} from "react-icons/fa";
import moment from "moment";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { logout } from "../../../actions/auth";

function Footer(props) {
  const [currentTime, setCurrentTime] = useState("");
  const [modal, setModal] = useState(false);
  const { width, height } = useWindowDimensions();
  const history = useHistory();

  const toggle = () => setModal(!modal);

  useEffect(() => {
    setInterval(function () {
      setCurrentTime(moment().format("YYYY-MM-DD HH:mm:ss"));
    }, 500);
  }, []);
  return (
    <div
      style={{
        position: "absolute",
        bottom: 0,
        zIndex: 1,
        width: "100%",
        backgroundColor: props.layout.color_footer_ctp,
        height: props.layout.footer_height_ctp,
        paddingLeft: props.layout.width_sidebar_ctp,
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          padding: 5,
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            color: "white",
            fontSize: 14,
            textDecoration: "italics",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <FaRegClock
                style={{ fontSize: 17, marginRight: 10 }}
              ></FaRegClock>
              <i>{currentTime}</i>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", marginLeft: 40 }}
            >
              <FaIndustry
                style={{ fontSize: 17, marginRight: 10 }}
              ></FaIndustry>
              <i>PM10: 19 μg/m3</i>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", marginLeft: 40 }}
            >
              <FaCloud style={{ fontSize: 17, marginRight: 10 }}></FaCloud>
              <i>
                NO<sub>2</sub>: 88 μg/m3
              </i>
            </div>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <FaPrint
              style={{ fontSize: 17, cursor: "pointer", marginRight: 15 }}
              onClick={() => window.print()}
            ></FaPrint>
            <FaCog
              style={{ fontSize: 17, cursor: "pointer" }}
              onClick={() => setModal(true)}
            ></FaCog>
          </div>
        </div>
      </div>

      {/* settings modal */}
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>
          <span>Settings</span>
        </ModalHeader>
        <ModalBody>
          Leave the application:
          <Button
            color="danger"
            onClick={() => props.logout()}
            style={{ marginLeft: 10 }}
          >
            Logout
          </Button>
        </ModalBody>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps, { logout })(Footer);
