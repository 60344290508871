export const fig = {
  data: [
    {
      type: "sankey",
      arrangement: "freeform",
      domain: {
        x: [0, 1],
        y: [0, 1],
      },
      valueformat: ".3r",
      valuesuffix: " Mtoe",
      orientation: "h",
      node: {
        thickness: 1,
        line: {
          width: 1,
          color: "transparent",
        },
        label: [
          "Bio-electricity",
          "Bio-electricity Production",
          "Bio-fuels",
          "Bio-fuels Import",
          "Bio-fuels Production",
          "Bio-fuels Stock change",
          "Bunkers",
          "Civil and Agriculture",
          "Consumption and losses",
          "Electricity",
          "Electricity Import",
          "Electricity production",
          "Export",
          "Gas",
          "Gas Import",
          "Gas Production",
          "Gas Stock change",
          "Industry",
          "Non energy uses",
          "Oil",
          "Oil Import",
          "Oil Production",
          "Oil Stock change",
          "Renewables",
          "Renewables Production",
          "Solid fuels",
          "Solid fuels Import",
          "Solid fuels Production",
          "Solid fuels Stock change",
          "TOTAL",
          "TOTAL Import",
          "TOTAL Production",
          "TOTAL Stock change",
          "Total final use",
          "Transport",
          "",
        ],
        x: [
          0.25, 0.01, 0.25, 0.01, 0.01, 0.01, 0.99, 0.99, 0.99, 0.65, 0.01, 0.5,
          0.99, 0.25, 0.01, 0.01, 0.01, 0.99, 0.99, 0.25, 0.01, 0.01, 0.01,
          0.25, 0.01, 0.25, 0.01, 0.01, 0.99, 0.99, 0.587,
        ],
        y: [
          0.87, 0.87, 0.68, 0.64, 0.68, 0.72, 0.7, 0.53, 0.82, 0.86, 0.8, 0.78,
          0.25, 0.47, 0.47, 0.57, 0.37, 0.05, 0.65, 0.01, 0.01, 0.11, 0.15,
          0.95, 0.95, 0.27, 0.23, 0.27, 0.33, 0.15, 0.78,
        ],
        color: "white",
      },
      link: {
        source: [
          19, 13, 19, 9, 13, 11, 35, 19, 11, 35, 0, 13, 11, 35, 19, 11, 35, 23,
          25, 11, 35, 2, 9, 13, 19, 25, 3, 10, 14, 20, 26, 13, 19, 25, 13, 19,
          1, 4, 15, 21, 24, 27, 5, 16, 22, 25, 2, 13, 19, 9, 9, 9,
        ],
        target: [
          6, 7, 7, 8, 11, 35, 8, 11, 35, 8, 9, 11, 35, 9, 11, 35, 9, 9, 11, 35,
          9, 12, 12, 12, 12, 12, 2, 9, 13, 19, 25, 17, 17, 17, 18, 18, 0, 2, 13,
          19, 23, 25, 2, 13, 19, 28, 34, 34, 34, 17, 34, 7,
        ],
        value: [
          0.7071087300000001, 11.266467808964128, 1.1791310900607783,
          9.08351019806074, 0.5918283193236817, 0.5918283193236817,
          0.5918283193236817, 0.77425, 0.77425, 0.77425, 1.3367499999999999,
          5.28320145712912, 5.28320145712912, 5.28320145712912, 0.12817,
          0.12817, 0.12817, 4.983280062541262, 0.8543163461690737,
          0.8543163461690737, 0.8543163461690737, 0.0723327564, 0.20042,
          0.1478034707952141, 6.50130663, 0.05181465169999999, 0.2172197813,
          2.84614, 14.193838569833407, 15.421684108100003, 1.4738179407499998,
          3.0440613611371106, 0.6429536110516226, 0.5505030580309263, 0.16325,
          1.35987261, 1.3367499999999999, 0.19, 0.7668570855028032,
          1.3680450000000002, 4.983280062541262, 0.08989414409999999,
          0.0016217312999999853, 5.777500928186427, 0.985021105699996,
          0.10707802895000004, 0.3365087562, 0.24175, 6.481957542687599,
          2.6838310705056823, 0.2408466391135735, 3.2232499581594602,
        ],
        color: [
          "rgba(110, 98, 151, 0.8)",
          "rgba(0, 95, 147, 0.8)",
          "rgba(110, 98, 151, 0.8)",
          "rgba(0, 186, 218, 0.8)",
          "rgba(0, 95, 147, 0.8)",
          "rgba(192, 235, 247, 0.8)",
          "rgba(195, 195, 195, 0.8)",
          "rgba(110, 98, 151, 0.8)",
          "rgba(192, 235, 247, 0.8)",
          "rgba(195, 195, 195, 0.8)",
          "rgba(0, 255, 72, 0.8)",
          "rgba(0, 95, 147, 0.8)",
          "rgba(192, 235, 247, 0.8)",
          "rgba(0, 186, 218, 0.8)",
          "rgba(110, 98, 151, 0.8)",
          "rgba(192, 235, 247, 0.8)",
          "rgba(0, 186, 218, 0.8)",
          "rgba(21, 153, 21, 0.8)",
          "rgba(176, 179, 182, 0.8)",
          "rgba(192, 235, 247, 0.8)",
          "rgba(0, 186, 218, 0.8)",
          "rgba(220, 255, 0, 0.8)",
          "rgba(0, 186, 218, 0.8)",
          "rgba(0, 95, 147, 0.8)",
          "rgba(110, 98, 151, 0.8)",
          "rgba(176, 179, 182, 0.8)",
          "rgba(220, 255, 0, 0.8)",
          "rgba(0, 186, 218, 0.8)",
          "rgba(0, 95, 147, 0.8)",
          "rgba(110, 98, 151, 0.8)",
          "rgba(176, 179, 182, 0.8)",
          "rgba(0, 95, 147, 0.8)",
          "rgba(110, 98, 151, 0.8)",
          "rgba(176, 179, 182, 0.8)",
          "rgba(0, 95, 147, 0.8)",
          "rgba(110, 98, 151, 0.8)",
          "rgba(0, 255, 72, 0.8)",
          "rgba(220, 255, 0, 0.8)",
          "rgba(0, 95, 147, 0.8)",
          "rgba(110, 98, 151, 0.8)",
          "rgba(21, 153, 21, 0.8)",
          "rgba(176, 179, 182, 0.8)",
          "rgba(220, 255, 0, 0.8)",
          "rgba(0, 95, 147, 0.8)",
          "rgba(110, 98, 151, 0.8)",
          "rgba(176, 179, 182, 0.8)",
          "rgba(220, 255, 0, 0.8)",
          "rgba(0, 95, 147, 0.8)",
          "rgba(110, 98, 151, 0.8)",
          "rgba(0, 186, 218, 0.8)",
          "rgba(0, 186, 218, 0.8)",
          "rgba(0, 186, 218, 0.8)",
        ],
      },
    },
  ],
  layout: {
    title: {
      text: "Energy forecast for 2050, UK — Department of Energy & Climate Change<br>Imperfect copy of <a href='https://bost.ocks.org/mike/sankey/'>Mike Bostock's example</a><br>with numerous <a href='https://plotly.com/javascript/'>Plotly</a> features",
    },
    width: 1118,
    height: 772,
    font: {
      size: 10,
    },
    updatemenus: [
      {
        y: 1,
        buttons: [
          {
            label: "Light",
            method: "relayout",
            args: ["paper_bgcolor", "white"],
          },
          {
            label: "Dark",
            method: "relayout",
            args: ["paper_bgcolor", "black"],
          },
        ],
      },
      {
        y: 0.9,
        buttons: [
          {
            label: "Thick",
            method: "restyle",
            args: ["node.thickness", 15],
          },
          {
            label: "Thin",
            method: "restyle",
            args: ["node.thickness", 8],
          },
        ],
      },
      {
        y: 0.8,
        buttons: [
          {
            label: "Small gap",
            method: "restyle",
            args: ["node.pad", 15],
          },
          {
            label: "Large gap",
            method: "restyle",
            args: ["node.pad", 20],
          },
        ],
      },
      {
        y: 0.7,
        buttons: [
          {
            label: "Snap",
            method: "restyle",
            args: ["arrangement", "snap"],
          },
          {
            label: "Perpendicular",
            method: "restyle",
            args: ["arrangement", "perpendicular"],
          },
          {
            label: "Freeform",
            method: "restyle",
            args: ["arrangement", "freeform"],
          },
          {
            label: "Fixed",
            method: "restyle",
            args: ["arrangement", "fixed"],
          },
        ],
      },
      {
        y: 0.6,
        buttons: [
          {
            label: "Horizontal",
            method: "restyle",
            args: ["orientation", "h"],
          },
          {
            label: "Vertical",
            method: "restyle",
            args: ["orientation", "v"],
          },
        ],
      },
    ],
  },
};
