import React from "react";
import { connect } from "react-redux";
import { handleOCInfoBottom } from "../../actions/handleOpenCloseActions";
import { handleMenuOC } from "../../actions/handleOpenCloseActions";
import { SpeedDials } from "./SpeedDials";

export const ThreeIconsMenu = ({
  isStaff,
  page,
  handleOCInfoBottom,
  handleMenuOC,
}) => {
  return (
    <div>
      <SpeedDials isStaff={isStaff}></SpeedDials>
      <button
        type="button"
        className="btn btn-circle btn-xl"
        onClick={() => {
          handleMenuOC();
          setTimeout(() => {
            window.dispatchEvent(new Event("resize"));
          }, 1000);
        }}
        style={{
          position: "fixed",
          right: "122px",
          top: "20px",
          zIndex: 999,
          width: "65px",
          height: "65px",
          borderRadius: "35px",
          fontSize: "24px",
          boxShadow:
            "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
          backgroundColor: "#1f5cdd",
          cursor: "pointer",
        }}
      >
        <i
          className="fa fa-sliders"
          style={{ color: "white", marginBottom: "5px" }}
        ></i>
      </button>
      <button
        type="button"
        className="btn btn-circle btn-xl"
        onClick={handleOCInfoBottom}
        style={{
          position: "fixed",
          right: "40px",
          top: "20px",
          zIndex: 999,
          width: "65px",
          height: "65px",
          borderRadius: "35px",
          fontSize: "24px",
          boxShadow:
            "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
          backgroundColor: "#1f5cdd",
        }}
      >
        <i className="fa fa-info" style={{ color: "white" }}></i>
      </button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isStaff: state.auth.isStaff,
  page: state.toolbar.page,
});

export default connect(mapStateToProps, { handleOCInfoBottom, handleMenuOC })(
  ThreeIconsMenu
);
