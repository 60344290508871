export const consumiPerSettore2020 = [
  {
    settore: "Industry",
    consumo_totale: 2943391,
    contributo: 24.7,
  },
  {
    settore: "Tertiary",
    consumo_totale: 2942001,
    contributo: 24.7,
  },
  {
    settore: "Residential",
    consumo_totale: 3735757,
    contributo: 31.4,
  },
  {
    settore: "Transport",
    consumo_totale: 2291113,
    contributo: 19.2,
  },
];

export const consumiPerCommodity2020 = [
  {
    settore: "Electric en.",
    consumo_totale: 2696774,
    contributo: 22.6,
  },
  {
    settore: "Natural gas",
    consumo_totale: 5176259,
    contributo: 43.5,
  },
  {
    settore: "Heat",
    consumo_totale: 1790025,
    contributo: 15,
  },
  {
    settore: "Petroleum pr.",
    consumo_totale: 2220969,
    contributo: 18.6,
  },
  {
    settore: "Others",
    consumo_totale: 28235,
    contributo: 0.2,
  },
];

export const consumiPerSettore2018 = [
  {
    settore: "Industry",
    consumo_totale: 3329421,
    contributo: 26,
  },
  {
    settore: "Tertiary",
    consumo_totale: 2871131,
    contributo: 22.4,
  },
  {
    settore: "Residential",
    consumo_totale: 3801698,
    contributo: 29.7,
  },
  {
    settore: "Transport",
    consumo_totale: 2791146,
    contributo: 21.8,
  },
];

export const consumiPerCommodity2018 = [
  {
    settore: "Electric en.",
    consumo_totale: 2423712,
    contributo: 18.9,
  },
  {
    settore: "Natural gas",
    consumo_totale: 5873915,
    contributo: 45.9,
  },
  {
    settore: "Heat",
    consumo_totale: 1813062,
    contributo: 14.2,
  },
  {
    settore: "Petroleum pr.",
    consumo_totale: 2646931,
    contributo: 20.7,
  },
  {
    settore: "Others",
    consumo_totale: 35776,
    contributo: 0.3,
  },
];
