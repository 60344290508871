import React from "react";
import { connect } from "react-redux";
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";
import { Col } from "reactstrap";

function CardImpactAssessment(props) {
  const { width, height } = useWindowDimensions();
  return (
    <Col
      xxl={width > 1700 ? "3" : "6"}
      xl="6"
      lg="6"
      md="6"
      sm="6"
      xs="12"
      style={{ padding: 5 }}
    >
      <div style={{ border: "1px solid #E2E2E2", borderRadius: 10 }}>
        <div
          style={{
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            backgroundColor: props.layout.color_footer_ctp,
            color: "white",
            padding: "0px 7px",
          }}
        >
          {props.title}
        </div>
        <div
          style={{
            padding: 10,
            display: "flex",
            fontWeight: "bold",
          }}
        >
          <div style={{ width: "50%", padding: "10px 0px" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: "gray",
              }}
            >
              <div
                style={{
                  width: 10,
                  height: 10,
                  backgroundColor: "gray",
                  borderRadius: "50%",
                  marginRight: 10,
                }}
              ></div>
              {props.value_1}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: props.layout.color_sidebar_ctp,
                marginTop: 10,
              }}
            >
              <div
                style={{
                  width: 10,
                  height: 10,
                  backgroundColor: props.layout.color_sidebar_ctp,
                  borderRadius: "50%",
                  marginRight: 10,
                }}
              ></div>
              {props.value_2}
            </div>
          </div>
          <div
            style={{
              width: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: 32,
              color: props.color_footer_ctp,
            }}
          >
            {props.delta}
          </div>
        </div>
      </div>
    </Col>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(CardImpactAssessment);
