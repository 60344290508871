import React from "react";
import { useWindowDimensions } from "../../../utils/useWindowDimensions";
import { connect } from "react-redux";
import LogoTorino from "../../../../images/torino_logo_full.png";
import LogoPolitecnico from "../../../../images/logo_est_polito_EC_noBackgroud.png";
import DefaultPerson from "../../../../images/default_person.png";
import { FaSearch } from "react-icons/fa";
import { Input } from "reactstrap";

function Navbar(props) {
  const { width, height } = useWindowDimensions();

  return (
    <div
      style={{
        width: "100%",
        zIndex: 1,
        backgroundColor: props.layout.color_navbar_ctp,
        height: height * 0.08,
        paddingLeft: props.layout.width_sidebar_ctp,
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          padding: 10,
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ height: "100%" }}>
            {width > 700 ? (
              <>
                <img
                  src={LogoTorino}
                  style={{
                    height: "100%",
                    width: "auto",
                  }}
                ></img>
                <img
                  src={LogoPolitecnico}
                  style={{
                    height: "100%",
                    marginLeft: 15,
                  }}
                ></img>
              </>
            ) : null}
          </div>
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Input
              type="text"
              style={{ padding: "2px 10px", borderRadius: 20 }}
            />
            <FaSearch
              style={{ fontSize: 25, marginRight: 20, marginLeft: "-15px" }}
            ></FaSearch>
            <img
              src={DefaultPerson}
              alt="pic"
              style={{
                borderRadius: "50%",
                height: "55%",
                width: "auto",
              }}
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(Navbar);
