import React, { useRef, useEffect, useState } from "react";
import { renderToString } from "react-dom/server";
import * as Cesium from "cesium";
import "cesium/Build/Cesium/Widgets/widgets.css";
import { API_URL } from "../../../actions/types";
import gearth from "./gearth.png"
import axios from "axios";

export default function Ape({ selectedFeaturesOBJ }) {
    const [style, setStyle] = useState("buildings-type");

    const container = useRef<HTMLDivElement>(null);
    const legend = useRef<HTMLDivElement>(null);
    const viewer = useRef<Cesium.Viewer>();
    const tilesetTorino = useRef<Cesium.Cesium3DTileset>();
    const google = useRef<Cesium.Cesium3DTileset>();

    const features = useRef({});

    const addTorinoTileset = async () => {
        tilesetTorino.current = await Cesium.Cesium3DTileset.fromUrl(`${API_URL.replace("api", "")}tilesets/ape/tileset.json`, {
            maximumScreenSpaceError: 1,
            lightColor: new Cesium.Cartesian3(10, 10, 10),
        });

        tilesetTorino.current.style = new Cesium.Cesium3DTileStyle({
            color: {
                conditions: [
                    // eslint-disable-next-line no-template-curly-in-string
                    ["${selected} === true", 'color("aqua")'],
                    // eslint-disable-next-line no-template-curly-in-string
                    [
                        '${feature["EPC count"]} >= 65', 'color("#d7191c")',
                    ],
                    // eslint-disable-next-line no-template-curly-in-string
                    [
                        '${feature["EPC count"]} >= 20', 'color("#fdae61")',
                    ],
                    // eslint-disable-next-line no-template-curly-in-string
                    [
                        '${feature["EPC count"]} >= 8', 'color("#ffffbf")',
                    ],
                    // eslint-disable-next-line no-template-curly-in-string
                    [
                        '${feature["EPC count"]} >= 2', 'color("#abdda4")',
                    ],
                    // eslint-disable-next-line no-template-curly-in-string
                    [
                        '${feature["EPC count"]} >= 0', 'color("#2b83ba")',
                    ],

                ],
            },
        });
        viewer.current?.scene.primitives.add(tilesetTorino.current);
        tilesetTorino.current!.tileLoad.addEventListener((tile: Cesium.Cesium3DTile) => {


            const content = tile.content;
            const featuresLength = content.featuresLength;
            for (let i = 0; i < featuresLength; i++) {
                const feature = content.getFeature(i);
                const feature_id = feature.getProperty("Identifier");
                features.current[feature_id] = feature;
                features.current[feature_id].setProperty(
                    "selected",
                    selectedFeaturesOBJ[feature_id] ?? false
                );
            }
        });


    };

    const addLegendHTML = () => {
        legend.current!.innerHTML = renderToString(
            <div style={{ fontSize: 14, width: 100 }}>
                <div
                    style={{
                        fontWeight: "bold",
                        width: "100%",
                        textAlign: "center",
                        marginBottom: 5,
                    }}
                >
                    EPC count
                </div>
                <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", width: "100%", }}><div style={{ height: 14, width: 14, backgroundColor: "#2b83ba" }} ></div><div style={{ width: 10 }}></div><div>0 - 1</div></div>
                <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", width: "100%", }}><div style={{ height: 14, width: 14, backgroundColor: "#abdda4" }} ></div><div style={{ width: 10 }}></div><div>2 - 7</div></div>
                <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", width: "100%", }}><div style={{ height: 14, width: 14, backgroundColor: "#ffffbf" }} ></div><div style={{ width: 10 }}></div><div>8 - 19</div></div>
                <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", width: "100%", }}><div style={{ height: 14, width: 14, backgroundColor: "#fdae61" }} ></div><div style={{ width: 10 }}></div><div>20 - 64</div></div>
                <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", width: "100%", }}><div style={{ height: 14, width: 14, backgroundColor: "#d7191c" }} ></div><div style={{ width: 10 }}></div><div>65 - 289</div></div>
            </div>
        );
    };

    useEffect(() => {
        if (!tilesetTorino.current || !selectedFeaturesOBJ) return;
        tilesetTorino.current!.tileLoad._listeners = [];
        tilesetTorino.current!.tileLoad._scopes = [];
        tilesetTorino.current!.tileLoad.addEventListener((tile) => {
            const content = tile.content;
            const featuresLength = content.featuresLength;
            for (let i = 0; i < featuresLength; i++) {
                const feature = content.getFeature(i);
                const feature_id = feature.getProperty("Identifier");
                features.current[feature_id] = feature;
                features.current[feature_id].setProperty(
                    "selected",
                    selectedFeaturesOBJ[feature_id] ?? false
                );
                feature.setProperty(
                    "epv_class",
                    computeEPVClass(feature.getProperty("Producibilità [kWh/anno]"))
                );
            }
        });
        Object.entries(features.current).forEach(([id, feature]) => {
            feature.setProperty("selected", selectedFeaturesOBJ[id] ?? false);
        });
    }, [selectedFeaturesOBJ]);

    const OSMImagery = () => {
        viewer.current?.imageryLayers.removeAll();
        const osmImagery = new Cesium.OpenStreetMapImageryProvider({
            url: "https://a.tile.openstreetmap.org/",
        });
        viewer.current?.imageryLayers.addImageryProvider(osmImagery);
    };



    useEffect(() => {
        if (!container.current || viewer.current) return;

        viewer.current = new Cesium.Viewer(container.current, {
            timeline: false,
            animation: false,
            baseLayerPicker: false,
            homeButton: false,
            navigationHelpButton: false,
            infoBox: true,
            scene3DOnly: true,
            geocoder: false,
            shadows: false,
            selectionIndicator: true,
            fullscreenButton: false,
        });
        viewer.current.scene.globe.depthTestAgainstTerrain = true
        // viewer.current.scene.globe.ellipsoid = true


        viewer.current.camera.setView({
            destination: Cesium.Cartesian3.fromDegrees(7.78, 45.02, 10000),
            orientation: {
                heading: Cesium.Math.toRadians(-60.0),
                pitch: Cesium.Math.toRadians(-45.0),
            },
        });
        viewer.current.screenSpaceEventHandler.removeInputAction(
            Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK
        );
        addTorinoTileset();
        OSMImagery();
        addLegendHTML()
        generateGoogleInstance()
        // addClickHandler()
    }, []);

    useEffect(() => {
        if (!tilesetTorino.current) return;
        tilesetTorino.current!.style = new Cesium.Cesium3DTileStyle({
            color: {
                conditions: [
                    // eslint-disable-next-line no-template-curly-in-string
                    ["${selected} === true", 'color("aqua")'],
                    // eslint-disable-next-line no-template-curly-in-string
                    [
                        '${feature["Tipologia edificio"]} === "Residenziale"',
                        'color("#698269")',
                    ],
                    // eslint-disable-next-line no-template-curly-in-string
                    [
                        '${feature["Tipologia edificio"]} === "Servizi"',
                        'color("#B99B6B")',
                    ],
                    // eslint-disable-next-line no-template-curly-in-string
                    [
                        '${feature["Tipologia edificio"]} === "Produzione"',
                        'color("#AA5656")',
                    ],
                ],
            },
        });
    }, [style]);

    const generateGoogleInstance = () => {
        Cesium.Cesium3DTileset.fromIonAssetId(2275207, {
            shadows: Cesium.ShadowMode.DISABLED,
            skipLevelOfDetail: true,
            baseScreenSpaceError: 10240,
            skipScreenSpaceErrorFactor: 16,
            skipLevels: 1,
            immediatelyLoadDesiredLevelOfDetail: false,
            loadSiblings: false,
            cullWithChildrenBounds: true
        }).then(g => {
            google.current = g;
        })
    }

    function toggleGoogle() {
        if (viewer.current?.scene.primitives.contains(google.current!)) {
            viewer.current?.scene.primitives.remove(google.current!)
            generateGoogleInstance()
        } else {
            viewer.current?.scene.primitives.add(google.current!)
        }
    }

    return (
        <div
            ref={container}
            style={{ width: "100%", height: "100%", position: "relative" }}
        >
            <div
                ref={legend}
                style={{
                    position: "absolute",
                    bottom: ".5em",
                    left: ".5em",
                    backgroundColor: "white",
                    borderRadius: 5,
                    padding: 10,
                    zIndex: 99,
                    pointerEvents: "none",
                }}
            />
        </div>
    );
}
