export const getFontSize = (dimension) => {
  const dictSizes = {
    extraSmall: "clamp(0.7rem, calc(0.09vw + 0.93vh), 2rem)",
    small: "clamp(0.8rem, calc(0.2vw + 0.95vh), 2.5rem)",
    medium: "clamp(0.9rem, calc(0.27vw + 0.96vh), 2.7rem)",
    large: "clamp(1rem, calc(0.3vw + 0.98vh), 2.9rem)",
    extrLarge: "clamp(1.2rem, calc(0.44vw + 1vh), 3.5rem)",
  };
  return dictSizes[dimension];
};
