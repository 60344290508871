import React, { useState, useEffect } from "react";
import { useWindowDimensions } from "../../../utils/useWindowDimensions";
import { connect } from "react-redux";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
} from "reactstrap";
import { getFontSize } from "../../../utils/getFontSize";
import ModellingParametersPhoto from "../../../../images/modelling_parameters.png";
import { TextField } from "@mui/material";

export const MainContent = (props) => {
  const { width, height } = useWindowDimensions();
  const padding = height * 0.01;
  const [activeTab, setActiveTab] = useState("1");
  const [listParams, setListParams] = useState([]);

  useEffect(() => {
    setListParams([
      {
        category_id: "carbIntEltGen_Dict",
        category_name: "Gen. el. carbon intensity",
        id: "coal_carbIntEltGen_Dict",
        description: "Coal el. gen. [kg/kWh]",
        value: 0.94,
      },
      {
        category_id: "carbIntEltGen_Dict",
        category_name: "Gen. el. carbon intensity",
        id: "gas_carbIntEltGen_Dict",
        description: "Gas el. gen. [kg/kWh]",
        value: 0.471,
      },
      {
        category_id: "carbIntEltGen_Dict",
        category_name: "Gen. el. carbon intensity",
        id: "geothermal_carbIntEltGen_Dict",
        description: "Geothermal el. gen. [kg/kWh]",
        value: 0.047,
      },
      {
        category_id: "carbIntEltGen_Dict",
        category_name: "Gen. el. carbon intensity",
        id: "hydro_carbIntEltGen_Dict",
        description: "Hydro el. gen. [kg/kWh]",
        value: 0.013,
      },
      {
        category_id: "carbIntEltGen_Dict",
        category_name: "Gen. el. carbon intensity",
        id: "oil_carbIntEltGen_Dict",
        description: "Oil el. gen. [kg/kWh]",
        value: 0.84,
      },
      {
        category_id: "carbIntEltGen_Dict",
        category_name: "Gen. el. carbon intensity",
        id: "photovoltaic_carbIntEltGen_Dict",
        description: "Photovoltaic el. gen. [kg/kWh]",
        value: 0.056,
      },
      {
        category_id: "carbIntEltGen_Dict",
        category_name: "Gen. el. carbon intensity",
        id: "wind_on_carbIntEltGen_Dict",
        description: "Wind el. gen. [kg/kWh]",
        value: 0.012,
      },
      {
        category_id: "carbIntHeat_Dict",
        category_name: "Dir. comb. carbon intensity",
        id: "district_heating_carbIntHeat_Dict",
        description: "District heating direct comb. [kg/kWh]",
        value: 0.45,
      },
      {
        category_id: "carbIntHeat_Dict",
        category_name: "Dir. comb. carbon intensity",
        id: "gas_carbIntHeat_Dict",
        description: "Gas direct comb. [kg/kWh]",
        value: 0.202,
      },
      {
        category_id: "carbIntHeat_Dict",
        category_name: "Dir. comb. carbon intensity",
        id: "lpg_carbIntHeat_Dict",
        description: "Lpg direct comb. [kg/kWh]",
        value: 0.237,
      },
      {
        category_id: "carbIntHeat_Dict",
        category_name: "Dir. comb. carbon intensity",
        id: "oil_carbIntHeat_Dict",
        description: "Oil direct comb. [kg/kWh]",
        value: 0.259,
      },
      {
        category_id: "carbIntHeat_Dict",
        category_name: "Dir. comb. carbon intensity",
        id: "photovoltaic_carbIntHeat_Dict",
        description: "Photovoltaic direct comb. [kg/kWh]",
        value: 0.056,
      },
      {
        category_id: "other",
        category_name: "other",
        id: "PVCostSpec",
        description: "PV Cost Spec [€/kW]",
        value: 2000,
      },
      {
        category_id: "other",
        category_name: "other",
        id: "PVPowSpec",
        description: "PV Pow Spec [kW/m^2]",
        value: 0.2,
      },
      {
        category_id: "other",
        category_name: "other",
        id: "COPHeatPumps",
        description: "COP heat pumps",
        value: 3,
      },
    ]);
  }, []);

  const kk = ["other"];

  return (
    <Row style={{ margin: 0, padding: padding }}>
      <Col
        xs="12"
        style={{
          height: "100%",
          padding: padding,
        }}
      >
        <Nav tabs style={{ fontSize: getFontSize("medium") }}>
          <NavItem>
            <NavLink
              className={activeTab === "1" ? "active" : ""}
              onClick={() => setActiveTab("1")}
            >
              Efficiencies
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <div
              style={{
                height: "100%",
                backgroundColor: "#E3F3F9",
                borderRadius: 7,
              }}
            >
              <Row style={{ height: "100%", margin: 0 }}>
                <Col
                  md="4"
                  sm="12"
                  style={{
                    padding: 10,
                    height: height * 0.703,
                  }}
                >
                  <div
                    style={{
                      height: "100%",
                      backgroundColor: "#9FD4FF",
                      borderRadius: 7,
                      padding: 8,
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        fontWeight: 500,
                        fontSize: getFontSize("large"),
                        borderBottom: "3px solid white",
                      }}
                    >
                      Efficiencies
                    </div>
                    <div
                      style={{
                        height: "91%",
                        overflow: "auto",
                        padding: 10,
                        // display: "flex",
                        // flexWrap: "wrap",
                        // alignItems: "center",
                        // alignContent: "space-between",
                      }}
                    >
                      {[
                        ...new Set(listParams.map((obj) => obj.category_name)),
                      ].map((category) => {
                        return (
                          <div
                            style={{
                              width: "100%",
                              padding: 10,
                              border: "1px solid #5F5F5F",
                              borderRadius: 7,
                              marginTop: 10,
                            }}
                          >
                            <div
                              style={{
                                fontWeight: 500,
                                fontSize: getFontSize("medium"),
                              }}
                            >
                              {category}
                            </div>

                            {listParams
                              .filter((obj) => obj.category_name === category)
                              .map((obj) => {
                                return (
                                  <div
                                    style={{
                                      borderBottom: "1px solid white",
                                      width: "100%",
                                      fontSize: getFontSize("medium"),
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      paddingBottom: 5,
                                    }}
                                  >
                                    {obj.description}
                                    <TextField
                                      defaultValue={obj.value}
                                      type="number"
                                      variant="standard"
                                      InputProps={{
                                        style: {
                                          fontSize: getFontSize("medium"),
                                        },
                                      }}
                                      style={{
                                        width: width > 1600 ? 200 : 70,
                                      }}
                                    />
                                  </div>
                                );
                              })}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Col>
                <Col
                  md="8"
                  sm="12"
                  style={{
                    padding: 10,
                    height: height * 0.703,
                  }}
                >
                  <div
                    style={{
                      height: "100%",
                      backgroundColor: "white",
                      borderRadius: 7,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={ModellingParametersPhoto}
                      alt=""
                      style={{
                        height: width < 1100 ? "65%" : "80%",
                        maxHeight: 1000,
                        width: width < 1100 ? "95%" : "70%",
                        maxWidth: 2000,
                      }}
                    ></img>
                  </div>
                </Col>
              </Row>
            </div>
          </TabPane>
        </TabContent>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(MainContent);
